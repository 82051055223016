import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
//import TableRows from "../TableRows";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  CardHeader,
  Table,
  Card,
  CardBody,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";
import Select from "react-select";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { get_cookie } from "../../../helpers/get_cookie";

export const Roster = () => {
  document.title = "Roster Settings | Get Rostered";
  const [noticePeriod, setNoticePeriod] = useState(24);
  const [shiftStatus, setShiftStatus] = useState("Open");
  const [isPublish, setIsPublish] = useState("No");
  const [isStatusOpen, setIsStatusOpen] = useState(true);
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [authUserId, setAuthUserId] = useState();

  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        setAuthUserId(res.id);
        getRosterSettings(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const getRosterSettings = (id) => {
    let formData = {
      user_id: id,
      business_id: business_id,
    };
    axios
      .post("/get-roster-settings", formData)
      .then((res) => {
        console.log(res);
        setShiftStatus(res.data.cancellation_policy);
        setNoticePeriod(res.data.cancel_shift_notice_hours);
        setIsPublish(res.data.auto_publish_open_shift_on_worker_cancel);
        setIsStatusOpen(res.data.cancellation_policy === "Open");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleUpdate = () => {
    let formData = {
      user_id: authUserId,
      shift_notice_period: noticePeriod,
      shift_status: shiftStatus,
      is_publish: isPublish,
      business_id: business_id,
    };
    axios
      .post("/save-roster-settings", formData)
      .then((res) => {
        console.log(res);
        toast.success("Roster settings updated successfully!");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Card id="contactList">
        <CardHeader>
          <h5 className="mb-0 text-primary">Roster Settings</h5>
        </CardHeader>
        <CardBody className="pt-0 mt-4">
          <Row>
            <Col lg={6}>
              <div className="d-flex align-items-center justify-content-between">
                <Label>Worker shift cancellation notice period</Label>
                <select
                  name="cancel_shift_notice_hours"
                  id="cancel_shift_notice_hours"
                  className="form-select"
                  style={{ width: "35%" }}
                  value={noticePeriod}
                  onChange={(e) => setNoticePeriod(e.target.value)}
                >
                  {" "}
                  <option value={6}>6 hours</option>{" "}
                  <option value={12}>12 hours</option>{" "}
                  <option value={18}>18 hours</option>{" "}
                  <option value={24}>1 day</option>{" "}
                  <option value={48}>2 days</option>{" "}
                  <option value={72}>3 days</option>{" "}
                  <option value={96}>4 days</option>{" "}
                  <option value={120}>5 days</option>{" "}
                  <option value={144}>6 days</option>{" "}
                  <option value={168}>7 days</option>{" "}
                </select>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={6}>
              <div className="d-flex align-items-center justify-content-between">
                <Label>Shift status after worker cancels a shift</Label>
                <select
                  className="form-select"
                  style={{ width: "35%" }}
                  value={shiftStatus}
                  onChange={(e) => {
                    if (e.target.value == "Empty") {
                      setIsPublish("No");
                    }
                    setShiftStatus(e.target.value);
                    setIsStatusOpen(e.target.value === "Open");
                  }}
                >
                  <option value="Open">Open</option>
                  <option value="Empty">Empty</option>
                </select>
              </div>
            </Col>
            {isStatusOpen && (
              <Col lg={6}>
                <div className="d-flex align-items-center justify-content-between">
                  <Label>Automatically publish this open shift?</Label>
                  <select
                    className="form-select"
                    style={{ width: "35%" }}
                    value={isPublish}
                    onChange={(e) => setIsPublish(e.target.value)}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </Col>
            )}
          </Row>
          <div className="mt-3">
            <Button className="btn btn-primary" onClick={() => handleUpdate()}>
              Update
            </Button>
          </div>
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </div>
  );
};
