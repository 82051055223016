import React from 'react'
import authBgImage from '../assets/images/logo-roster-main.png';

const ErrorPage = () => {

    const handleGoBack = () => {
        // Use the history object to navigate back to the previous page
        window.history.back();
        setTimeout(() => {
            window.location.reload();
          }, 100);
      };
    return (
        <div className='auth-page-wrapper'>
            {/* <div className="auth-one-bg-position auth-one-bg2"> */}
                {/* <div className='bg-overlay'></div> */}
            {/* </div> */}
            <div className="page-content vh-50 d-flex align-items-center text-center">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <img src={authBgImage} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content vh-50 d-flex align-items-center">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='text-center text-white-50'>
                                <h1 className='coming-soon-text' style={{fontSize:"5rem"}}>404</h1>
                                <h2 className='text-dark'>You have encountered an error while loading the page</h2>
                                <div className='mt-4 pt-2'>
                                    <button className='btn btn-primary' onClick={handleGoBack}>
                                        <i className='mdi mdi-home me-1'></i> Back to previous page
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage
