import React, { useState, useEffect } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
// import Components
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../../Components/Common/DeleteModal";
import Loader from "../../../Components/Common/Loader";
import { get_cookie } from "../../../helpers/get_cookie";
export const Roles = () => {
  const [roles, setRoles] = useState("");
  const [rowsData, setRowsData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [authUserId, setAuthUserId] = useState();
  const [role_id, setRoleid] = useState();
  //const [business_id, setbusinessid] = useState();
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  document.title = "Roles | Get Rostered";

  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res.id, "helllllooooooo");
        setAuthUserId(res.id);
        loadTable(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const loadTable = (id) => {
    console.log(id, "idddd");
    setisLoading(true);

    var datavalue = {
      user_id: id,
      business_id: business_id,
    };
    axios.post("/get-roles-data", datavalue).then((res) => {
      console.log(res);
      setRoles(res);
      setisLoading(false);
    });
  };

  const addTableRows = () => {
    setRowsData([...rowsData, { grouproles: "", groupRate: "" }]);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    if (name === "category_name") {
      const updatedProductCategory = [...roles];
      updatedProductCategory[index].name = value;
      setRoles(updatedProductCategory);
    } else if (name === "grouproles") {
      const updatedRowsData = [...rowsData];
      updatedRowsData[index].grouproles = value;
      setRowsData(updatedRowsData);
    }
  };

  const handleNewChange = (index, event) => {
    const { name, value } = event.target;
    if (name === "category_name") {
      const updatedProductCategory = [...roles];
      updatedProductCategory[index].description = value;
      setRoles(updatedProductCategory);
    } else if (name === "groupRate") {
      const updatedRowsData = [...rowsData];
      updatedRowsData[index].groupRate = value;
      setRowsData(updatedRowsData);
    }
  };
  console.log(rowsData, "rowsdata");
  console.log(roles, "roles");

  //Edit

  const handleEdit = (index) => {
    const updatedProductCategory = [...roles];
    updatedProductCategory[index].isEditing = true;
    setRoles(updatedProductCategory);
  };
  //cancel button for edit
  const handleCancel = (index) => {
    const updatedProductCategory = [...roles];
    updatedProductCategory[index].isEditing = false;
    setRoles(updatedProductCategory);
  };
  //save(Update)
  const handleSave = async (index) => {
    const item = roles[index];
    console.log("update:", item);
    console.log("data", item);
    var datavalue = {
      user_id: authUserId,
      business_id: business_id,
      role_id: item.id,
      name: item.name,
      description: item.description,
    };

    axios
      .post("/add-role", datavalue)
      .then((res) => {
        toast.success(res.message, { theme: "light" });
        loadTable(authUserId);
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };

  const [deleteId, setDeleteId] = useState("");
  //Delete(Upadte)
  const handleDelete = async (index) => {
    const item = roles[index];
    var id = item.id;
    setDeleteId(id);
    setDeleteModal(true);
  };
  const handleDeletePermission = async () => {
    var deleteval = {
      id: deleteId,
    };
    axios
      .post("/delete-role", deleteval)
      .then((res) => {
        console.log(res.success);
        toast.success(res.message, { theme: "light" });
        loadTable(authUserId);
        setDeleteModal(false);
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };
  //SAVE(new row)
  const saveTableRow = (index) => {
    const data = rowsData[index];
    // console.log('Saving:', data.groupName);
    var datavalue = {
      user_id: authUserId,
      business_id: business_id,
      name: data.grouproles,
      description: data.groupRate,
    };
    if (data.grouproles == "") {
      toast.error("The name field is required.", { theme: "light" });
      return;
    }
    if (data.groupRate == "") {
      toast.error("The description field is required.", { theme: "light" });
      return;
    }
    axios
      .post("/add-role", datavalue)
      .then((res) => {
        console.log(res.success);
        toast.success(res.message, { theme: "light" });
        const updatedRows = [...rowsData];
        updatedRows.splice(index, 1);
        setRowsData([...updatedRows]);
        loadTable(authUserId);
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };
  //cancel(new row)
  const deleteTableRows = (index) => {
    const updatedRowsData = [...rowsData];
    updatedRowsData.splice(index, 1);
    setRowsData(updatedRowsData);
  };
  const [permission, setPermission] = useState("");
  let rolename = ""; // Declare 'a' as a 'let' variable

  //   useEffect(() => {
  //     axios.get("/api/RolePermission")
  //       .then((res) => {
  //         console.log(res, "rolepermission12324");
  //         setPermission(res);
  //       });
  //   }, []);

  const filteredMenuItemsGeneral = !permission.includes("roles_permissions");

  return (
    <React.Fragment>
      {/* {isLoading && <Loader />} */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePermission}
        onCloseClick={() => setDeleteModal(false)}
      />
      <ToastContainer closeButton={false} limit={1} theme="light" />

      <Card>
        <CardHeader>
          <h5 className="mb-0 text-primary">Roles</h5>
        </CardHeader>
        <Row>
          <Col xl={12}>
            <CardBody>
              
              {filteredMenuItemsGeneral && (
                <Table className="table table-bordered   align-middle mb-3">
                  <thead className="bg-light">
                    <tr>
                      <th className="listTypeHead" style={{ width: "230px" }}>
                        Role Name
                      </th>
                      <th style={{ width: "800px" }}>Description</th>
                      <th style={{ width: "145px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody className="mb-3">
                    {roles &&
                      roles.map((item, index) => (
                        <tr key={index}>
                          <td
                            className="fw-medium"
                            style={{
                              maxWidth: "230px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.isEditing ? (
                              <input
                                type="text"
                                value={item.name}
                                onChange={(event) => handleChange(index, event)}
                                className="form-control"
                                name="category_name"
                              />
                            ) : (
                              item.name
                            )}
                          </td>
                          <td
                            className="fw-medium"
                            style={{
                              maxWidth: "800px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.isEditing ? (
                              <input
                                type="text"
                                value={item.description}
                                onChange={(event) =>
                                  handleNewChange(index, event)
                                }
                                className="form-control"
                                name="category_name"
                              />
                            ) : (
                              item.description
                            )}
                          </td>
                          {item.editable == 1 && (
                            <td
                              className="fw-medium"
                              style={{
                                maxWidth: "145px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <div className="hstack gap-3 flex-wrap">
                                {item.isEditing ? (
                                  <>
                                    <button
                                      className="btn btn-success"
                                      onClick={() => handleSave(index)}
                                    >
                                      Update
                                    </button>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => handleCancel(index)}
                                    >
                                      Cancel
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="btn btn-sm btn-soft-info edit-list "
                                      onClick={() => handleEdit(index)}
                                    >
                                      <i className="bx bxs-pencil fs-12 pt-1"></i>
                                    </button>
                                    <button
                                      className="btn btn-sm btn-soft-danger  "
                                      onClick={() => handleDelete(index)}
                                    >
                                      <i className="ri-delete-bin-5-fill fs-12 pt-1"></i>
                                    </button>
                                    {/* <button
                                    className="btn btn-sm btn-soft-danger  "
                                    onClick="/permission"
                                  > */}

                                    {/* </button> */}                                    
                                  </>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    {/* ///Adding for new rows  */}
                    {rowsData &&
                      rowsData.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              value={data.grouproles}
                              onChange={(event) => handleChange(index, event)}
                              name="grouproles"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={data.groupRate}
                              onChange={(event) =>
                                handleNewChange(index, event)
                              }
                              name="groupRate"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <div className="hstack gap-2">
                              <button
                                className="btn btn-success"
                                onClick={() => saveTableRow(index)}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={() => deleteTableRows(index)}
                              >
                                Cancel
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    {/* <TableInput rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} /> */}
                  </tbody>

                  <button
                    className="btn btn-primary mt-3"
                    onClick={addTableRows}
                  >
                    +
                  </button>
                </Table>
              )}
            </CardBody>
            {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};
