import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import powered_logo from "../../assets/images/powered_by.jpg";
import axios from "axios";
import { get_cookie } from "../../helpers/get_cookie";

const AddNewPayment = ({onSuccess}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [authUserId, setAuthUserId] = useState();
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        setAuthUserId(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      let data = {
        payment_method: paymentMethod.id,
        user_id: authUserId,
        business_id: business_id,
      };
      // Send the paymentMethod.id to your server for processing the subscription
      axios
        .post("/add-card", data)
        .then((response) =>{ console.log(response); onSuccess();})
        .then((data) => {
          console.log("[Server response]", data);
        });
    }
  };
  const cardElementOptions = {
    hidePostalCode: true,
  };
  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={cardElementOptions}  data-tid="CardNumberElement-suffix" />
      <div className="d-flex justify-content-between align-items-center mt-3">
       
        <img src={powered_logo} className="mb-0" height={50}></img>
        <Button
          type="submit"
          style={{ border: "0px solid black"}}
          className="btn btn-primary"
          disabled={!stripe}
        >
          Add Card
        </Button>
      </div>
    </form>
  );
};

export default AddNewPayment;
