import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";

// Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";

// Routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected } from "./AuthProtected";
import { get_cookie } from "../helpers/get_cookie";
import BusinessProfile from "../pages/Authentication/BusinessProfile";

const Index = () => {
  const [profileStatus, setProfileStatus] = useState(0);

  // Function to fetch and update profile status from cookie
  const updateProfileStatusFromCookie = () => {
    const authUser = JSON.parse(get_cookie("authUser"));
    const updatedProfileStatus = authUser?.profile_status;
    setProfileStatus(updatedProfileStatus);
  };

  useEffect(() => {
    // Update profile status when component mounts
    updateProfileStatusFromCookie();

    // Set up interval to periodically update profile status
    const interval = setInterval(() => {
      updateProfileStatusFromCookie();
    }, 1000); // Adjust interval as needed

    // Clean up interval to prevent memory leaks
    return () => clearInterval(interval);
  }, []);

  console.log(profileStatus, "profileStatus");
  console.log(profileStatus === 0, "profileStatus");

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map((route, idx) => (
           <Route
           path={route.path}
           element={
               profileStatus === 0 ? (
                   <AuthProtected path="/business-profile">
                       <VerticalLayout>
                           {route.path === "/business-profile" ? (
                               <BusinessProfile />
                           ) : (
                               <Navigate to="/business-profile" replace />
                           )}
                       </VerticalLayout>
                   </AuthProtected>
               ) : (
                   <AuthProtected path={route.path}>
                       <VerticalLayout>{route.component}</VerticalLayout>
                   </AuthProtected>
               )
           }
           key={idx}
           exact={true}
       />
       
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;
