import React, { useEffect, useState } from "react";
import { Navigate, Route, useLocation, useNavigate } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";

import { useProfile } from "../Components/Hooks/UserHooks";

import { logoutUser } from "../store/actions";

import {
  add_cookie,
  add_permissions_cookie,
  delete_cookie,
  get_cookie,
} from "../helpers/get_cookie";
import axios from "axios";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_id = JSON.parse(get_cookie("authUser")).user_id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [permissions, setPermissions] = useState([]);
  const { userProfile, loading, token } = useProfile();

  useEffect(() => {
    if (userProfile && !loading && token) {
      setAuthorization(token);
    } else if ((!userProfile || !token) && loading) {
      dispatch(logoutUser());
    } else {
      dispatch(logoutUser());
    }
  }, [token, userProfile, loading, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payLoad = {
          user_id: user_id,
          business_id : business_id,
        };
        const rolesAndPermissionsRes = await axios.post(
          "/get-roles-and-permissions",
          payLoad
        );

        delete_cookie("permissions");

        // Adding permissions to cookie
        if (rolesAndPermissionsRes) {
          // Check if any of the permissions include "view_rosters"
          const permissions = rolesAndPermissionsRes.roles_and_permissions
            .map((role) => role.permission_names)
            .flat();

          setPermissions(permissions);
          add_permissions_cookie(permissions);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [navigate]);

  if (userProfile && token) {
    setAuthorization(token);
    const currentPageUrl = window.location.href;
    const url = new URL(currentPageUrl);
    const pathname = url.pathname;
    const getLocalStorageItem = localStorage.getItem(
      "expiredSessionRedirectUrl"
    );
    if (getLocalStorageItem !== null) {
      const indexOfHash = getLocalStorageItem.indexOf("#");
      if (indexOfHash === -1) {
        localStorage.setItem("expiredSessionRedirectUrl", pathname);
      }
    }
  }

 // Check for permissions based on route's permission prop
  const hasPermission = () => {
    return props.permission ? permissions.includes(props.permission) : true;
    // Check if permission prop exists, then check for its presence in permissions. If no permission prop, assume access granted.
  };

  console.log(props.permission, "prop");
  console.log(!props.permission, "prop");
  console.log(permissions, "permissions");

  console.log(hasPermission(), "hasPermission");

  /*
    Navigate is un-auth access protected routes via url
    */
  if (!userProfile || !token) {
    sessionStorage.setItem("RedirectURL", JSON.stringify(props.path));
    return <Navigate to={{ pathname: "/login", state: "/dashboard" }} />;
  }
  var user = JSON.parse(get_cookie("authUser"));
  user.remember ? add_cookie(user, true) : add_cookie(user);

  // Render children if no permission is required or permission check passes
  return (
    <>
      {permissions.length > 0 && (
        <>
          {permissions.length > 0 &&
            // Check if permission is required and granted
            (hasPermission() ? (
              props.children // Render children if permission is granted
            ) : (
              <Navigate to="/unauthorized" replace /> // Redirect if permission is required but not granted
            ))}
        </>
      )}
    </>
  );
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
