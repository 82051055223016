import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Accordion,
  AccordionItem,
  Collapse,
  Label,
  Input,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SettingsTabPages } from "./Constants/SettingsConstants";
import SettingsTabControl from "./TabComponents/SettingsTabControl";
import SettingsTabContent from "./TabComponents/SettingsTabContent";

const EditSite = () => {
  document.title = "Business Settings | Get Rostered";
  const params = useParams();
  const tab = params.activeTab;
  const [activeTab, setActiveTab] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    // Handle tab parameter if it's provided in the URL
    if (tab === "general") {
      setActiveTab(SettingsTabPages.General);
    }else if (tab === "zone") {
      setActiveTab(SettingsTabPages.Zone);
    }else if (tab === "assignedPeople") {
      setActiveTab(SettingsTabPages.AssignedPeople);
    }else if (tab === "holidays") {
      setActiveTab(SettingsTabPages.Holidays);
    } else {
      setActiveTab(SettingsTabPages.General);
    }
  }, [tab]);

  //Pagepersonalsation Headings
  const getPageTitle = () => {
    switch (activeTab) {
      case SettingsTabPages.General:
        return "General";
      case SettingsTabPages.Zone:
        return "Zone";
      case SettingsTabPages.AssignedPeople:
        return "AssignedPeople";
      case SettingsTabPages.Holidays:
        return "Holidays";
      default:
        return "General";
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Edit Site" pageTitle="" />
          <Row>
            <Col md="3">
              <SettingsTabControl
                activeTabSetter={setActiveTab}
                activeTab={activeTab}
              />
              <button
                className="btn btn-primary hstack gap-2 align-items-center"
                onClick={() => {
                  navigate("/business_settings/sites");
                }}
              >
                <i className=" ri-arrow-left-line  "></i>
                <span className=""> Back to Sites</span>
              </button>
            </Col>
            {activeTab ? (
              <Col md="9">
                <SettingsTabContent activeTab={activeTab} />
              </Col>
            ) : (
              <Col md="9">
                <Card className="p-3 text-bold"> Permission Not Granted.</Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditSite;
