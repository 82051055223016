import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Input, Col, Container, Row, Button } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
//import images
import logoRoster from "../../assets/images/logopurple.png";
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
const ResetPassword = () => {
    const [isResend, setIsResend] = useState(false);
    const navigate = useNavigate();
    const [otpValues, setOtpValues] = useState(['', '', '', '', '', '']);
    const [isInCorrect, setIsInCorrect] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const location = useLocation();
    const emailFromRegistration = location.state.email;

    const validatePassword = (value) => {
        // Password must be at least 8 characters long and contain at least one number, one uppercase letter, and one lowercase letter.
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        const isValid = passwordRegex.test(value);
        if (!value || !isValid) {
            setPasswordError(
                'Password must have 8 characters, contain at least one number, one uppercase letter, and one lowercase letter.'
            );
            return false;
        }
        setPasswordError('');
        return true;

    };
    const validateConfirmPassword = (value) => {
        // Password must be at least 8 characters long and contain at least one number, one uppercase letter, and one lowercase letter.
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        const isValid = passwordRegex.test(value);
        if (!value || !isValid) {
            setConfirmPasswordError(
                'Password must have 8 characters, contain at least one number, one uppercase letter, and one lowercase letter.'
            );
            return false;
        }
        setConfirmPasswordError('');
        return true;

    };
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
    };
    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);
    };

    const handleKeyUp = (index, value) => {
        // Update the OTP values based on the input
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);

        // Move focus to the next input field when a digit is entered
        if (value && index < otpValues.length - 1) {
            const nextInput = document.getElementById(`digit${index + 2}-input`);
            if (nextInput) {
                nextInput.focus();
            }
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const verificationCode = otpValues.join('');
        // Perform your validation here
        if (!validateConfirmPassword(confirmPassword) || !validatePassword(password)) {
            // Validation failed, dont proceed with submission
            return;
        }else if (password !== confirmPassword) {
            toast.error('please make sure new password and confirm password is same');
            return;
        }
        let formData = {
            'email': emailFromRegistration,
            'verification_code': verificationCode,
            'new_password': confirmPassword
        }

        // Validation passed, proceed with form submission
        console.log("Validation passed. Register calling here");
        // Axios request
        try {
            const response = await axios.post('/verify-forgot-password-code', formData);
            console.log(response);
            if (response.status === "success") {
                navigate('/login');
            }
            else {
                toast.error(response.message)
            }
        } catch (error) {
            toast.error('invalid reset code')
        }
    };
    console.log(otpValues,"otpvalues2")

    const handleResend = () => {
        setIsResend(true);
        setOtpValues(['', '', '', '', '', '']);
        setPassword('');
        setConfirmPassword('');
        setPasswordError('');
        setConfirmPasswordError('');
        
        axios.post('/forgot-password', { 'email': emailFromRegistration })
            .then(res => {
                console.log(res);
                toast.success("OTP has been resent to your email.");
            })
            .catch(err => {
                console.log(err);
                toast.error("Error resending OTP. Please try again later.");
            });
    };

    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <ParticlesAuth>
                    <div className="auth-page-content">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                        <div>
                                            <Link to="/dashboard" className="d-inline-block auth-logo">
                                                <img src={logoRoster} alt="" height="35" />
                                            </Link>
                                        </div>
                                        <p className="mt-3 fs-15 fw-medium"></p>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="">
                                        <CardBody className="p-4">
                                            <div className="mb-4">
                                                <div className="avatar-lg mx-auto">
                                                    <div className="avatar-title bg-light text-primary display-5 rounded-circle">
                                                        <i className="ri-mail-line"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="p-2 mt-4">
                                                <div className="text-muted text-center mb-4 mx-lg-3">
                                                    {!isResend ? (
                                                        <>
                                                            <h4 className="">Reset Password!</h4>
                                                            <p className='mt-2'> We've sent a Reset Code to your email.
                                                            </p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h4 className="">OTP has been Resent to your email</h4>
                                                            <p className='mt-2'>Please check your email's Inbox and Spam folders.
                                                            </p>
                                                        </>
                                                    )}

                                                </div>

                                                <form onSubmit={handleSubmit}>
                                                    <Row>
                                                        {[1, 2, 3, 4, 5, 6].map((index) => (
                                                            <Col className="col-2" key={index}>
                                                                <div className="mb-3">
                                                                    <label htmlFor={`digit${index}-input`} className="visually-hidden">
                                                                        Digit {index}
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        value={otpValues[index - 1]}
                                                                        className="form-control bg-light border-light text-center"
                                                                        maxLength="1"
                                                                        id={`digit${index}-input`}
                                                                        onChange={(e) => handleKeyUp(index - 1, e.target.value)}
                                                                       // onKeyUp={(e) => handleKeyUp(index - 1, e.target.value)}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        ))}
                                                        <Input
                                                          type="text" 
                                                          className="form-control visually-hidden"
                                                          id="firstnameInput"
                                                          placeholder="Enter job title"
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="password-input">New password</label>
                                                            <div className="position-relative auth-pass-inputgroup">
                                                                <Input
                                                                    type={passwordShow ? "text" : "password"}
                                                                    className={`form-control pe-5 password-input ${passwordError ? 'is-invalid' : ''
                                                                        }`}
                                                                    placeholder="Enter password"
                                                                    id="password-input"
                                                                    name="password"
                                                                    value={password}
                                                                    onChange={handlePasswordChange}
                                                                    required
                                                                />

                                                                <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                                    id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                                {passwordError &&
                                                                    <div className="invalid-feedback">{passwordError}</div>
                                                                }
                                                            </div>

                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="password-input">Confirm new password</label>
                                                            <div className="position-relative auth-pass-inputgroup">
                                                                <Input
                                                                    type={confirmPasswordShow ? "text" : "password"}
                                                                    className={`form-control pe-5 password-input ${confirmPasswordError ? 'is-invalid' : ''
                                                                        }`}
                                                                    placeholder="Enter password"
                                                                    id="password-input"
                                                                    name="password"
                                                                    value={confirmPassword}
                                                                    onChange={handleConfirmPasswordChange}
                                                                    required
                                                                />

                                                                <Button color="link" onClick={() => setConfirmPasswordShow(!confirmPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                                    id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                                {confirmPasswordError &&
                                                                    <div className="invalid-feedback">{confirmPasswordError}</div>
                                                                }
                                                            </div>

                                                        </div>
                                                    </Row>
                                                    <div className="mt-3">
                                                        <button className="w-100 btn text-white btn-signup" >Confirm</button>
                                                    </div>
                                                </form>
                                                {isInCorrect && <div className='text-danger'>The Reset code you entered is wrong. Please enter the correct code.</div>}
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <div className="mt-4 d-flex justify-content-between ">
                                        <Link to="/login" >Back to Login</Link>
                                        <t className="mb-0   ">Didn't receive a code ? &nbsp;
                                            <span className="fw-semibold text-info text-decoration-underline"
                                                style={{ textDecoration: "underline" ,cursor: "pointer" }}
                                                onClick={handleResend}
                                            >Resend</span> </t>
                                    </div>
                                </Col>
                            </Row>
                            <ToastContainer closeButton={false} limit={1} theme="light" />
                        </Container>
                    </div>
                </ParticlesAuth >

            </div>
        </React.Fragment >)
}

export default ResetPassword;