module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  beampusher: {
    INSTANCE_ID: "a2403fd5-e2d9-45f4-9958-fc9962dc08e9",
    WEB_URL: "https://work.getrostered.app/",
  },
  api: {
     //local
    //  API_URL: "http://127.0.0.1:8000/api",
    //  WEB_URL: "http://127.0.0.1:8000/api",

     //development
      API_URL: " https://devbusiness.getrostered.app/server/public/api",
      WEB_URL: "https://devbusiness.getrostered.app/server/public/api",

     // //live 
     //API_URL: " https://business.getrostered.app/server/public/api",
     //WEB_URL: "https://business.getrostered.app/server/public/api",
  }
};
