import React, { useEffect, useState } from 'react';

import { Col, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, UncontrolledDropdown, UncontrolledTooltip, UncontrolledCollapse, ButtonGroup, Button, UncontrolledButtonDropdown, Modal, ModalBody, ModalHeader, Input, Progress, Card, CardHeader, CardBody, Alert, Collapse } from 'reactstrap';

import classnames from "classnames";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FeatherIcon from 'feather-icons-react';
import DeleteModal from '../../Components/Common/DeleteModal';

//SimpleBar
import SimpleBar from "simplebar-react";

// Rating
import Rating from "react-rating";

// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg";

import img2 from "../../assets/images/small/img-2.jpg";
import img6 from "../../assets/images/small/img-6.jpg";

import image2 from "../../assets/images/users/avatar-2.jpg";
import image4 from "../../assets/images/users/avatar-4.jpg";
import image3 from "../../assets/images/users/avatar-3.jpg";
import image5 from "../../assets/images/users/avatar-5.jpg";
import Loader from "../../Components/Common/Loader";

import { Link } from 'react-router-dom';
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { get_cookie } from "../../helpers/get_cookie";
// import Pusher from "pusher-js";

//redux
import { useSelector, useDispatch } from "react-redux";
import { getMailDetails, deleteMail } from '../../store/actions';

const EmailToolbar = () => {
    const [isLoading, setIsLoading] = useState(false);
    const authUser_id = JSON.parse(get_cookie("workauthUser")).user_id;
    const user_id = JSON.parse(get_cookie("authUser")).user_id;
    const business_id = JSON.parse(get_cookie("authUser")).business_id;
    const [toemail, setToEmail] = useState("");
    const [fromemail, setFromEmail] = useState("");
    const [cc, setcc] = useState("");
    const [bcc, setbcc] = useState("");
    const [sub, setSub] = useState("");
    const [message, setMessage] = useState("");

    const [torepemail, setTorepEmail] = useState("");
    //const [fromemail, setFromEmail] = useState("");
    const [ccrep, setccrep] = useState("");
    const [bccrep, setbccrep] = useState("");
    const [subrep, setSubrep] = useState("");
    const [messagerep, setMessagerep] = useState("");
    const [idrep, setIdrep] = useState("");

    const [toforemail, setToforEmail] = useState("");
    const [ccfor, setccfor] = useState("");
    const [bccfor, setbccfor] = useState("");
    const [subfor, setSubfor] = useState("");
    const [messagefor, setMessagefor] = useState("");
    const [idfor, setIdfor] = useState("");
    const [count, setCounts] = useState("");
    const [businessId, setBusinessId] = useState(null);
    const dispatch = useDispatch();
    const { mailDetails, isLoader } = useSelector((state) => ({
        mailDetails: state.Mailbox.mailDetails,
        isLoader: state.Mailbox.isLoader,
    }));
    
    const [id, setId] = useState("");
    const [mailList, setMailList] = useState([]);
    const [activeTabs, setActive] = useState("all");
    const [isLabelTab, setIsLabelTab] = useState("");
    const [isTypeTab, setIsTypeTab] = useState("primary");
    const [displayCategory, setCategory] = useState("all");
    const [displaytype, settype] = useState("all");
    const [displaylabel, setLabel] = useState("all");
    const [isOpen, setIsOpen] = useState(true);
    const toggleCollapse = () => {
        setIsOpen(!isOpen); // Toggle the state of isOpen
    };
    //list all email here
    const getEamils = () => {
        const formData = {
            user_id: user_id,
            business_id: business_id,
            category : displayCategory,
        };
        setIsLoading(true);
        axios
            .post("/get-emails", formData)
            .then((response) => {
                console.log(response, "heyyylowww");
                setBusinessId(response.businessId);
                setMailList(response.all_emails);
                setCounts(response.counts);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const openComposeModal = () => {
        setFromEmail("");
        setToEmail("");
        setSub("");
        setMessage("");
        setId("");
        setModal(true);
    };
    useEffect(() => {
        getEamils();
        // const pusher = new Pusher("a432f2e9eb2a5f935169", {
        //     cluster: "ap4",
        //     encrypted: true,
        // });
        // // Subscribe to the channel
        // const channel = pusher.subscribe("email-channel");
        // // Listen for the 'new-message' event
        // channel.bind("new-email", function (data) {
        //     console.log(data, "business_id");
        //     if (data[0].business_id === businessId) {
        //         console.log(data, "data");
        //         setMailList(data.all_emails);
        //         // setCounts(data.counts);
        //         getEamils();
        //     }
        // });
        // console.log(mailList, "mailList");
        // return () => {
        //     // Unsubscribe from the channel when component unmounts
        //     channel.unbind_all();
        //     channel.unsubscribe();
        // };
    }, [businessId,displayCategory]);

    const toggleTab = (ncategory, ntype, nlabel) => {
        var element = document.getElementById("mail-filter-navlist");
        if (ncategory === "all" || ncategory === "inbox") {
            element.style.display = 'block';
        } else {
            element.style.display = 'none';
        }
        if (activeTabs !== ncategory) {
            setActive(ncategory);
        }
        if (isLabelTab !== nlabel) {
            setIsLabelTab(nlabel);
        }
        if (isTypeTab !== ntype) {
            setIsTypeTab(ntype);
        }
        setCategory(ncategory);
        settype(ntype);
        setLabel(nlabel);
        getEamils();
    };

    // SideBar Open
    function sidebarOpen(value, ele, item) {
        const element = document.getElementsByTagName('body')[0];
        element.classList.add(value);
        ele.closest("li").classList.remove("unread");
        console.log(item,"item");
        setFromEmail(item.log_from);
        setToEmail(item.log_to);
        setSub(item.subject);
        setMessage(item.teaser);
        setId(item.id);
        // reply 
        setFromEmail(item.log_from);
        setTorepEmail(item.log_to);
        setSubrep(item.subject);
        setMessagerep(item.teaser);
        setIdrep(item.id);
        //forward
        setFromEmail(item.log_from);
        setToforEmail(item.log_to);
        setSubfor(item.subject);
        setMessagefor(item.teaser);
        setIdfor(item.id);
        if (item.unread === 1) {
            var formData = {
                user_id: user_id,
                business_id: business_id,
                ids: item.id, // Pass the array of forIds
                categoryType: "read",
                source_table: item.source_table,
                log_status: "read",
            };
            axios
                .post("/update-as-trash-or-read", formData)
                .then((response) => {
                    console.log(response);
                    console.log(response.responses[0].error);
                    if (response.responses[0].error === 0) {
                        //toast.success(response.responses[0].message, { theme: "light" });
                        getEamils();
                    } else {
                        toast.error(response.responses[0].message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error("Something went wrong...");
                });
        }
    }

    // SideBar Close
    function sidebarClose(value) {
        const element = document.getElementsByTagName('body')[0];
        element.classList.remove(value);
    }

    useEffect(() => {
        sidebarClose("email-detail-show");
    }, []);

    // Chat Box
    const chatBox = (value) => {
        const element = document.getElementById("emailchat-detailElem");
        element.style.display = value;
    };

    const [modal, setModal] = useState(false);

    const toggle = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    };
    const [coll3, setcoll3] = useState(false);
    const [coll4, setcoll4] = useState(false);
    const t_coll3 = () => {
        setcoll3(!coll3);
    };
    const t_coll4 = () => {
        setcoll4(!coll4);
    };

    const [coll5, setcoll5] = useState(false);
    const [coll6, setcoll6] = useState(false);

    const t_coll5 = () => {
        setcoll5(!coll5);
    };

    const t_coll6 = () => {
        setcoll6(!coll6);
    };

    // delete button toggle
    const onChangeCheckBox = (value, check) => {
        const element = document.getElementById("email-topbar-actions");
        const checkedCount = document.querySelectorAll('.checkbox-wrapper-mail input:checked').length;
        const activeList = document.getElementById(value);
        console.log(activeList);

        if (checkedCount >= 1) {
            element.style.display = 'block';
        } else {
            element.style.display = 'none';
        }
        if (check) {
            activeList.classList.add("active");
        } else {
            activeList.classList.remove("active");
        }
    };

    // Checked All Email
    const checkedAll = () => {
        // check all inboxes
        const checkall = document.getElementById("checkall");
        const element = document.querySelectorAll(".message-list li");
        const ele = document.getElementById("email-topbar-actions");
        ele.style.display = 'block';

        if (checkall.checked) {
            element.forEach((node) => {
                node.classList.add("active");
                node.firstChild.firstChild.firstChild.checked = true;
            });
        } else {
            ele.style.display = 'none';
            element.forEach((node) => {
                node.classList.remove("active");
                node.firstChild.firstChild.firstChild.checked = false;
            });
        }
    };


    // trash  Email top icon
    const removeEmail = () => {
        const element = document.querySelectorAll(".message-list li");
        const ele = document.getElementById("email-topbar-actions");
        const checkall = document.getElementById("checkall");
        let forIdsWithNames = [];
        element.forEach((element) => {
            if (element.classList.contains('active')) {
                var forId = element.querySelector('.form-check-input').value;
                var source_table = element.querySelector('.form-check-input').dataset.source;
                forIdsWithNames.push({ id: forId, source_table: source_table });
                // Deactivate the element and uncheck the checkbox
                element.classList.remove("active");
                element.querySelector('.form-check-input').checked = false;
            }
        });
        // Check if there are any forId values collected
        if (forIdsWithNames.length > 0) {
            // Prepare the formData with the collected forId values
            var formData = {
                user_id: user_id,
                business_id: business_id,
                ids: forIdsWithNames, // Pass the array of forIds
                categoryType: "trash",
            };

            // Make a single axios request
            axios
                .post("/update-as-trash-or-read", formData)
                .then((response) => {
                    console.log(response.responses[0].error);
                    if (response.responses[0].error === 0) {
                        //toast.success(response.responses[0].message, { theme: "light" });
                        getEamils();
                    } else {
                        toast.error(response.responses[0].message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error("Something went wrong...");
                });
        }
        checkall.checked = false;
        ele.style.display = 'none';
    };

    // Mark all as Read
    const readAll = () => {
        if (document.querySelectorAll(".message-list li.unread").length === 0) {
            document.getElementById("unreadConversations").style.display = "block";
            setTimeout(function () { document.getElementById("unreadConversations").style.display = "none"; }, 1000);
        }
        let forIdsWithNames = [];
        document.querySelectorAll(".message-list li.unread").forEach(function (element) {
            if (element.classList.contains("unread")) {
                var forId = element.querySelector('.form-check-input').value;
                var source_table = element.querySelector('.form-check-input').dataset.source;
                forIdsWithNames.push({ id: forId, source_table: source_table });
                element.classList.remove("unread");
            }
        });
        if (forIdsWithNames.length > 0) {
            // Prepare the formData with the collected forId values
            var formData = {
                user_id: user_id,
                business_id: business_id,
                ids: forIdsWithNames, // Pass the array of forIds
                categoryType: "read",
                log_status: "read",
            };

            // Make a single axios request
            axios
                .post("/update-as-trash-or-read", formData)
                .then((response) => {
                    console.log(response);
                    console.log(response.responses[0].error);
                    if (response.responses[0].error === 0) {
                        //toast.success(response.responses[0].error, { theme: "light" });
                        getEamils();
                    } else {
                        toast.error(response.responses[0].error);
                    }
                })
                .catch((error) => {
                    toast.error("Something went wrong...");
                });
        }
    };


    const favouriteBtn = (ele, id, source, important) => {
        // mark as important
        let starred = "";
        if (important === 1) {
            ele.closest("button").classList.remove("active");
            starred = 0;
        } else {
            ele.closest("button").classList.add("active");
            starred = 1;
        }
        var formData = {
            user_id: user_id,
            business_id: business_id,
            ids: id, // Pass the array of forIds
            categoryType: "starred",
            source_table: source,
            starred: starred,
        };
        axios
            .post("/update-as-star", formData)
            .then((response) => {
                console.log(response);
                console.log(response.responses[0].error);
                if (response.responses[0].error === 0) {
                    //toast.success(response.response[0].message, { theme: "light" });
                    getEamils();
                } else {
                    toast.error(response.responses[0].message);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error("Something went wrong...");
            });
    };

    const chatData = [
        { id: 1, img: image2, name: "Scott Median", caption: "Hello ! are you there?" },
        { id: 2, img: image4, name: "Julian Rosa", caption: "What about our next.." },
        { id: 3, img: image3, name: "David Medina", caption: "Yeah everything is fine" },
        { id: 4, img: image5, name: "Jay Baker", caption: "Wow that's great" },
    ];

    const [info, setInfo] = useState([]);

    const [emailinfo, setEmailinfo] = useState([]);

    //delete order
    const [deleteModal, setDeleteModal] = useState(false);
    const sendEmail = () => {
        const fromemail = "";
        let draft;
        if (toemail === "" || sub === "" || message === "" || toemail === null || sub === null || message === null) {
            draft = 1;
        } else {
            draft = 0;
        }
        var formData = {
            user_id: user_id,
            business_id: business_id,
            log_from: fromemail,
            log_to: toemail,
            cc: cc,
            bcc: bcc,
            log_subject: sub,
            log_message: message,
            draft: draft,
        };
        if (id) {
            formData.id = id;
        }
        axios
            .post("/compose-emails", formData)
            .then((response) => {
                if (response.result === 'created') {
                    if (response.error === 0) {
                        toast.success(response.message, { theme: "light" });
                        setModal(false);
                        getEamils();
                        setFromEmail("");
                        setToEmail("");
                        setSub("");
                        setMessage("");
                        setId("");
                    } else {
                        toast.error(response.message);
                        setModal(true);
                    }
                } else {
                    if (response.error === 0) {
                        toast.success(response.message, { theme: "light" });
                        sidebarClose("email-detail-show");
                        getEamils();
                    } else {
                        toast.error(response.message);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error("Something went wrong...");
            });
    };
    const replyEmail = () => {
        const fromemail = "";
        let draft;
        if (torepemail === "" || subrep === "" || messagerep === "") {
            draft = 1;
        } else {
            draft = 0;
        }
        var formData = {
            user_id: user_id,
            business_id: business_id,
            log_from: fromemail,
            log_to: torepemail,
            cc: ccrep,
            bcc: bccrep,
            log_subject: subrep,
            log_message: messagerep,
            draft: draft,
            categoryType: "reply",
            id: idrep,
        };
        axios
            .post("/reply-forword-email", formData)
            .then((response) => {
                if (response.error === 0) {
                    toast.success(response.message, { theme: "light" });
                    setcoll5(false);
                    getEamils();
                } else {
                    toast.error(response.message);
                    setcoll5(true);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error("Something went wrong...");
                setcoll5(true);
            });
    };
    const forwardEmail = () => {
        const fromemail = "";
        let draft;
        if (toforemail === "" || subfor === "" || messagefor === "") {
            draft = 1;
        } else {
            draft = 0;
        }
        var formData = {
            user_id: user_id,
            business_id: business_id,
            log_from: fromemail,
            log_to: toforemail,
            cc: ccfor,
            bcc: bccfor,
            log_subject: subfor,
            log_message: messagefor,
            draft: draft,
            categoryType: "forward",
            id: idfor,
        };
        axios
            .post("/reply-forword-email", formData)
            .then((response) => {
                if (response.error === 0) {
                    toast.success(response.message, { theme: "light" });
                    setcoll6(false);
                    getEamils();
                } else {
                    toast.error(response.message);
                    setcoll6(true);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error("Something went wrong...");
                setcoll6(true);
            });
    };
    const discardEmail = () => {
        const fromemail = "";
        let draft;
        if (toemail !== "" || sub !== "" || message !== "") {
            var formData = {
                user_id: user_id,
                business_id: business_id,
                log_from: fromemail,
                log_to: toemail,
                cc: cc,
                bcc: bcc,
                log_subject: sub,
                log_message: message,
                draft: 1,
            };
            axios
                .post("/compose-emails", formData)
                .then((response) => {
                    if (response.result === 'created') {
                        if (response.error === 0) {
                            toast.success(response.message, { theme: "light" });
                            setModal(false);
                            getEamils();
                            setFromEmail("");
                            setToEmail("");
                            setSub("");
                            setMessage("");
                            setId("");
                        } else {
                            toast.error(response.message);
                            setModal(true);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toast.error("Something went wrong...");
                });
        } else {
            setModal(false);
        }
    };

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => {
                    removeEmail();
                    setDeleteModal(false);
                }}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="email-menu-sidebar">
                <div className="p-4 d-flex flex-column h-100">
                    <div className="pb-4 border-bottom border-bottom-dashed">
                        <button
                            type="button"
                            className="btn btn-danger w-100"
                            onClick={openComposeModal}
                        >
                            <FeatherIcon icon="plus-circle" className="icon-xs me-1 icon-dual-light" />
                            Compose
                        </button>
                    </div>

                    <SimpleBar
                        className="mx-n4 px-4 email-menu-sidebar-scroll" 
                        data-simplebar
                    >
                        <div className="mail-list mt-3">
                            <Link to="#" onClick={() => { toggleTab("all", "all", "all"); }} className={classnames({ active: activeTabs === 'all' })}  >
                                <i className="ri-mail-fill me-3 align-middle fw-medium"></i>
                                {" "}
                                <span className="mail-list-link" >

                                    All{" "}
                                </span>
                                <span className="badge badge-soft-success ms-auto" style={{ backgroundColor: '#865ce2', color: '#fff', padding: '2px 5px', borderRadius: '3px' }}>{count.inbox + count.sent}</span>
                            </Link>
                            <Link to="#" onClick={() => { toggleTab("inbox", "all", "all"); }} className={classnames({ active: activeTabs === 'inbox' })}>
                                <i className="ri-inbox-archive-fill me-3 align-middle fw-medium"></i>{" "}
                                <span className="mail-list-link">
                                    Inbox{" "}
                                </span>
                                <span className="badge badge-soft-success ms-auto" style={{ backgroundColor: '#865ce2', color: '#fff', padding: '2px 5px', borderRadius: '3px' }}>{count.inbox}</span>
                            </Link>
                            <Link to="#" onClick={() => { toggleTab("sent", "all", "all"); }} className={activeTabs === "sent" ? "active" : null}>
                                <i className="ri-send-plane-2-fill me-3 align-middle fw-medium"></i>{" "}
                                <span className="mail-list-link" >
                                    Sent
                                </span>
                                <span className="badge badge-soft-success ms-auto" style={{ backgroundColor: '#865ce2', color: '#fff', padding: '2px 5px', borderRadius: '3px' }}>{count.sent}</span>
                            </Link>
                            <Link to="#" onClick={() => { toggleTab("draft", "all", "all"); }} className={activeTabs === "draft" ? "active" : null}>
                                <i className="ri-edit-2-fill me-3 align-middle fw-medium"></i>
                                <span className="mail-list-link">
                                    Draft
                                </span>
                            </Link>

                            <Link to="#" onClick={() => { toggleTab("trash", "all", "all"); }} className={activeTabs === "trash" ? "active" : null}>
                                <i className="ri-delete-bin-5-fill me-3 align-middle fw-medium"></i>
                                <span className="mail-list-link">
                                    Trash
                                </span>
                            </Link>
                            <Link to="#" onClick={() => { toggleTab("starred", "all", "all"); }} className={activeTabs === "starred" ? "active" : null}>
                                <i className="ri-star-fill me-3 align-middle fw-medium"></i>
                                <span className="mail-list-link">
                                    Starred
                                </span>
                            </Link>
                        </div>
                    </SimpleBar>
                </div>
            </div>


            <div className="email-content">
                <div className="p-4 pb-0">
                    <div className="border-bottom border-bottom-dashed">
                        <Row className="mt-n2 mb-3 mb-sm-0">
                            <Col className="col-sm-auto order-1 d-block d-lg-none">
                                <button type="button" className="btn btn-soft-success btn-icon btn-sm fs-16 email-menu-btn">
                                    <i className="ri-menu-2-fill align-bottom"></i>
                                </button>
                            </Col>
                            <div className="col-sm order-3 order-sm-2">
                                <div className="hstack gap-sm-1 align-items-center flex-wrap email-topbar-link">
                                    <div className="form-check fs-14 m-0">
                                        <input className="form-check-input" type="checkbox" value="" id="checkall"
                                            onChange={e => {
                                                checkedAll(e.target.value);
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor="checkall"></label>
                                    </div>

                                    <div id="email-topbar-actions" style={{ display: "none" }}>
                                        <div className="hstack gap-sm-1 align-items-center flex-wrap">
                                            {/* <button type="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16" id="Tooltip1">
                                                <i className="ri-inbox-archive-fill align-bottom"></i>
                                            </button> */}
                                            {/* <button type="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16" id="Tooltip2">
                                                <i className="ri-error-warning-fill align-bottom"></i>
                                            </button> */}
                                            <button type="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16" id="Tooltip3" onClick={() => { setDeleteModal(true); }} >

                                                <i className="ri-delete-bin-5-fill align-bottom"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="vr align-self-center mx-2"></div>

                                    {/* <UncontrolledDropdown>
                                        <DropdownToggle tag="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
                                            <i className="ri-price-tag-3-fill align-bottom"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem>Support</DropdownItem>
                                            <DropdownItem>Freelance</DropdownItem>
                                            <DropdownItem>Social</DropdownItem>
                                            <DropdownItem>Friends</DropdownItem>
                                            <DropdownItem>Family</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown> */}

                                    <UncontrolledDropdown>
                                        <DropdownToggle tag="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
                                            <i className="ri-more-2-fill align-bottom"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem onClick={() => readAll()}>Mark all as Read</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                    <Alert color="warning" style={{ display: "none" }} className="unreadConversations-alert px-4 fade" id="unreadConversations">
                                        No Unread Conversations
                                    </Alert>

                                    {/* <UncontrolledTooltip placement="top" target="Tooltip1"> Archive </UncontrolledTooltip> */}
                                    {/* <UncontrolledTooltip placement="top" target="Tooltip2"> Report Spam </UncontrolledTooltip> */}
                                    <UncontrolledTooltip placement="top" target="Tooltip3"> Trash </UncontrolledTooltip>
                                </div>
                            </div>
                            <div className="col-auto order-2 order-sm-3">
                                <div className="d-flex gap-sm-1 email-topbar-link">
                                    <button type="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16" onClick={getEamils}>
                                        <i className="ri-refresh-line align-bottom"></i>
                                    </button>
                                    {/*<UncontrolledDropdown>
                                        <DropdownToggle tag="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
                                            <i className="ri-more-2-fill align-bottom"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem>Mark as Unread</DropdownItem>
                                            <DropdownItem>Mark as Important</DropdownItem>
                                            <DropdownItem>Add to Tasks</DropdownItem>
                                            <DropdownItem>Add Star</DropdownItem>
                                            <DropdownItem>Mute</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>*/}
                                </div>
                            </div>
                        </Row>

                        <Row className="row align-items-end mt-3">
                            <Col>
                                <div id="mail-filter-navlist">
                                    <Nav
                                        className="nav nav-tabs nav-tabs-custom nav-primary gap-1 text-center border-bottom-0"
                                        role="tablist"
                                    >
                                        <NavItem>
                                            <NavLink
                                                className={classnames(
                                                    { active: isTypeTab === "primary" },
                                                    "fw-semibold"
                                                )}
                                                onClick={() => {
                                                    toggleTab("all", "primary", "all");
                                                }}
                                                href="#"
                                            >
                                                <i className="ri-inbox-fill align-bottom d-inline-block"></i>
                                                <span className="ms-1 d-none d-sm-inline-block">Primary</span>
                                            </NavLink>
                                        </NavItem>

                                    </Nav>
                                </div>
                            </Col>
                            <div className="col-auto">
                                <div className="text-muted mb-2">1-50 of 154</div>
                            </div>
                        </Row>
                    </div>

                    <SimpleBar className="message-list-content mx-n4 px-4 message-list-scroll">
                        {isLoader ? <div id="elmLoader">
                            <div className="spinner-border text-primary avatar-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                            :
                            <ul className="message-list" id="mail-list">
                                {
                                    mailList?.filter(({ category, type, label }) => displayCategory === category || (displayCategory === "all" && (displaytype === type || displaytype === 'all') && (displaylabel === label || displaylabel === 'all'))).map((item, key) => (
                                        <li className={item.unread ? "unread" : null} key={key} id={item.id}>
                                            <div className="col-mail col-mail-1">
                                                <div className="form-check checkbox-wrapper-mail fs-14">
                                                    <input
                                                        onChange={(e) => {
                                                            onChangeCheckBox(e.target.value, e.target.checked, item.source_table);
                                                        }}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={item.id}
                                                        data-source={item.source_table}
                                                        id={item.id}
                                                    />
                                                    <label className="form-check-label" htmlFor={item.id}></label>
                                                </div>
                                                <button type="button" className="btn avatar-xs p-0 favourite-btn fs-15" onClick={(e) => favouriteBtn(e.target, item.id, item.source_table, item.starred)}>
                                                    <i className='ri-star-fill' style={{ color: item.starred === 1 ? '#f8bf3b' : '' }}/>
                                                </button>
                                                <Link to="#" className="title" onClick={(e) => { sidebarOpen("email-detail-show", e.target, item.id); setEmailinfo(item); }}>{item.name} {" "} {item.number} </Link>
                                            </div>
                                            <div className="col-mail col-mail-2" onClick={(e) => { sidebarOpen("email-detail-show", e.target, item); setEmailinfo(item); }}>
                                                <Link to="#" className="subject"> {item.badge ? <span className={"badge me-2 bg-" + item.badgeClass}>{item.badge}</span> : null} {item.subject} {/*<span className="teaser">{item.teaser}</span>*/}
                                                </Link>
                                                <div className="date" style={{ width: "169px",paddingLeft:'0px'}}>{item.date}</div>
                                            </div>
                                        </li>
                                    ))}
                            </ul>}
                    </SimpleBar>
                </div>
            </div>

            <div className="email-detail-content" style={{ left: "28%", width: "72%" }}>
                <div className="p-4 d-flex flex-column h-100">
                    <div className="pb-4 border-bottom border-bottom-dashed">
                        <Row>
                            <Col className="col">
                                <div className="">
                                    <button type="button" className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" onClick={() => sidebarClose("email-detail-show")}>
                                        <i className="ri-close-fill align-bottom"></i>
                                    </button>
                                </div>
                            </Col>
                            <Col className="col-auto">
                                <div className="hstack gap-sm-1 align-items-center flex-wrap email-topbar-link">
                                    <button className="btn btn-ghost-secondary btn-icon btn-sm fs-16" onClick={t_coll5} style={{ cursor: "pointer" }} title="Reply">
                                        <i className="ri-reply-fill align-bottom"></i>
                                    </button>
                                    <button className="btn btn-ghost-secondary btn-icon btn-sm fs-16" onClick={t_coll6} style={{ cursor: "pointer" }} title="Forward">
                                        <i className="ri-share-forward-fill align-bottom"></i>
                                    </button>
                                    <button type="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16 favourite-btn" title="Starred">
                                        {/* <Rating
                                            stop={1}
                                            emptySymbol="ri-star-fill align-bottom"
                                            fullSymbol="ri-star-fill text-muted align-bottom" style={{ color: emailinfo.category === 'starred' ? '#f8bf3b' : '' }}
                                        />*/}
                                        <i className='ri-star-fill' style={{ color: emailinfo.starred === 1 ? '#f8bf3b' : '' }}/>
                                    </button>
                                    <button className="btn btn-ghost-secondary btn-icon btn-sm fs-16" title="print">
                                        <i className="ri-printer-fill align-bottom"></i>
                                    </button>
                                    <button className="btn btn-ghost-secondary btn-icon btn-sm fs-16 remove-mail" title="delete" onClick={() => { setDeleteModal(true); }}>
                                        <i className="ri-delete-bin-5-fill align-bottom"></i>
                                    </button>
                                    {/*<UncontrolledDropdown>
                                        <DropdownToggle tag="button" className="btn btn-ghost-secondary btn-icon btn-sm fs-16">
                                            <i className="ri-more-2-fill align-bottom"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem>Mark as Unread</DropdownItem>
                                            <DropdownItem>Mark as Important</DropdownItem>
                                            <DropdownItem>Add to Tasks</DropdownItem>
                                            <DropdownItem>Add Star</DropdownItem>
                                            <DropdownItem>Mute</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>*/}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <SimpleBar className="mx-n4 px-4 email-detail-content-scroll">
                        <div className="mt-4 mb-3">
                            <h5 className="fw-bold email-subject-title">{emailinfo.subject}</h5>
                        </div>
                        <div className="accordion accordion-flush">
                            <div className="accordion-item border-dashed left">
                                <div className="accordion-header">
                                    <a role="button" href='/#'
                                        className="btn w-100 text-start px-0 bg-transparent shadow-none collapsed"
                                        id="email-collapseOne"
                                        onClick={toggleCollapse}>
                                        <div className="d-flex align-items-center text-muted">
                                            <div className="flex-shrink-0 avatar-xs me-3">
                                                <img src={emailinfo.img} alt="" className="img-fluid rounded-circle" />
                                            </div>
                                            <div className="flex-grow-1 overflow-hidden">
                                                <h5 className="fs-14 text-truncate mb-0 email-user-name">{emailinfo.name}</h5>
                                                <div className="text-truncate fs-12">{emailinfo.log_to}</div>
                                            </div>
                                            <div className="flex-shrink-0 align-self-start">
                                                <div className="text-muted fs-12">{emailinfo.date}</div>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <UncontrolledCollapse toggler="#email-collapseOne" isOpen={isOpen}>
                                    <div className="accordion-body text-body px-0">
                                        <div>
                                            {emailinfo && emailinfo.category !== "draft" && (
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: emailinfo.teaser,
                                                    }}
                                                ></p>
                                            )}
                                            {emailinfo && emailinfo.category === "draft" && (

                                                <div className="mt-auto">
                                                    <div className="mt-auto">
                                                        <div className="mb-3 position-relative">
                                                            <Input
                                                                type="hidden"
                                                                className="form-control email-compose-input"
                                                                //defaultValue="support@themesbrand.com"
                                                                value={id}
                                                                onChange={(e) =>
                                                                    setId(e.target.value)
                                                                }
                                                            />
                                                            <Input
                                                                type="text"
                                                                className="form-control email-compose-input"
                                                                //defaultValue="support@themesbrand.com"
                                                                value={toemail}
                                                                onChange={(e) =>
                                                                    setToEmail(e.target.value)
                                                                }
                                                            />
                                                            <div className="position-absolute top-0 end-0">
                                                                <div className="d-flex">
                                                                    <button onClick={t_coll3}
                                                                        className="btn btn-link text-reset fw-semibold px-2"
                                                                        type="button"
                                                                    >
                                                                        Cc
                                                                    </button>
                                                                    <button onClick={t_coll4}
                                                                        className="btn btn-link text-reset fw-semibold px-2"
                                                                        type="button"
                                                                    >
                                                                        Bcc
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Collapse isOpen={coll3} id="multiCollapseExample1">
                                                            <div className="mb-3">
                                                                <label>Cc:</label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Cc recipients"
                                                                    value={cc}
                                                                    onChange={(e) =>
                                                                        setcc(e.target.value)
                                                                    }
                                                                />
                                                            </div>
                                                        </Collapse>
                                                        <Collapse isOpen={coll4} id="multiCollapseExample1">
                                                            <div className="mb-3">
                                                                <label>Bcc:</label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Bcc recipients"
                                                                    value={bcc}
                                                                    aria-describedby="inputGroup-sizing-default"
                                                                    onChange={(e) =>
                                                                        setbcc(e.target.value)
                                                                    }
                                                                />
                                                            </div>
                                                        </Collapse>
                                                        <div className="mb-3">
                                                            <Input type="text" className="form-control" placeholder="Subject" value={sub}
                                                                onChange={(e) =>
                                                                    setSub(e.target.value)} />
                                                        </div>
                                                        <div className="ck-editor-reverse">
                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                onReady={(editor) => {
                                                                    // You can store the "editor" and use when it is needed.

                                                                }}
                                                                data={message || ""}
                                                                // onReady={(editor) => {}}
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData();
                                                                    setMessage(data);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer mt-3">
                                                        {/*<button
                                                        type="button"
                                                        className="btn btn-ghost-danger"
                                                        onClick={t_coll5}
                                                    >
                                                        Discard
                                                    </button>*/}
                                                        <button
                                                            type="button"
                                                            className="btn btn-success"
                                                            onClick={() => {
                                                                sendEmail();
                                                            }}
                                                        >
                                                            Send
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </UncontrolledCollapse>
                            </div>
                        </div>

                        <div className="mt-auto">
                            <Collapse isOpen={coll5} id="multiCollapseExample1">
                                <div className="mt-auto">
                                    <div className="mb-3 position-relative">
                                        <Input
                                            type="text"
                                            className="form-control email-compose-input"
                                            defaultValue="support@themesbrand.com"
                                            value={torepemail}
                                            onChange={(e) =>
                                                setTorepEmail(e.target.value)
                                            }
                                        />
                                        <div className="position-absolute top-0 end-0">
                                            <div className="d-flex">
                                                <button onClick={t_coll3}
                                                    className="btn btn-link text-reset fw-semibold px-2"
                                                    type="button"
                                                >
                                                    Cc
                                                </button>
                                                <button onClick={t_coll4}
                                                    className="btn btn-link text-reset fw-semibold px-2"
                                                    type="button"
                                                >
                                                    Bcc
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <Collapse isOpen={coll3} id="multiCollapseExample1">
                                        <div className="mb-3">
                                            <label>Cc:</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Cc recipients"
                                                value={ccrep}
                                                onChange={(e) =>
                                                    setccrep(e.target.value)
                                                }
                                            />
                                        </div>
                                    </Collapse>
                                    <Collapse isOpen={coll4} id="multiCollapseExample1">
                                        <div className="mb-3">
                                            <label>Bcc:</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Bcc recipients"
                                                value={bccrep}
                                                aria-describedby="inputGroup-sizing-default"
                                                onChange={(e) =>
                                                    setbccrep(e.target.value)
                                                }
                                            />
                                        </div>
                                    </Collapse>
                                    <div className="mb-3">
                                        <Input type="text" className="form-control" placeholder="Subject" value={subrep}
                                            onChange={(e) =>
                                                setSubrep(e.target.value)} />
                                    </div>
                                    <div className="ck-editor-reverse">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            onReady={(editor) => {
                                                // You can store the "editor" and use when it is needed.

                                            }}
                                            data={messagerep || ""}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setMessagerep(data);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-ghost-danger"
                                        onClick={t_coll5}
                                    >
                                        Discard
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                        onClick={() => {
                                            replyEmail();
                                        }}
                                    >
                                        Send
                                    </button>
                                </div>
                            </Collapse>
                        </div>

                        <div className="mt-3">
                            <Collapse isOpen={coll6} id="multiCollapseExample1">
                                <div className="mt-auto">
                                    <div className="mb-3 position-relative">
                                        <Input
                                            type="text"
                                            className="form-control email-compose-input"
                                            defaultValue="support@themesbrand.com"
                                            value={toforemail}
                                            onChange={(e) =>
                                                setToforEmail(e.target.value)
                                            }
                                        />
                                        <div className="position-absolute top-0 end-0">
                                            <div className="d-flex">
                                                <button onClick={t_coll3}
                                                    className="btn btn-link text-reset fw-semibold px-2"
                                                    type="button"
                                                >
                                                    Cc
                                                </button>
                                                <button onClick={t_coll4}
                                                    className="btn btn-link text-reset fw-semibold px-2"
                                                    type="button"
                                                >
                                                    Bcc
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <Collapse isOpen={coll3} id="multiCollapseExample1">
                                        <div className="mb-3">
                                            <label>Cc:</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Cc recipients"
                                                value={ccfor}
                                                onChange={(e) =>
                                                    setccfor(e.target.value)
                                                }
                                            />
                                        </div>
                                    </Collapse>
                                    <Collapse isOpen={coll4} id="multiCollapseExample1">
                                        <div className="mb-3">
                                            <label>Bcc:</label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                placeholder="Bcc recipients"
                                                value={bccfor}
                                                onChange={(e) =>
                                                    setbccfor(e.target.value)
                                                }
                                            />
                                        </div>
                                    </Collapse>
                                    <div className="mb-3">
                                        <Input type="text" className="form-control" placeholder="Subject" value={subfor}
                                            onChange={(e) =>
                                                setSubfor(e.target.value)} />
                                    </div>
                                    <div className="ck-editor-reverse">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            onReady={(editor) => {
                                                // You can store the "editor" and use when it is needed.

                                            }}
                                            data={messagefor || ""}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setMessagefor(data);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-ghost-danger"
                                        onClick={t_coll6}
                                    >
                                        Discard
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-success"
                                        onClick={() => {
                                            forwardEmail();
                                        }}
                                    >
                                        Send
                                    </button>
                                </div>
                            </Collapse>
                        </div>

                    </SimpleBar>
                </div>
            </div>
            <Modal id="composemodal" className="modal-lg" isOpen={modal} toggle={toggle} centered>
                {/*<ModalHeader className="p-3 bg-light" toggle={toggle}>
                    New Message
                </ModalHeader>*/}
                <h5 style={{ margin: "22px 10px 10px 28px" }}>New Message</h5>
                <ModalBody>
                    <div>
                        <div className="mb-3 position-relative">
                            <Input
                                type="text"
                                className="form-control email-compose-input"
                                //defaultValue="support@themesbrand.com"
                                placeholder="To"
                                value={toemail}
                                onChange={(e) =>
                                    setToEmail(e.target.value)
                                }
                            />
                            <div className="position-absolute top-0 end-0">
                                <div className="d-flex">
                                    <button onClick={t_coll3}
                                        className="btn btn-link text-reset fw-semibold px-2"
                                        type="button"
                                    >
                                        Cc
                                    </button>
                                    <button onClick={t_coll4}
                                        className="btn btn-link text-reset fw-semibold px-2"
                                        type="button"
                                    >
                                        Bcc
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Collapse isOpen={coll3} id="multiCollapseExample1">
                            <div className="mb-3">
                                <label>Cc:</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Cc recipients"
                                    value={cc}
                                    onChange={(e) =>
                                        setcc(e.target.value)
                                    }
                                />
                            </div>
                        </Collapse>
                        <Collapse isOpen={coll4} id="multiCollapseExample1">
                            <div className="mb-3">
                                <label>Bcc:</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Bcc recipients"
                                    value={bcc}
                                    onChange={(e) =>
                                        setbcc(e.target.value)
                                    }
                                />
                            </div>
                        </Collapse>
                        <div className="mb-3">
                            <Input type="text" className="form-control" placeholder="Subject" value={sub}
                                onChange={(e) =>
                                    setSub(e.target.value)} />
                        </div>
                        <div className="ck-editor-reverse">
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: {
                                        items: [
                                            'bold', 'italic', 'underline', 'strikethrough', '|',
                                            'bulletedList', 'numberedList', 'todoList', '|',
                                            'alignment', '|',
                                            'indent', 'outdent', '|',
                                            'fontBackgroundColor', 'fontColor', '|',
                                            'fontSize', '|',

                                        ]
                                    },
                                    // Other configurations...
                                }}
                                onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.

                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setMessage(data);
                                }}
                            />
                        </div>
                    </div>
                </ModalBody>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-ghost-danger"
                        /*onClick={() => {
                            setModal(false);
                        }}*/
                        onClick={() => {
                            discardEmail();
                        }}
                    >
                        Discard
                    </button>

                    <UncontrolledDropdown className="btn-group">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={sendEmail}
                            style={{ backgroundColor: '#865ce2', borderColor: '#865ce2' }}
                        >
                            Send
                        </button>
                        <DropdownToggle
                            tag="button"
                            type="button"
                            className="btn btn-success"
                            split
                            style={{ backgroundColor: '#865ce2', borderColor: '#865ce2' }}
                        >
                            <span className="visually-hidden">Toggle Dropdown</span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <li>
                                <DropdownItem href="#">
                                    <i className="ri-timer-line text-muted me-1 align-bottom"></i>{" "}
                                    Schedule Send
                                </DropdownItem>
                            </li>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                </div>
            </Modal>
            {isLoading && (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                    }}
                >
                    <Loader />
                </div>
            )}
        </React.Fragment >
    );
};

export default EmailToolbar;