import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  UncontrolledAlert,
  TabContent,
  TabPane,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Button } from "reactstrap";
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import { CardHeader, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
//dateformat
import moment from "moment";
import { get_cookie } from "../../../helpers/get_cookie";
//reloading the page
import { jsx } from "@emotion/react";
import { ClipLoader } from "react-spinners";
//toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";
import Dropzone from "react-dropzone";
import uploadImg from "../../../assets/images/freepik/uploading_img.png";
import img13 from "../../../assets/images/small/img-12.jpg";
import { api } from "../../../config";
import GoogleAutocomplete from "react-google-autocomplete";
import { useParams } from "react-router-dom";
import classnames from "classnames";

export const Holidays = () => {
  document.title = "General | Roster";
  const params = useParams();
  const site_id = params.id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [site_name, setSiteName] = useState("");
  // operation hours
  const [state_name, setState_name] = useState("");
  const [holiday_full_address, setHoliday_full_address] = useState("");
  const [selectedYear, setSelectedYear] = useState("2024");
  const [public_holiday, setPublicHoliday] = useState();
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res);
        loadgeneral(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const loadgeneral = (userId) => {
    setisLoading(true);
    const formData = {
      id: site_id,
      user_id: userId,
      business_id: business_id,
    };
    axios
      .post("/get-site-data", formData)
      .then((response) => {
        if (response.error === 1) {
          setSiteName(response?.site_data.site_name);
          setState_name(response?.state_name);
          setHoliday_full_address(response?.holiday_full_address);
          setPublicHoliday(response?.public_holidays);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const handleYearChange = (tab) => {
    if (selectedYear !== tab) {
      setSelectedYear(tab);
    }
  };

  return (
    <div>
      <Card id="contactList">
        <CardHeader>
          <h5 className="mb-0 text-primary">Public Holidays</h5>
        </CardHeader>
        {isLoading ? (
          <>
            <CardBody>
              <div className="mt-0">
                <div className="d-flex align-items-center mb-2"></div>
                <div>
                  <Row className="gy-3">
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-2 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-4 bg-dark"></span>
                    </h3>

                    <TabPane tabId={2}>
                      <div className="mt-4">
                        <div className="d-flex align-items-center mb-2"></div>
                        <div>
                          <Row className="gy-3">
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-4 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                            </h3>
                          </Row>
                        </div>
                      </div>
                    </TabPane>
                  </Row>
                </div>
              </div>
            </CardBody>
          </>
        ) : (
          <>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <p>
                      <span>
                        <i
                          className="text-secondary fs-18 me-1 bx bx-globe"
                          style={{ verticalAlign: "middle" }}
                        ></i>
                      </span>
                      <b> Site Name:</b> <span>{site_name}</span>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="mb-5">
                    <p>
                      <span>
                        <i
                          className="text-danger fs-18 me-1 bx bx-map"
                          style={{ verticalAlign: "middle" }}
                        ></i>
                      </span>
                      <b> Site Address:</b> {holiday_full_address}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <h4>
                      <span></span>
                      {state_name} - Public Holidays
                      {/* <b> TimeZone:</b> {selectedRow?.timezone} */}
                    </h4>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={8}>
                  <div className="mb-3">
                    <Nav pills className="nav-tabs mb-3">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={selectedYear === "2024" ? "active" : ""}
                          onClick={() => handleYearChange("2024")}
                        >
                          2024
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={selectedYear === "2025" ? "active" : ""}
                          onClick={() => handleYearChange("2025")}
                        >
                          2025
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={selectedYear} className="text-muted">
                      <TabPane tabId="2024">
                        <div className="table-responsive">
                          <Table className="table-bordered align-middle table-nowrap mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Day</th>
                                <th scope="col">Holiday</th>
                              </tr>
                            </thead>
                            <tbody>
                              {public_holiday &&
                                public_holiday[selectedYear] &&
                                public_holiday[selectedYear].map(
                                  (item, index) => (
                                    <tr key={index}>
                                      <td>{item.date}</td>
                                      <td>{item.day}</td>
                                      <td>{item.holiday}</td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </Table>
                        </div>
                      </TabPane>
                      <TabPane tabId="2025">
                        <div className="table-responsive">
                          <Table className="table-bordered align-middle table-nowrap mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Day</th>
                                <th scope="col">Holiday</th>
                              </tr>
                            </thead>
                            <tbody>
                              {public_holiday &&
                                public_holiday[selectedYear] &&
                                public_holiday[selectedYear].map(
                                  (item, index) => (
                                    <tr key={index}>
                                      <td>{item.date}</td>
                                      <td>{item.day}</td>
                                      <td>{item.holiday}</td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </Table>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </>
        )}
      </Card>
    </div>
  );
};
