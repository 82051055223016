import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Button,
  Spinner,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { get_cookie } from "../../helpers/get_cookie";
import axios from "axios";

const ManageTeamDetail = ({ UserId, workforceId, toggle }) => {
  const user_id = JSON.parse(get_cookie("authUser")).user_id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  
  const [modal_grid, setmodal_grid] = useState(false);
  const [userId, setUserId] = useState(null);

  console.log(UserId, workforceId);
  //constants to store the main data
  const [manageTeamData, setManageTeamData] = useState([]);
  const [detailHeader, setDetailHeader] = useState();
  const [payRates, setPayRates] = useState();
  const [siteAssigned, setSiteAssigned] = useState();
  const [isLoading, setIsLoading] = useState(true);
  console.log(payRates, "payRates");
  const loadData = () => {
    setIsLoading(true);
    let ManageTeamData;
    if (user_id != null) {
      ManageTeamData = {
        workforce_id: workforceId,
        user_id: UserId,
        business_id: business_id,
      };
      axios
        .post("/manage-team-detail", ManageTeamData)
        .then((res) => {
          console.log(res, "Manage Team details");
          if (res.error === 0) {
            setManageTeamData(res.data);
            setDetailHeader(res.data.detail_header);
            setPayRates(res.data.pay_rates);
            setSiteAssigned(res.data.sites_assigned);
            console.log(res);
            setIsLoading(false);
          }
        })
        .then((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  // -------- End ----------- //

  document.title = "Manage Team Detail | Get Rostered";
  return (
    <React.Fragment>
      <div className="pt-4">
        <Container fluid>
          {!isLoading ? (
            <>
              <div className="mb-2 mb-lg-0 pb-lg-4 profile-wrapper ">
                <Row className="g-4 ">
                  <Col>
                    <div className="px-2">
                      {manageTeamData && (
                        <>
                          <div className="hstack justify-content-between">
                            <h4 className="text-primary mb-3">
                              {`${detailHeader.first_name} ${detailHeader.last_name}`}
                            </h4>
                            <div className="text-end mb-3">
                              <button
                                onClick={toggle}
                                type="botton"
                                className="custom-close-btn"
                              >
                                <i className="ri-close-fill"></i>
                              </button>
                            </div>
                          </div>
                          <div>
                            <div className="me-2 hstack mb-1">
                              <i className="text-primary bx bx-briefcase fs-18 me-1"></i>
                              <p className="fs-14 fw-normal mb-0">
                                {detailHeader?.work_title ? (
                                  detailHeader.work_title
                                ) : (
                                  <i className="text-muted fs-12">
                                    Job title not specified
                                  </i>
                                )}
                              </p>
                            </div>
                            {/* <span className="h4 fw-normal fs-16 mb-1">
                              {detailHeader?.work_title}
                            </span> */}
                            <div className="me-2 hstack mb-1">
                              <i className="text-primary bx bx-map-pin fs-18 me-1"></i>
                              <p className="fs-14 fw-normal mb-0">
                                {detailHeader?.full_address ? (
                                  detailHeader.full_address
                                ) : (
                                  <i className="text-muted fs-12">
                                    Location not specified
                                  </i>
                                )}
                              </p>
                            </div>
                            <div className="me-2 hstack mb-4">
                              <i className="text-primary bx bxs-phone fs-18 me-1"></i>
                              <p className="fs-14 fw-normal mb-0">
                                {detailHeader?.mobile ? (
                                  detailHeader.mobile
                                ) : (
                                  <i className="text-muted fs-12">
                                    Mobile number not specified
                                  </i>
                                )}
                              </p>
                            </div>
                          </div>
                          {/* <hr /> */}
                        </>
                      )}

                      <div className="">
                        <p className="h4 text-primary mb-2 ">Pay Rates</p>
                        {/* <Row>
                          <Col xl={8} className="mb-2">
                            <div className="hstack justify-content-between">
                              <span>
                                {console.log(payRates,"payRates13")}
                                <b className="">Week Day: </b>{" "}
                                {payRates && payRates.week_day == null ? (
                                   <i className="text-muted fs-12">
                                   $00.00
                                 </i>
                                ) : (
                                  payRates.week_day
                                )}
                              </span>
                              <span>
                                <b className="">Saturday: </b>{" "}
                                {payRates && payRates.saturday == null ? (
                                  <i className="text-muted fs-12">
                                  $00.00
                                </i>
                                ) : (
                                  payRates.saturday
                                )}
                              </span>
                            </div>
                          </Col>
                        </Row> */}
                        <Row>
                          <Col xl={8} className="mb-2">
                            <div className="hstack justify-content-between">
                              <span>
                                {console.log(payRates, "payRates13")}
                                <b className="">Week Day: </b>{" "}
                                {payRates === null ||
                                payRates.week_day === null ? (
                                  <i className="text-muted fs-12">$00.00</i>
                                ) : (
                                  payRates.week_day
                                )}
                              </span>
                              <span>
                                <b className="">Saturday: </b>{" "}
                                {payRates === null ||
                                payRates.saturday === null ? (
                                  <i className="text-muted fs-12">$00.00</i>
                                ) : (
                                  payRates.saturday
                                )}
                              </span>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl={8}>
                            <div className="hstack justify-content-between mb-4">
                              <span>
                                <b className="">Sunday: </b>{" "}
                                {payRates === null ||
                                payRates.sunday === null ? (
                                  <i className="text-muted fs-12">$00.00</i>
                                ) : (
                                  payRates && payRates.sunday
                                )}
                                {/* {payRates.sunday} */}
                              </span>
                              <span>
                                <b className="">Public Holiday:</b>{" "}
                                {payRates === null ||
                                payRates.public_holiday === null ? (
                                  <i className="text-muted fs-12">$00.00</i>
                                ) : (
                                  payRates && payRates.public_holiday
                                )}
                                {/* {payRates.public_holiday} */}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        {/* <hr /> */}
                      </div>
                      <div>
                        <p className="h4 text-primary mb-2">Sites Assigned</p>
                        <div>
                          <div className="vstack col-12">
                            {siteAssigned && siteAssigned.length >= 1 ? (
                              siteAssigned.map((site) => (
                                <div
                                  key={site.id}
                                  className="me-2 hstack justify-content-between mb-1"
                                >
                                  <div className="me-2 hstack mb-1">
                                    <i className="bx bx-globe text-primary me-1 fs-14 text-sucess align-middle"></i>
                                    <p className="fs-14 fw-normal mb-0">
                                      {site.site_name}
                                    </p>
                                  </div>
                                  <div className="me-2 hstack mb-1">
                                    <i className="bx bxs-map text-primary me-1 fs-14 text-sucess align-middle"></i>
                                    <p className="fs-14 fw-normal mb-0">
                                      {site.site_address}
                                    </p>
                                  </div>
                                  <div className="me-2 hstack mb-1">
                                    <i className="bx bx-time text-primary me-1 fs-14 text-sucess align-middle"></i>
                                    <p className="fs-14 fw-normal mb-0">
                                      {site.time_zone}
                                    </p>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <i className="text-muted">
                                No sites Assigned for this Member
                              </i>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <div className="mb-2 mb-lg-0 pb-lg-4 profile-wrapper ">
                <Row className="g-4 ">
                  <Col>
                    <div className="px-2">
                      <Row className="text-primary mb-1 placeholder-glow hstack justify-content-between">
                        <Col xs={8}>
                          <span className="placeholder col-4 bg-dark"></span>
                        </Col>
                      </Row>

                      <div className="hstack  h6 gap-2 placeholder-glow">
                        {/* <span className="placeholder col-2 bg-dark"></span> */}
                        <div className="placeholder-glow col-4">
                          <span className="placeholder col-6 bg-dark"></span>
                        </div>
                      </div>
                      <p className="text-muted placeholder-glow">
                        <span className="placeholder col-1 bg-dark"></span>
                      </p>
                      <div className="mt-4">
                        <p className="text-primary h4">Pay Rates</p>
                        <p className=" placeholder-glow">
                          <span className="placeholder col-6 bg-dark"></span>
                          <span className="placeholder col-8 bg-dark"></span>
                          <span className="placeholder col-8 bg-dark"></span>
                        </p>
                      </div>
                      <div className="pt-3">
                        <p className="text-primary h4">Sites Assigned</p>
                        <div className="my-2 placeholder-glow">
                          <span className="placeholder col-3 bg-dark me-2"></span>
                          <span className="placeholder col-3 bg-dark me-2"></span>
                          <span className="placeholder col-3 bg-dark me-2"></span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col>
                    <div className="mt-0 pt-4 px-2">
                      <h5>About the Business</h5>
                      <div className="hstack gap-4 align-items-center mt-2 placeholder-glow">
                        <span className="placeholder col-2 bg-dark"></span>{" "}
                        <span className="placeholder col-2 bg-dark"></span>{" "}
                      </div>
                      <div
                        className="mt-3 placeholder-glow"
                        style={{ maxHeight: "200px", overflow: "scroll" }}
                      >
                        <span className="placeholder col-6 bg-dark"></span>
                        <span className="placeholder col-7 bg-dark"></span>
                        <span className="placeholder col-7 bg-dark"></span>
                        <span className="placeholder col-7 bg-dark"></span>
                      </div>
                    </div>
                  </Col>
                </Row> */}
              </div>
            </>
          )}
        </Container>
      </div>
      {/* <ToastContainer closeButton={false} limit={1} /> */}
    </React.Fragment>
  );
};

export default ManageTeamDetail;
