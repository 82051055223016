import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Input,
  Accordion,
  AccordionItem,
  Collapse,
  Label,
} from "reactstrap";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
export const Widgets = ({ shiftStatusCounts }) => {
  const borderRightStyle = {
    borderRight: "1px solid #dee2e6",
  };

  const borderNoneStyle = {
    borderRight: "none",
  };

  const divStyle = {
    padding: "1rem",
    textAlign: "center",
  };

  const innerDivStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  };

  const h5Style = {
    marginBottom: "0.5rem",
  };
  return (
    <>
      <>
      <Row>
          <Col xl={12} className="pe-0 ps-0">
            <Card className="crm-widget mt-2">
              <CardBody className="p-0">
                <Row className="">
                  {shiftStatusCounts &&
                    Object.entries(shiftStatusCounts).map(([status, count]) => (
                      
                      <div
                        className={status !== "unPublished" ? "border-end" : "border-none "}
                        key={status}
                        style={{width:"20%"}}
                      >
                     
                        <div className="mt-3 mt-md-0 py-3 px-3">
                          <div className="d-flex align-items-center justify-content-between">
                            <h5 className="text-muted text-uppercase fs-13 mb-0">
                              {status}{" "}
                            </h5>

                            <h5 className="mb-0 roster-count-view text-center">
                             
                                <CountUp
                                  start={0}
                                  end={count}
                                  decimals={0}
                                  duration={4}
                                />
                             
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row> 
        {/* <Row>
          <Col xl={12} className="pe-0 ps-0">
            <Card className="crm-widget mt-2">
              <CardBody className="p-0">
                <Row className="justify-content-between">
                  {shiftStatusCounts &&
                    Object.entries(shiftStatusCounts).map(([status, count]) => (
                      <Col
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={2}
                        key={status}
                        style={count <= 4 ? borderRightStyle : borderNoneStyle}
                      >
                        <div style={divStyle}>
                          <div style={innerDivStyle}>
                            <h5
                              style={h5Style}
                              className="text-muted text-uppercase fs-13 mb-0"
                            >
                              {status}
                            </h5>
                            <h5 className="mb-0 roster-count-view">
                              <CountUp
                                start={0}
                                end={count}
                                decimals={0}
                                duration={4}
                              />
                            </h5>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
      </>
    </>
  );
};
