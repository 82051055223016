import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  Fragment,
} from "react";
import img13 from "../../../assets/images/small/img-13.png";
import { Button } from "reactstrap";
import { isEmpty } from "lodash";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../../assets/scss/pages/_settings.scss";
import { CardHeader, Label, Input } from "reactstrap";
//reloading the page
import { jsx } from "@emotion/react";
import { ClipLoader } from "react-spinners";
//toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// axios
import axios from "axios";
import Loader from "../../../Components/Common/Loader";
import { get_cookie } from "../../../helpers/get_cookie";
import { Link } from "react-router-dom";
import classnames from "classnames";

//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export const Notifications = () => {
  document.title = "Notifications | Get Rostered";

  const User_id = JSON.parse(get_cookie("authUser")).user_id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  console.log(User_id);

  const [isLoading, setisLoading] = useState(true);
  const [notificationData, setNotificationData] = useState();
  const [personalisedData, setPersonalisedData] = useState();
  const [checked, setChecked] = useState(false);
  const [activeTab, setactiveTab] = useState("1");
  //Modal
  const [modalEmailSubjectEdit, setModalEmailSubjectEdit] = useState(false);
  const [modalEmailSubjectView, setModalEmailSubjectView] = useState(false);
  const [id, setId] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const [notificationSubject, setNotificationSubject] = useState("");
  const [notificationContent, setNotificationContent] = useState("");
  const [type, setType] = useState("");
  const [tag, setTag] = useState("");
  const [emailSub, setEmailSub] = useState("");
  const [notiSub, setNotiSub] = useState("");
  const [notiCont, setNotiCont] = useState("");
  const editorRef = useRef(null);
  const editorEmailSubRef = useRef(null);
  const editorNotiSubRef = useRef(null);
  const editorNotiContRef = useRef(null);
  const handleOptionChange = (selectedOption) => {
    setTag({ value: selectedOption.value, label: selectedOption.label });
  };
  const handleEditorReady = (editor) => {
    editorRef.current = editor;
  };
  const handleUse = () => {
    if (editorRef.current && tag) {
      const editor = editorRef.current;
      const viewFragment = editor.data.processor.toView(tag.value);
      const modelFragment = editor.data.toModel(viewFragment);
      editor.model.insertContent(
        modelFragment,
        editor.model.document.selection
      );
    }
  };

  //Notification Content
  const handleNotiCont = (selectedOption) => {
    setNotiCont({ value: selectedOption.value, label: selectedOption.label });
  };
  const handleEditorReadyNotiCont = (editor) => {
    editorRef.current = editor;
  };
  const handleNotiContUse = () => {
    if (editorRef.current && notiCont) {
      const editor = editorRef.current;
      const viewFragment = editor.data.processor.toView(notiCont.value);
      const modelFragment = editor.data.toModel(viewFragment);
      editor.model.insertContent(
        modelFragment,
        editor.model.document.selection
      );
    }
  };
  //Notification Subject
  const handleEmailSub = (selectedOption) => {
    setEmailSub(selectedOption);
  };
  const handleEmailSubUse = () => {
    if (emailSub) {
      setEmailSubject((prevSubject) => `${prevSubject} ${emailSub.value}`);
    }
  };
  //Notification Subject
  const handleNotiSub = (selectedOption) => {
    setNotiSub(selectedOption);
  };
  const handleNotiSubUse = () => {
    if (notiSub) {
      setNotificationSubject(
        (prevSubject) => `${prevSubject} ${notiSub.value}`
      );
    }
  };
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const getData = () => {
    const formData = {
      user_id: User_id,
      business_id: business_id,
    };
    axios
      .post("/get-notifications-data", formData)
      .then((res) => {
        console.log(res.notification_details);
        setNotificationData(res.notification_details);
        setPersonalisedData(res.personalised_tags);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const setSendPushNotification = (id, value, notificationType) => {
    setNotificationData((prevData) =>
      prevData.map((notification) =>
        notification.id === id
          ? { ...notification, send_push_notification: value }
          : notification
      )
    );
    /* setisLoading(true);*/
    const formData = {
      user_id: User_id,
      business_id: business_id,
      id: id,
      value: value,
      type: notificationType,
    };
    console.log(formData);
    axios
      .post("/update-email-notification-status", formData)
      .then((res) => {
        console.log(res);
        getData();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  const setSendEmail = (id, value, notificationType) => {
    setNotificationData((prevData) =>
      prevData.map((notification) =>
        notification.id === id
          ? { ...notification, send_email: value }
          : notification
      )
    );
    /* setisLoading(true);*/
    const formData = {
      user_id: User_id,
      business_id: business_id,
      id: id,
      value: value,
      type: notificationType,
    };
    console.log(formData);
    axios
      .post("/update-email-notification-status", formData)
      .then((res) => {
        console.log(res);
        getData();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  const toggleModalEmailSubjectEdit = () => {
    setModalEmailSubjectEdit(!modalEmailSubjectEdit);
  };
  const toggleModalEmailSubjectView = () => {
    setModalEmailSubjectView(!modalEmailSubjectView);
  };
  const emailSubjectEdit = (notification, type) => {
    console.log(notification);
    setModalEmailSubjectEdit(true);
    setisLoading(true);
    setId(notification.id);
    setEmailSubject(notification.email_subject);
    setType(type);
    setEmailContent(notification.email_content);
    setNotificationSubject(notification.push_notification_subject);
    setNotificationContent(notification.push_notification_content);
    setisLoading(false);
  };
  const emailSubjectView = (notification, type) => {
    console.log(notification);
    setModalEmailSubjectView(true);
    setisLoading(true);
    setId(notification.id);
    setEmailSubject(notification.email_subject);
    setType(type);
    setEmailContent(notification.email_content);
    setNotificationSubject(notification.push_notification_subject);
    setNotificationContent(notification.push_notification_content);
    setisLoading(false);
  };

  const handleSubmit = () => {
    const formData = {
      user_id: User_id,
      business_id: business_id,
      id: id,
      type: type,
      emailSubject: emailSubject,
      emailContent: emailContent,
      notificationSubject: notificationSubject,
      notificationContent: notificationContent,
    };

    console.log(formData);

    // Update Permission:
    axios
      .post("/update-notification-details", formData)
      .then((response) => {
        console.log(response);
        if (response.error === 0) {
          toast.success(response.message, { theme: "light" });
          setModalEmailSubjectEdit(false);
          getData();
        } else {
          toast.error(response.message);
          setModalEmailSubjectEdit(true);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 1000, // Ensure the dropdown appears above other elements
      //position: 'absolute', // Ensure the dropdown is positioned correctly
    }),
    control: (provided) => ({
      ...provided,
      //marginLeft: '20px', // Apply margin to the control
    }),
  };
  return (
    <div>
      <Card>
        <CardHeader >
          <div className="d-flex align-items-center flex-wrap gap-2">
          <h5 className="mb-0 text-primary">Notifications</h5>
          </div>
       
        </CardHeader>
        <CardBody>
          <Card>
            <CardBody>
              <Nav
                tabs
                className="nav nav-tabs nav-tabs-custom nav nav-justified mb-3"
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Settings
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Edit Content
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="1" id="settings">
                  <h6>Settings</h6>
                  <Card>
                    <CardBody>
                      {isLoading ? (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background: "rgba(255, 255, 255, 0.8)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 9999,
                          }}
                        >
                          <Loader />
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <Table className="table-bordered align-middle mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" style={{ minWidth: "15rem" }}>
                                  Notifications
                                </th>
                                <th
                                  scope="col"
                                  className="text-center"
                                  style={{ minWidth: "10rem" }}
                                >
                                  Send Email
                                </th>
                                <th
                                  scope="col"
                                  className="text-center"
                                  style={{ minWidth: "10rem" }}
                                >
                                  Send Push Notifications
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {notificationData?.map((notification) => (
                                <tr key={notification.id}>
                                  <td className="fw-medium">
                                    {notification.log_type}
                                  </td>
                                  <td className="">
                                    <div className="form-check form-check-inline w-100 d-flex justify-content-center align-center">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`formCheck2-${notification.id}`}
                                        checked={
                                          notification.send_email === "Y"
                                        }
                                        onChange={(e) =>
                                          setSendEmail(
                                            notification.id,
                                            e.target.checked ? "Y" : "N",
                                            "sendEmail"
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td className="">
                                    <div className="form-check form-check-inline w-100 d-flex justify-content-center align-center">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`formCheck2-${notification.id}`}
                                        checked={
                                          notification.send_push_notification ===
                                          "Y"
                                        }
                                        onChange={(e) =>
                                          setSendPushNotification(
                                            notification.id,
                                            e.target.checked ? "Y" : "N",
                                            "sendPushNotification"
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </TabPane>

                <TabPane tabId="2" id="edit_content">
                  <h6>Edit Content</h6>
                  <Card>
                    <CardBody>
                      {isLoading ? (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background: "rgba(255, 255, 255, 0.8)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 9999,
                          }}
                        >
                          <Loader />
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <Table className="table-bordered align-middle mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col" style={{ minWidth: "15rem" }}>
                                  Notifications
                                </th>
                                <th
                                  scope="col"
                                  className="text-center"
                                  style={{ minWidth: "10rem" }}
                                >
                                  Email Subject
                                </th>
                                <th
                                  scope="col"
                                  className="text-center"
                                  style={{ minWidth: "10rem" }}
                                >
                                  Email Content
                                </th>
                                <th
                                  scope="col"
                                  className="text-center"
                                  style={{ minWidth: "10rem" }}
                                >
                                  Notification Subject
                                </th>
                                <th
                                  scope="col"
                                  className="text-center"
                                  style={{ minWidth: "10rem" }}
                                >
                                  Notification Content
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {notificationData?.map((notification) => (
                                <tr key={notification.id}>
                                  <td className="fw-medium">
                                    {notification.log_type}
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 fs-15 d-flex justify-content-center align-center">
                                      <button
                                        className="btn btn-sm btn-soft-success copy-list"
                                        onClick={() =>
                                          emailSubjectView(
                                            notification,
                                            "emailSubject"
                                          )
                                        }
                                      >
                                        <i
                                          className="bx bx-show-alt fs-12 pt-1"
                                          id="emailsubjectcopytooltip"
                                        ></i>
                                      </button>

                                      <button
                                        className="btn btn-sm btn-soft-warning edit-list"
                                        onClick={() =>
                                          emailSubjectEdit(
                                            notification,
                                            "emailSubject"
                                          )
                                        }
                                      >
                                        <i
                                          className="bx bxs-pencil fs-12 pt-1"
                                          id="emailsubjectedittooltip"
                                        ></i>
                                      </button>

                                      {/*<a href="#" className="link-primary" onClick={() => emailSubjectView(notification,"emailSubject")}><i className="ri-eye-fill"></i></a>
                                                                              <a href="#" className="link-danger" onClick={() => emailSubjectEdit(notification,"emailSubject")}><i className="ri-pencil-fill"></i></a>*/}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 fs-15 d-flex justify-content-center align-center">
                                      <button
                                        className="btn btn-sm btn-soft-success copy-list"
                                        onClick={() =>
                                          emailSubjectView(
                                            notification,
                                            "emailContent"
                                          )
                                        }
                                      >
                                        <i
                                          className="bx bx-show-alt fs-12 pt-1"
                                          id="emailcontentcopytooltip"
                                        ></i>
                                      </button>

                                      <button
                                        className="btn btn-sm btn-soft-warning edit-list"
                                        onClick={() =>
                                          emailSubjectEdit(
                                            notification,
                                            "emailContent"
                                          )
                                        }
                                      >
                                        <i
                                          className="bx bxs-pencil fs-12 pt-1"
                                          id="emailcontentedittooltip"
                                        ></i>
                                      </button>

                                      {/*<a href="#" className="link-primary" onClick={() => emailSubjectView(notification,"emailContent")}><i className="ri-eye-fill"></i></a>
                                                                              <a href="#" className="link-danger" onClick={() => emailSubjectEdit(notification,"emailContent")}><i className="ri-pencil-fill"></i></a>*/}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 fs-15 d-flex justify-content-center align-center">
                                      <button
                                        className="btn btn-sm btn-soft-success copy-list"
                                        onClick={() =>
                                          emailSubjectView(
                                            notification,
                                            "notificationSubject"
                                          )
                                        }
                                      >
                                        <i
                                          className="bx bx-show-alt fs-12 pt-1"
                                          id="notificationsubjectcopytooltip"
                                        ></i>
                                      </button>
                                      <button
                                        className="btn btn-sm btn-soft-warning edit-list"
                                        onClick={() =>
                                          emailSubjectEdit(
                                            notification,
                                            "notificationSubject"
                                          )
                                        }
                                      >
                                        <i
                                          className="bx bxs-pencil fs-12 pt-1"
                                          id="notificationsubjectedittooltip"
                                        ></i>
                                      </button>
                                      {/*<a href="#" className="link-primary" onClick={() => emailSubjectView(notification,"notificationSubject")}><i className="ri-eye-fill"></i></a>
                                                                              <a href="#" className="link-danger" onClick={() => emailSubjectEdit(notification,"notificationSubject")}><i className="ri-pencil-fill"></i></a>*/}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="hstack gap-3 fs-15 d-flex justify-content-center align-center">
                                      <button
                                        className="btn btn-sm btn-soft-success copy-list"
                                        onClick={() =>
                                          emailSubjectView(
                                            notification,
                                            "notificationContent"
                                          )
                                        }
                                      >
                                        <i
                                          className="bx bx-show-alt fs-12 pt-1"
                                          id="notificationcontentcopytooltip"
                                        ></i>
                                      </button>
                                      <button
                                        className="btn btn-sm btn-soft-warning edit-list"
                                        onClick={() =>
                                          emailSubjectEdit(
                                            notification,
                                            "notificationContent"
                                          )
                                        }
                                      >
                                        <i
                                          className="bx bxs-pencil fs-12 pt-1"
                                          id="notificationcontentedittooltip"
                                        ></i>
                                      </button>
                                      {/*<a href="#" className="link-primary" onClick={() => emailSubjectView(notification,"notificationContent")}><i className="ri-eye-fill"></i></a>
                                                                            <a href="#" className="link-danger" onClick={() => emailSubjectEdit(notification,"notificationContent")}><i className="ri-pencil-fill"></i></a>*/}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>

          {/*// email subject Edit */}
          {isLoading ? (
            <>
              <Modal
                className="zoomIn"
                id="addSeller"
                size="lg"
                isOpen={modalEmailSubjectEdit}
                toggle={toggleModalEmailSubjectEdit}
                centered
              >
                <ModalHeader toggle={toggleModalEmailSubjectEdit}>
                  Edit Email Subject
                </ModalHeader>
                <div className="modal-content border-0 mt-3"></div>
                <ModalBody>
                  <Row>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-4 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                    </h3>
                  </Row>
                </ModalBody>
              </Modal>
            </>
          ) : (
            <>
              <Modal
                className="zoomIn"
                id="addSeller"
                size="lg"
                isOpen={modalEmailSubjectEdit}
                toggle={toggleModalEmailSubjectEdit}
                centered
              >
                <ModalHeader toggle={toggleModalEmailSubjectEdit}>
                  Edit Details
                </ModalHeader>
                <div className="modal-content border-0 mt-3"></div>
                <ModalBody>
                  <Row>
                    <Col lg={12}>
                      {type === "emailSubject" && (
                        <div className="mb-3">
                          <Input
                            type="hidden"
                            value={id}
                            onChange={(e) => {
                              setId(e.target.value);
                            }}
                            id="firstnameInput"
                          />
                          <Row className="mb-3">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Col md={4}>
                                <Label
                                  for="firstnameInput"
                                  className="form-label"
                                >
                                  Email Subject
                                </Label>
                              </Col>
                              <Col md={3}>
                                <Label
                                  for="firstnameInput"
                                  className="form-label"
                                  style={{ marginLeft: "20px" }}
                                >
                                  Personalisation Tags
                                </Label>
                              </Col>
                              <Col
                                md={4}
                                style={{ zIndex: 1, marginLeft: "-16px" }}
                              >
                                <Select
                                  value={emailSub}
                                  onChange={(selectedOption) => {
                                    handleEmailSub(selectedOption);
                                  }}
                                  options={
                                    personalisedData &&
                                    Array.isArray(personalisedData)
                                      ? personalisedData.map((item) => ({
                                          value: item.tag,
                                          label: item.tag_name,
                                        }))
                                      : []
                                  }
                                  styles={customStyles}
                                />
                              </Col>
                              <Col md={1}>
                                <button
                                  type="submit"
                                  className="btn btn-light"
                                  style={{
                                    color: "white",
                                    marginLeft: "21px",
                                    backgroundColor: "#865ce2",
                                  }}
                                  onClick={handleEmailSubUse}
                                >
                                  Use
                                </button>
                              </Col>
                            </div>
                          </Row>
                          <textarea
                            type="text"
                            value={emailSubject}
                            onChange={(e) => {
                              setEmailSubject(e.target.value);
                            }}
                            className="form-control"
                            id="firstnameInput"
                            placeholder="Enter Email Subject"
                          />
                        </div>
                      )}
                      {type === "emailContent" && (
                        <div className="mb-3">
                          <Input
                            type="hidden"
                            value={id}
                            onChange={(e) => {
                              setId(e.target.value);
                            }}
                            id="firstnameInput"
                          />
                          <Row className="mb-3">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Col md={4}>
                                <Label
                                  for="firstnameInput"
                                  className="form-label"
                                >
                                  Email Content
                                </Label>
                              </Col>
                              <Col md={3}>
                                <Label
                                  for="firstnameInput"
                                  className="form-label"
                                  style={{ marginLeft: "20px" }}
                                >
                                  Personalisation Tags
                                </Label>
                              </Col>
                              <Col
                                md={4}
                                style={{ zIndex: 1, marginLeft: "-16px" }}
                              >
                                <Select
                                  value={tag}
                                  onChange={(selectedOption) => {
                                    handleOptionChange(selectedOption);
                                  }}
                                  options={
                                    personalisedData &&
                                    Array.isArray(personalisedData)
                                      ? personalisedData.map((item) => ({
                                          value: item.tag,
                                          label: item.tag_name,
                                        }))
                                      : []
                                  }
                                  styles={customStyles}
                                />
                              </Col>
                              <Col md={1}>
                                <button
                                  type="submit"
                                  className="btn btn-light"
                                  style={{
                                    color: "white",
                                    marginLeft: "21px",
                                    backgroundColor: "#865ce2",
                                  }}
                                  onClick={handleUse}
                                >
                                  Use
                                </button>
                              </Col>
                            </div>
                          </Row>
                          <CKEditor
                            className="form-control is-invalid"
                            editor={ClassicEditor}
                            data={emailContent || ""}
                            config={{
                              placeholder:
                                "Craft a comprehensive job description that captures the essence of this role. Highlight key attributes you're seeking in a candidate, such as specific skills, personality traits, required qualifications, and location preferences. Please include any crucial details that will help candidates understand the role better. This description is not just a guide for prospective applicants but also aids our AI in accurately matching your vacancy with the most suitable candidates in our talent pool. The more detailed your description, the better the match will be!",
                            }}
                            onReady={handleEditorReady}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              console.log(data);
                              setEmailContent(data);
                            }}
                            //style={{zIndex: "1 !important"}}
                          />
                        </div>
                      )}
                      {type === "notificationSubject" && (
                        <div className="mb-3">
                          <Input
                            type="hidden"
                            value={id}
                            onChange={(e) => {
                              setId(e.target.value);
                            }}
                            id="firstnameInput"
                          />
                          <Row className="mb-3">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Col md={4}>
                                <Label
                                  for="firstnameInput"
                                  className="form-label"
                                >
                                  Notification Subject
                                </Label>
                              </Col>
                              <Col md={3}>
                                <Label
                                  for="firstnameInput"
                                  className="form-label"
                                  style={{ marginLeft: "20px" }}
                                >
                                  Personalisation Tags
                                </Label>
                              </Col>
                              <Col
                                md={4}
                                style={{ zIndex: 1, marginLeft: "-16px" }}
                              >
                                <Select
                                  value={notiSub}
                                  onChange={handleNotiSub}
                                  options={
                                    personalisedData &&
                                    Array.isArray(personalisedData)
                                      ? personalisedData.map((item) => ({
                                          value: item.tag,
                                          label: item.tag_name,
                                        }))
                                      : []
                                  }
                                  styles={customStyles}
                                />
                              </Col>
                              <Col md={1}>
                                <button
                                  type="button"
                                  className="btn btn-light"
                                  style={{
                                    color: "white",
                                    marginLeft: "21px",
                                    backgroundColor: "#865ce2",
                                  }}
                                  onClick={handleNotiSubUse}
                                >
                                  Use
                                </button>
                              </Col>
                            </div>
                          </Row>
                          <textarea
                            type="text"
                            value={notificationSubject}
                            onChange={(e) => {
                              setNotificationSubject(e.target.value);
                            }}
                            className="form-control"
                            id="firstnameInput"
                            placeholder="Enter Notification Subject"
                          />
                        </div>
                      )}
                      {type === "notificationContent" && (
                        <div className="mb-3">
                          <Input
                            type="hidden"
                            value={id}
                            onChange={(e) => {
                              setId(e.target.value);
                            }}
                            id="firstnameInput"
                          />
                          <Row className="mb-3">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Col md={4}>
                                <Label
                                  for="firstnameInput"
                                  className="form-label"
                                >
                                  Notification Content
                                </Label>
                              </Col>
                              <Col md={3}>
                                <Label
                                  for="firstnameInput"
                                  className="form-label"
                                  style={{ marginLeft: "20px" }}
                                >
                                  Personalisation Tags
                                </Label>
                              </Col>
                              <Col
                                md={4}
                                style={{ zIndex: 1, marginLeft: "-16px" }}
                              >
                                <Select
                                  value={notiCont}
                                  onChange={(selectedOption) => {
                                    handleNotiCont(selectedOption);
                                  }}
                                  options={
                                    personalisedData &&
                                    Array.isArray(personalisedData)
                                      ? personalisedData.map((item) => ({
                                          value: item.tag,
                                          label: item.tag_name,
                                        }))
                                      : []
                                  }
                                  styles={customStyles}
                                />
                              </Col>
                              <Col md={1}>
                                <button
                                  type="submit"
                                  className="btn btn-light"
                                  style={{
                                    color: "white",
                                    marginLeft: "21px",
                                    backgroundColor: "#865ce2",
                                  }}
                                  onClick={handleNotiContUse}
                                >
                                  Use
                                </button>
                              </Col>
                            </div>
                          </Row>
                          <CKEditor
                            className="form-control is-invalid"
                            editor={ClassicEditor}
                            data={notificationContent || ""}
                            config={{
                              placeholder:
                                "Craft a comprehensive job description that captures the essence of this role. Highlight key attributes you're seeking in a candidate, such as specific skills, personality traits, required qualifications, and location preferences. Please include any crucial details that will help candidates understand the role better. This description is not just a guide for prospective applicants but also aids our AI in accurately matching your vacancy with the most suitable candidates in our talent pool. The more detailed your description, the better the match will be!",
                            }}
                            onReady={handleEditorReadyNotiCont}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              console.log(data);
                              setNotificationContent(data);
                            }}
                          />
                        </div>
                      )}
                    </Col>
                    <Col lg={12}>
                      <Row>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                          <Button
                            className="w-lg cancelButton"
                            color="primary"
                            //data-bs-dismiss="modal"
                            onClick={toggleModalEmailSubjectEdit}
                          >
                            Close
                          </Button>
                          <Button
                            //type="submit"
                            className="w-lg confirmButton"
                            color="primary"
                            //style={{ color: "#ec1388" }}
                            onClick={handleSubmit}
                          >
                            Update
                          </Button>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </>
          )}
          {/*// email subject view */}
          {isLoading ? (
            <>
              <Modal
                className="zoomIn"
                id="addSeller"
                size="lg"
                isOpen={modalEmailSubjectView}
                toggle={toggleModalEmailSubjectView}
                centered
              >
                <ModalHeader toggle={toggleModalEmailSubjectView}>
                  View Email Subject
                </ModalHeader>
                <div className="modal-content border-0 mt-3"></div>
                <ModalBody>
                  <Row>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-4 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                    </h3>
                  </Row>
                </ModalBody>
              </Modal>
            </>
          ) : (
            <>
              <Modal
                className="zoomIn"
                id="addSeller"
                size="lg"
                isOpen={modalEmailSubjectView}
                toggle={toggleModalEmailSubjectView}
                centered
              >
                <ModalHeader toggle={toggleModalEmailSubjectView}>
                  View Details
                </ModalHeader>
                <div className="modal-content border-0 mt-3"></div>
                <ModalBody>
                  <Row>
                    <Col lg={12}>
                      {type === "emailSubject" && (
                        <div className="mb-3">
                          <Input
                            type="hidden"
                            value={id}
                            onChange={(e) => {
                              setId(e.target.value);
                            }}
                            id="firstnameInput"
                          />
                          <div className="mt-2">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: emailSubject,
                              }}
                            ></span>
                          </div>
                        </div>
                      )}
                      {type === "emailContent" && (
                        <div className="mb-3">
                          <Input
                            type="hidden"
                            value={id}
                            onChange={(e) => {
                              setId(e.target.value);
                            }}
                            id="firstnameInput"
                          />
                          <div className="mt-2">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: emailContent,
                              }}
                            ></span>
                          </div>
                        </div>
                      )}
                      {type === "notificationSubject" && (
                        <div className="mb-3">
                          <Input
                            type="hidden"
                            value={id}
                            onChange={(e) => {
                              setId(e.target.value);
                            }}
                            id="firstnameInput"
                          />
                          <div className="mt-2">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: notificationSubject,
                              }}
                            ></span>
                          </div>
                        </div>
                      )}
                      {type === "notificationContent" && (
                        <div className="mb-3">
                          <Input
                            type="hidden"
                            value={id}
                            onChange={(e) => {
                              setId(e.target.value);
                            }}
                            id="firstnameInput"
                          />
                          <div className="mt-2">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: notificationContent,
                              }}
                            ></span>
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col lg={12}>
                      <Row>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                          <Button
                            className="w-lg cancelButton"
                            color="primary"
                            //data-bs-dismiss="modal"
                            onClick={toggleModalEmailSubjectView}
                          >
                            Close
                          </Button>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </>
          )}
          <ToastContainer closeButton={false} limit={1} theme="light" />
        </CardBody>
      </Card>
    </div>
  );
};
