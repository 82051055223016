import { Card, CardBody, Col, NavLink, Row, } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button } from 'reactstrap';
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import {
  CardHeader,
  Label,
  Table,
} from "reactstrap";
import Select from "react-select";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Timesheets = () => {
  document.title="Timesheets | Get Rostered";
  // Vertical Nav Tabs
  const [isLoading, setisLoading] = useState(false);
  return (
    <div>
      <Card>
        <CardHeader>
          <h5 className="mb-0 text-primary">Timesheets</h5>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </div>
  )
}
