import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, Alert } from "reactstrap";
import warning from "../assets/images/warning.png";
import crown from "../assets/images/crown.png";
import checkicon from "../assets/images/checkicon.svg";

const Trialovermodal = ({ isOpen, toggle, hide }) => {
  const navigate = useNavigate();
  
  // const handleCloseBtn = () => {
  //   hide();
  //   navigate('/business_settings');
  // };

  // const handleClose = () => {
  //   hide();
  //   navigate('/billing');
  // };

  const handleUpdatePayment = () => {
    hide();
    navigate('/billing');
  };

  const getNextBillingDate = () => {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1; // JavaScript months are 0-based, so add 1

    if (month > 11) {
      month = 0; // Reset to January
      year++; // Move to the next year
    }

    return new Date(year, month, 2);
  };

  const billingDate = getNextBillingDate();
  const formattedBillingDate = billingDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <React.Fragment>
      <Modal
        backdrop={true}  // Prevent closing the modal by clicking outside it or pressing the escape key
        id="addSeller"
        toggle={toggle}
        size="md"
        isOpen={isOpen}
        centered
        style={{ borderRadius: 20 }}
        className="custom-modal"
      >
        <ModalBody className="px-4">
        <div className="row mt-2">
            <div className="col-md-10 d-flex align-items-center">
              <h6>
                <img src={crown} height={20} className="mb-1 me-1" alt="icon" /> 
                Upgrade to use this feature
              </h6>
            </div>
            <div className="col-md-2 mb-3 d-flex justify-content-end">
              <button 
                onClick={toggle} 
                style={{
                  border: 'none',
                  background: 'none',
                  fontSize: '23px',
                  cursor: 'pointer',
                  color: 'grey'
                }}
              >
                x
              </button>
            </div>
          </div>
          <div>
            <h3>Get Rostered Premium</h3>
          </div>

          <div className="d-flex col-md-12 justify-content-start mt-4 mb-1">
            <div className="col-md-7">
              <p style={{ color: "#865ce2", fontSize: "15px" }}>One simple plan</p>  
            </div>
            <div className="col-md-5 h-20">
              <i
                style={{
                  color: "#865ce2",
                  fontSize: "22px",
                  background: "#F5F5F5",
                  padding: "5px",
                  borderRadius: "30px"
                }}
                className="bx bx-medal"
              ></i>  
            </div>
          </div>

          <div className="d-flex justify-content-start mb-1">
            <i className="bx bx-dollar" style={{ color: "#865ce2", fontSize: "13px", fontWeight: "bold" }}></i>
            <p style={{ fontSize: "13px", margin: 0 }}>
              <span style={{ color: "#865ce2", fontSize: "13px", fontWeight: "bold" }}>10</span> 
              /Team Member /Month
            </p>   
          </div>
          <hr />

          {[
            'Search Workforce',
            'Unlimited Rosters',
            'Unlimited Sites',
            'Unlimited Job Postings',
            'Timesheets',
            '3rd party Integration',
            'Unlimited Talent Pool'
          ].map((feature, index) => (
            <div className="d-flex justify-content-start mt-2 mb-2 text-muted" key={index}>
              <img src={checkicon} height={15} className="mb-1 mt-1 me-1" alt="icon" />
              <p className="mb-1 ms-1 mt-1">{feature}</p>
              <i className="bx bx-question-mark" style={{ fontSize: "12px" }}></i>
            </div>
          ))}

          <Alert color="danger" style={{ borderRadius: "10px", width: "100%", fontSize: "12px" }}>
            <strong>Cancel anytime. </strong> 
            You will be billed only on <b>{formattedBillingDate}</b>
          </Alert>

          <div className="d-flex justify-content-center mt-2 mb-2">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={handleUpdatePayment}
              style={{ borderRadius: "10px", width: "100%", fontSize: "16px" }}
            >
              Subscribe
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Trialovermodal;
