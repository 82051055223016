import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { useModal } from "../ModalContext";
import expired from "../assets/images/Updatepayment.png";

const PaymentFailed = ({ isOpen, toggle }) => {
  
  const navigate = useNavigate();

  const handleUpdatePayment = () => {
    toggle();
    navigate('/billing');
  };

  // const handleCloseBtn = () => {
  //   hide();
  //   navigate('/business_settings');
  // };


  const signout = () => {
    toggle();
    navigate('/login');
  };
  
  return (
    <div className="page-content">
      <Modal
      backdrop={true}  // Prevent closing the modal by clicking outside it or pressing the escape key
      id="paymentFailedModal"
      toggle={toggle}
      size="md"
      isOpen={isOpen}
      centered
      style={{ borderRadius: 20 }}
      className="custom-modal"
      >
        <ModalBody>
          <div className="text-center p-4">
            <Row className="justify-content-center">
              <Col md={12}>
                <Link to="/dashboard">
                  <img
                    src={expired}
                    alt="Payment Failed"
                    className="img-fluid mx-auto d-block"
                    style={{ maxHeight: "220px" }}
                  />
                </Link>
              </Col>
            </Row>
            <Row className="justify-content-center mb-4">
              <Col md={12}>
                <h2>Payment Failed!</h2>
              </Col>
            </Row>
            <Row className="justify-content-center mb-3">
              <Col md={10} className="d-flex justify-content-around">
                <Button
                  color="primary"
                  className="mx-2"
                  style={{
                    borderRadius: 10,
                    padding: "10px 20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={handleUpdatePayment}
                >
                  Update Payment
                </Button>
                <Button
                  tag={Link}
                  to="/login"
                  color="light"
                  className="mx-2"
                  style={{
                    border: "none",
                    borderRadius: 10,
                    padding: "10px 20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={signout}
                >
                  Sign Out
                </Button>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PaymentFailed;
