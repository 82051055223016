import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  PopoverBody,
  UncontrolledPopover,
  Popover,
  Table,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { get_cookie } from "../../helpers/get_cookie";
import axios from "axios";
import React, { useState, useEffect } from "react";
import userDummy from "../../assets/images/users/user-dummy-img.jpg";
import clockImg from "../../assets/images/clock.png";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../Components/Common/Loader";
import Flatpickr from "react-flatpickr";
import Select, { StylesConfig } from "react-select";

const TeamAvailability = () => {
  document.title = "Team Availability | Get Rostered";
  const user_id = JSON.parse(get_cookie("authUser")).user_id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [siteId, setSiteId] = useState({ value: "all", label: "All sites" });
  const [siteIdError, setSiteIdError] = useState("");
  const [sites, setSites] = useState([]);
  const [availabilityData, setAvailabilityData] = useState([]);
  const [startWeek, setStartWeek] = useState(
    moment(currentDate).startOf("week").format("YYYY-MM-DD")
  );
  const [endWeek, setEndWeek] = useState(
    moment(currentDate).endOf("week").format("YYYY-MM-DD")
  );
  useEffect(() => {
    getTeamAvailabilityData(startWeek, endWeek);
  }, [siteId]);

  // Add a useEffect hook to handle side effects after state updates
  useEffect(() => {
    const weekStartDate = moment(currentDate)
      .startOf("week")
      .format("YYYY-MM-DD");
    const weekEndDate = moment(currentDate).endOf("week").format("YYYY-MM-DD");
    setStartWeek(weekStartDate);
    setEndWeek(weekEndDate);
  }, [currentDate]);

  useEffect(() => {
    getTeamAvailabilityData(startWeek, endWeek);
  }, [startWeek, endWeek]);

  const getTeamAvailabilityData = (startWeek, endWeek) => {
    console.log("startWeek", startWeek?._d, "endWeek", endWeek?._d);
    setIsLoading(true);
    const formData = {
      user_id: user_id,
      business_id: business_id,
      startWeek: startWeek,
      endWeek: endWeek,
      site_id: siteId.value,
    };
    axios
      .post("/team-availability-details", formData)
      .then((res) => {
        console.log(res);
        setSites(res.Sites);
        setAvailabilityData(res.Team_Member);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const AvailableShiftsData = () => {
    setIsLoading(true);

    const payloadData = {
      user_id: user_id,
      currentDate: moment().format("YYYY-MM-DD"),
    };

    axios
      .post("/get-available-shifts-data", payloadData)
      .then((res) => {
        //setavailableshiftdetails(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  const getWeekDays = (date) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(date.setDate(date.getDate() - date.getDay() + i));
      days.push(day);
    }
    return days;
  };

  const handlePreviousWeek = () => {
    const newDate = new Date(currentDate);
    console.log(newDate);
    newDate.setDate(currentDate.getDate() - 7);
    setCurrentDate(newDate);
  };

  const handleNextWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + 7);
    setCurrentDate(newDate);
  };
  const weekStartDate = moment(currentDate).startOf("week").format("MMM DD");
  const weekEndDate = moment(currentDate).endOf("week").format("MMM DD");

  const days = getWeekDays(currentDate);
  const handleDateChange = (selectedDate) => {
    setCurrentDate(new Date(selectedDate));
    const dateOnlySelectedDate = moment(selectedDate).format("YYYY-MM-DD");

    setSelectedDate(dateOnlySelectedDate);
  };
  function handleSite(selectedOption) {
    setSiteId(selectedOption);
    getTeamAvailabilityData();
  }
  const renderDayCard = (day) => {
    const isCurrentDay = moment(day).isSame(moment(), "day");
    return (
      <div className="w-100">
        <div className="table-responsive table-wrapper">
          <Table className="table-custom  table-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th scope="col" style={{ fontWeight: "bold" }}>
                  Team Member
                </th>
                {days.map((day, index) => (
                  <th
                    scope="col"
                    key={index}
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    {moment(day).format("ddd DD MMM")}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {availabilityData?.map((data, dataIndex) => (
                <tr key={dataIndex} style={{ fontWeight: "bold" }}>
                  <td>{data?.full_name}</td>
                  {days.map((day, dayIndex) => {
                    const availability = data?.shift_dates.find((item) =>
                      moment(item?.shift_date).isSame(moment(day), "day")
                    );
                    const backgroundColor =
                      availability?.remaining_availability_hours > 0
                        ? "#fff"
                        : "#002e4610";
                    console.log(availability);
                    return (
                      <td
                        key={dayIndex}
                        style={{ backgroundColor, textAlign: "center" }}
                      >
                        {availability?.remaining_availability_hours
                          ? availability.remaining_availability_hours
                          : "-"}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: siteIdError ? "red" : provided.borderColor,
      "&:hover": {
        borderColor: siteIdError ? "red" : provided.borderColor,
      },
      minHeight: "50px",
      border: "none",
      borderRadius: "40px",
      boxShadow: state.isFocused
        ? siteIdError
          ? "0 0 0 1px red"
          : "0 0 0 1px #7F4BDA"
        : provided.boxShadow,
      padding: "10px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#7F4BDA" : provided.backgroundColor,
      color: state.isSelected ? "white" : provided.color,
      "&:hover": {
        backgroundColor: "#e8e6f7",
        color: "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingRight: "10px",
      padding: 0,
      color: "#7F4BDA", // Set your desired color here
      "&:hover": {
        color: "#7527CE", // Optional: change color on hover
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="p-4 pt-3 ">
          <BreadCrumb title="Team Availability" />
          <Row className="mt-3">
            <Col lg={12}>
              <Row>
                <Col md="12">
                  <div className="ps-0 p-3 align-items-start pb-0">
                    <div className="week-view-calendar w-100">
                      <div
                        className="navigation mb-4 d-flex"
                        style={{ justifyContent: "left" }}
                      >
                        <Col md="5" lg={4} style={{ marginRight: "20px" }}>
                          <div className="mb-3 ">
                            <Select
                              value={siteId}
                              className={`${siteIdError ? "is-invalid" : ""}`}
                              onChange={(selectedOption) => {
                                handleSite(selectedOption);
                                setSiteIdError("");
                              }}
                              options={[
                                { value: "all", label: "All sites" }, // Add the "All sites" option
                                ...(sites && Array.isArray(sites)
                                  ? sites.map((item) => ({
                                      value: item.id,
                                      label: item.site_name,
                                    }))
                                  : []),
                              ]}
                              styles={customStyles}
                            />
                            
                          </div>
                        </Col>
                        <Col md="5" lg={4}>
                          <div className="date-container p-2 d-flex justify-content-between align-items-center">
                            <button
                              className="btn btn-soft-primary soft-purple-rounded justify-content-center align-items-center"
                              onClick={handlePreviousWeek}
                            >
                              <i className="ri-arrow-left-s-line align-bottom fw-bold"></i>
                            </button>
                            <span className="btn ms-2" id="overviewCalendar">
                              {weekStartDate} - {weekEndDate}
                              <Popover
                                placement="bottom"
                                //isOpen={popoverOpen}
                                target="overviewCalendar"
                                //toggle={togglePopover}
                                trigger="legacy"
                              ></Popover>
                              <UncontrolledPopover
                                placement="bottom"
                                target="overviewCalendar"
                                trigger="legacy"
                              >
                                {/* <PopoverBody>
                                  <Flatpickr
                                    name="unavailabilityDates"
                                    className="form-control"
                                    id="datepicker-overview-input"
                                    placeholder="Select date(s)"
                                    options={{
                                      mode: "single",
                                      enableTime: false,
                                      dateFormat:
                                        "D M d Y H:i:s \\G\\M\\TO (T)",
                                      // dateFormat: "d/m/Y", // Set the date format to dd/mm/yyyy
                                      // defaultDate: "2024-02-21",
                                      inline: true, // Use inline mode
                                      // onChange: handleDateChange,
                                    }}
                                    onChange={handleDateChange}
                                    value={selectedDate || ""}
                                  />
                                </PopoverBody> */}
                              </UncontrolledPopover>
                            </span>
                            <button
                              className="btn btn-soft-primary soft-purple-rounded justify-content-center align-item-center"
                              onClick={handleNextWeek}
                            >
                              <i className="ri-arrow-right-s-line align-bottom fw-bold"></i>
                            </button>
                          </div>
                        </Col>
                        <div>
                          {/*<button
                                className="btn btn-soft-primary p-2 ms-3"
                                onClick={() =>
                                  getTeamAvailabilityData(startWeek, endWeek)
                                }
                              >
                                <i className="bx bx-refresh align-inherit fs-16"></i>
                              </button>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Card
                    className="table-availability"
                    style={{ boxShadow: "none" }}
                  >
                    <CardBody className="p-0">{renderDayCard(days)}</CardBody>
                  </Card>
                </Col>
              </Row>
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}
            </Col>
          </Row>
        </Container>
        <ToastContainer closeButton={false} limit={1} />
      </div>
    </React.Fragment>
  );
};

export default TeamAvailability;
