import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  Container,
  Input,
  Button,
} from "reactstrap";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  RedditShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  RedditIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookMessengerIcon,
} from "react-share";

const ShareModal = ({ shareableLink, isOpen, toggle }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(shareableLink).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset the button after 2 seconds
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="md" scrollable={true}>
      <ModalBody>
        <h4 className="text-primary">Social Share</h4>

        <h6 className="mt-3">Share this link via</h6>
        <div className="d-flex mt-3 justify-content-around">
          <FacebookShareButton url={shareableLink}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <TwitterShareButton url={shareableLink}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <LinkedinShareButton url={shareableLink}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <RedditShareButton url={shareableLink}>
            <RedditIcon size={32} round />
          </RedditShareButton>

          <WhatsappShareButton url={shareableLink}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <FacebookMessengerShareButton url={shareableLink}>
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>

          <EmailShareButton url={shareableLink}>
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
        {/* <h6 className="mt-3">Copy link</h6> */}
        <div className="mt-3 ">
          {/* <Input className=""  readOnly value={shareableLink}></Input> */}
          <Button
            color={copied ? "success" : "primary"}
            onClick={handleCopy}
            className="ml-2 mt-2 w-100"
          >
            {copied ? "Link Copied!" : "Copy Link"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ShareModal;
