import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  UncontrolledAlert,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Button } from "reactstrap";
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import { CardHeader, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
//dateformat
import moment from "moment";
import { get_cookie } from "../../../helpers/get_cookie";
//reloading the page
import { jsx } from "@emotion/react";
import { ClipLoader } from "react-spinners";
//toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";
import Dropzone from "react-dropzone";
import uploadImg from "../../../assets/images/freepik/uploading_img.png";
import img13 from "../../../assets/images/small/img-12.jpg";
import { api } from "../../../config";
import GoogleAutocomplete from "react-google-autocomplete";
import { useParams } from "react-router-dom";
//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export const General = () => {
  document.title = "General | Roster";
  const params = useParams();
  const site_id = params.id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [isLoading, setisLoading] = useState(true);
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [site_data, setSiteData] = useState();
  //site
  const [site_name, setSiteName] = useState("");
  const [address, setAddress] = useState("");
  const [week_starts, setWeekStarts] = useState("");
  /*const [time_zone, setTimeZone] = useState('');
  const [time_option, setTimeOption] = useState('');*/
  const [time_zone, setTimeZone] = useState("");
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [talent_pool, setTalentPool] = useState("");
  const [addressError, setAddressError] = useState("");
  const [siteNameError, setSiteNameError] = useState("");
  // operation hours
  const [mondayFromTime, setMondayFromTime] = useState("09:00");
  const [mondayToTime, setMondayToTime] = useState("17:00");
  const [tuesdayFromTime, setTuesdayFromTime] = useState("09:00");
  const [tuesdayToTime, setTuesdayToTime] = useState("17:00");
  const [wednesdayFromTime, setWednesdayFromTime] = useState("09:00");
  const [wednesdayToTime, setWednesdayToTime] = useState("17:00");
  const [thursdayFromTime, setThursdayFromTime] = useState("09:00");
  const [thursdayToTime, setThursdayToTime] = useState("17:00");
  const [fridayFromTime, setFridayFromTime] = useState("09:00");
  const [fridayToTime, setFridayToTime] = useState("17:00");
  const [saturdayFromTime, setSaturdayFromTime] = useState("09:00");
  const [saturdayToTime, setSaturdayToTime] = useState("17:00");
  const [sundayFromTime, setSundayFromTime] = useState("09:00");
  const [sundayToTime, setSundayToTime] = useState("17:00");
  const [id, setId] = useState("");
  const [state, setState] = useState("");
  const [notes, setNotes] = useState("");
  const toggledeletemodal = () => {
    setDeleteModal(!deletemodal);
  };
  const [selectedCity, setSelectedcity] = useState();
  const [selectedSuburb, setselectedSuburb] = useState();
  const [selectedPostcode, setselectedPostcode] = useState();
  const [country, setCountry] = useState([]);
  const [authUserId, setAuthUserId] = useState();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res);
        setAuthUserId(res.id);
        loadgeneral(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const residentialplace = (place) => {
    const addressComponents = place?.address_components;
    const { lat, lng } = place.geometry.location;
    const streetNumberComponent = addressComponents?.find((component) =>
      component.types.includes("street_number")
    );
    const streetRouteComponent = addressComponents?.find((component) =>
      component.types.includes("route")
    );
    const unitComponent = place?.addressComponents?.find((component) =>
      component.types.includes("subpremise")
    );
    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );
    const countryComponent = addressComponents?.find((component) =>
      component.types.includes("country")
    );

    let selectedStreetNumber = "";
    if (streetNumberComponent) {
      selectedStreetNumber = streetNumberComponent.long_name;
    }
    let selectedUnitNumber = "";
    if (unitComponent) {
      selectedUnitNumber = unitComponent.long_name;
    }
    let selectedStreetRoute = "";
    if (streetRouteComponent) {
      selectedStreetRoute = streetRouteComponent.long_name;
    }
    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
    }
    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name;
    }
    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }
    let selectedcountry = "";
    if (countryComponent) {
      selectedcountry = countryComponent.long_name;
    }

    setSelectedcity(
      `${selectedUnitNumber} ${selectedStreetNumber} ${selectedStreetRoute} `
    );
    setselectedSuburb(selectedSuburb + ", " + selectedState);
    setselectedPostcode(selectedPostcode);
    setCountry(selectedcountry);
    setAddress(place?.formatted_address);
    setLatitude(lat());
    setLongitude(lng());
  };
  const handleSelectTimeZone = (selectedOption) => {
    if (selectedOption) {
      setTimeZone(selectedOption.value);
    } else {
      setTimeZone(""); // Assuming time_zone is initially set to an empty string
    }
  };
  const loadgeneral = (userId) => {
    setisLoading(true);
    const formData = {
      id: site_id,
      user_id: userId,
      business_id: business_id,
    };
    axios
      .post("/get-site-data", formData)
      .then((response) => {
        if (response.error === 1) {
          setState(false);
          setTalentPool(response.dropdownOptions);
          if (Array.isArray(response.timezones)) {
            setTimeZone(response.timezones);
            console.log(response.timezones);
          }
          setSiteData(response.site_data);
          setAddress(response.full_address);
          setSiteName(response.site_data.site_name);
          setId(response.site_data.id);
          setWeekStarts(response.week_starts);
          if (Array.isArray(response.timezones)) {
            setTimeZoneOptions(
              response.timezones.map((timeZone) => ({
                value: timeZone,
                label: timeZone,
              }))
            );
          }
          // Set the selected time zone
          setTimeZone(response.site_data.timezone);
          setSelectedcity(response.site_data.address);
          setselectedSuburb(response.site_data.suburb);
          setselectedPostcode(response.site_data.postcode);
          setCountry(response.country_name);
          setNotes(response.site_data.notes);
          response?.site_opening_hours?.forEach((item) => {
            if (item.day_of_week === "Monday") {
              if (item.open_24_hours === 1) {
                setMondayFromTime("open_24_hours");
                setMondayToTime(item.closing_time);
                setIsMondayOpen(true);
              } else if (item.is_closed === 1) {
                setMondayFromTime("closed");
                setMondayToTime(item.closing_time);
                setIsMondayOpen(false);
              } else {
                setMondayFromTime(item.opening_time);
                setMondayToTime(item.closing_time);
                setIsMondayOpen(true);
              }
            } else if (item.day_of_week === "Tuesday") {
              if (item.open_24_hours === 1) {
                setTuesdayFromTime("open_24_hours");
                setTuesdayToTime(item.closing_time);
                setIsTueOpen(true);
              } else if (item.is_closed === 1) {
                setTuesdayFromTime("closed");
                setTuesdayToTime(item.closing_time);
                setIsTueOpen(false);
              } else {
                setTuesdayFromTime(item.opening_time);
                setTuesdayToTime(item.closing_time);
                setIsTueOpen(true);
              }
            } else if (item.day_of_week === "Wednesday") {
              if (item.open_24_hours === 1) {
                setWednesdayFromTime("open_24_hours");
                setWednesdayToTime(item.closing_time);
                setIsWedOpen(true);
              } else if (item.is_closed === 1) {
                setWednesdayFromTime("closed");
                setWednesdayToTime(item.closing_time);
                setIsWedOpen(false);
              } else {
                setWednesdayFromTime(item.opening_time);
                setWednesdayToTime(item.closing_time);
                setIsWedOpen(true);
              }
            } else if (item.day_of_week === "Thursday") {
              if (item.open_24_hours === 1) {
                setThursdayFromTime("open_24_hours");
                setThursdayToTime(item.closing_time);
                setIsThuOpen(true);
              } else if (item.is_closed === 1) {
                setThursdayFromTime("closed");
                setThursdayToTime(item.closing_time);
                setIsThuOpen(false);
              } else {
                setThursdayFromTime(item.opening_time);
                setThursdayToTime(item.closing_time);
                setIsThuOpen(true);
              }
            } else if (item.day_of_week === "Friday") {
              if (item.open_24_hours === 1) {
                setFridayFromTime("open_24_hours");
                setFridayToTime(item.closing_time);
                setIsFriOpen(true);
              } else if (item.is_closed === 1) {
                setFridayFromTime("closed");
                setFridayToTime(item.closing_time);
                setIsFriOpen(false);
              } else {
                setFridayFromTime(item.opening_time);
                setFridayToTime(item.closing_time);
                setIsFriOpen(true);
              }
            } else if (item.day_of_week === "Saturday") {
              if (item.open_24_hours === 1) {
                setSaturdayFromTime("open_24_hours");
                setSaturdayToTime(item.closing_time);
                setIsSatOpen(true);
              } else if (item.is_closed === 1) {
                setSaturdayFromTime("closed");
                setSaturdayToTime(item.closing_time);
                setIsSatOpen(false);
              } else {
                setSaturdayFromTime(item.opening_time);
                setSaturdayToTime(item.closing_time);
                setIsSatOpen(true);
              }
            } else if (item.day_of_week === "Sunday") {
              if (item.open_24_hours === 1) {
                setSundayFromTime("open_24_hours");
                setSundayToTime(item.closing_time);
                setIsSunOpen(true);
              } else if (item.is_closed === 1) {
                setSundayFromTime("closed");
                setSundayToTime(item.closing_time);
                setIsSunOpen(false);
              } else {
                setSundayFromTime(item.opening_time);
                setSundayToTime(item.closing_time);
                setIsSunOpen(true);
              }
            }
          });
        } else {
          setState(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  //*backend response*

  const togglemodal = () => {
    setModal(!modal);
  };

  function handleSelectDay(selectedDay) {
    setWeekStarts(selectedDay);
  }
  const productCountry = [
    {
      options: [
        { label: "Select Day...", value: "Select Day" },
        { label: "Monday", value: "Mon" },
        { label: "Tuesday", value: "Tue" },
        { label: "Wednesday", value: "Wed" },
        { label: "Thursday", value: "Thu" },
        { label: "Friday", value: "Fri" },
        { label: "Saturday", value: "Sat" },
        { label: "Sunday", value: "Sun" },
      ],
    },
  ];

  const generateTimeOptions = () => {
    const options = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        const hour = h < 10 ? `0${h}` : `${h}`;
        const minute = m === 0 ? "00" : `${m}`;
        const time = `${hour}:${minute}`;
        options.push({ label: formatTime(time), value: time });
      }
    }
    options.push({ label: "Open 24 hours", value: "open_24_hours" });
    options.push({ label: "Closed", value: "closed" });
    return options;
  };
  // Function to format time as AM/PM
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const formattedHour = parseInt(hour, 10) % 12 || 12;
    const period = parseInt(hour, 10) < 12 ? "AM" : "PM";
    return `${formattedHour}:${minute} ${period}`;
  };
  const timeOptions = generateTimeOptions();
  // edit site
  const handleSubmit = (event) => {
    event.preventDefault();
    /* validation */
    if (site_name === "") {
      setSiteNameError("Please enter site name.");
    } else {
      setSiteNameError(""); // Clear the error message if the input is not empty.
    }

    if (address === "") {
      setAddressError("Please enter your address.");
    } else {
      setAddressError(""); // Clear the error message if the input is not empty.
    }
    if (site_name === "" || address === "") {
      // If any of the required fields are empty, do not proceed with form submission.
      return;
    }
    var formData = {
      id: id,
      user_id: authUserId,
      business_id: business_id,
      site_name: site_name,
      site_address: selectedCity,
      suburb: selectedSuburb,
      post_code: selectedPostcode,
      country_name: country,
      latitude: latitude,
      longitude: longitude,
      week_starts: week_starts,
      time_zone: time_zone,
      notes: notes,
      working_hours: [
        {
          day_of_week: "Monday",
          is_closed: isMondayOpen,
          fromTime: mondayFromTime,
          toTime: mondayToTime,
        },
        {
          day_of_week: "Tuesday",
          is_closed: isTueOpen,
          fromTime: tuesdayFromTime,
          toTime: tuesdayToTime,
        },
        {
          day_of_week: "Wednesday",
          is_closed: isWedOpen,
          fromTime: wednesdayFromTime,
          toTime: wednesdayToTime,
        },
        {
          day_of_week: "Thursday",
          is_closed: isThuOpen,
          fromTime: thursdayFromTime,
          toTime: thursdayToTime,
        },
        {
          day_of_week: "Friday",
          is_closed: isFriOpen,
          fromTime: fridayFromTime,
          toTime: fridayToTime,
        },
        {
          day_of_week: "Saturday",
          is_closed: isSatOpen,
          fromTime: saturdayFromTime,
          toTime: saturdayToTime,
        },
        {
          day_of_week: "Sunday",
          is_closed: isSunOpen,
          fromTime: sundayFromTime,
          toTime: sundayToTime,
        },
      ],
    };
    const validateWorkingHours = () => {
      const daysOfWeek = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];

      for (const day of daysOfWeek) {
        const dayData = formData.working_hours.find(
          (entry) => entry.day_of_week === day
        );

        if (dayData.is_closed === true) {
          // If the day is closed, ensure fromTime is 'closed'
          if (dayData.fromTime === "closed") {
            toast.error(`Please enter valid working hours for ${day}.`);
            return false;
          } else if (
            dayData.fromTime !== "open_24_hours" &&
            dayData.fromTime &&
            !dayData.toTime
          ) {
            toast.error(`Please enter valid working hours for ${day}.`);
            return false;
          }
        }
      }

      return true;
    };

    // Run working hours validation
    if (!validateWorkingHours()) {
      return;
    }
    setisLoading(true);
    axios
      .post("/update-site-data", formData)
      .then((response) => {
        toast.success(response.success, { theme: "light" });
        loadgeneral(authUserId);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  };
  const [isMondayOpen, setIsMondayOpen] = useState(true);
  const [isTueOpen, setIsTueOpen] = useState(true);
  const [isWedOpen, setIsWedOpen] = useState(true);
  const [isThuOpen, setIsThuOpen] = useState(true);
  const [isFriOpen, setIsFriOpen] = useState(true);
  const [isSatOpen, setIsSatOpen] = useState(true);
  const [isSunOpen, setIsSunOpen] = useState(true);
  const handleCheckboxChange = (e, day) => {
    const isOpen = e.target.checked;

    // Update the state based on the selected day
    switch (day) {
      case "Monday":
        setIsMondayOpen(isOpen);
        break;
      case "Tuesday":
        setIsTueOpen(isOpen);
        break;
      case "Wednesday":
        setIsWedOpen(isOpen);
        break;
      case "Thursday":
        setIsThuOpen(isOpen);
        break;
      case "Friday":
        setIsFriOpen(isOpen);
        break;
      case "Saturday":
        setIsSatOpen(isOpen);
        break;
      case "Sunday":
        setIsSunOpen(isOpen);
        break;
      // Add more cases for other days if needed

      default:
        break;
    }
  };

  // useEffect to update dropdown values after state has been updated
  useEffect(() => {
    // If the checkbox is unchecked, set the dropdown values to 'closed'
    if (!isMondayOpen) {
      setMondayFromTime("closed");
    }
  }, [isMondayOpen]);

  useEffect(() => {
    // If the checkbox is unchecked, set the dropdown values to 'closed'
    if (!isTueOpen) {
      setTuesdayFromTime("closed");
    }
  }, [isTueOpen]);
  // Add more useEffect blocks for other days if needed

  useEffect(() => {
    // If the checkbox is unchecked, set the dropdown values to 'closed'
    if (!isWedOpen) {
      setWednesdayFromTime("closed");
    }
  }, [isWedOpen]);
  useEffect(() => {
    // If the checkbox is unchecked, set the dropdown values to 'closed'
    if (!isThuOpen) {
      setThursdayFromTime("closed");
    }
  }, [isThuOpen]);
  useEffect(() => {
    // If the checkbox is unchecked, set the dropdown values to 'closed'
    if (!isFriOpen) {
      setFridayFromTime("closed");
    }
  }, [isFriOpen]);
  useEffect(() => {
    // If the checkbox is unchecked, set the dropdown values to 'closed'
    if (!isSatOpen) {
      setSaturdayFromTime("closed");
    }
  }, [isSatOpen]);
  useEffect(() => {
    // If the checkbox is unchecked, set the dropdown values to 'closed'
    if (!isSunOpen) {
      setSundayFromTime("closed");
    }
  }, [isSunOpen]);
  return (
    <div>
      {isLoading ? (
        <>
          <Card id="contactList">
            <CardHeader>
              <h5 className="mb-0 text-primary">General</h5>
            </CardHeader>
            <CardBody>
              <Col xxl={12}>
                <div className="live-preview">
                  <Row className="gy-4">
                    <div className="me-2">
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder  col-2 bg-dark"></span>
                      </h3>
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder  col-4 bg-dark"></span>
                      </h3>
                    </div>
                    <div className="me-2">
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder  col-2 bg-dark"></span>
                      </h3>
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder  col-6 bg-dark"></span>
                      </h3>
                    </div>
                    <div className="me-2">
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder  col-2 bg-dark"></span>
                      </h3>
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder  col-10 bg-dark"></span>
                      </h3>
                    </div>
                    <div className="me-2">
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder  col-2 bg-dark"></span>
                      </h3>
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder  col-5 bg-dark"></span>
                      </h3>
                    </div>
                    <div className="me-2">
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder  col-2 bg-dark"></span>
                      </h3>
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder  col-6 bg-dark"></span>
                      </h3>
                    </div>
                    <div className="me-2">
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder  col-2 bg-dark"></span>
                      </h3>
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder  col-4 bg-dark"></span>
                      </h3>
                    </div>
                    <div className="me-2">
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                      <h4 className=" placeholder-glow">
                        <span className="placeholder col-1 me-3 mb-3"></span>{" "}
                        <span className="placeholder col-2 mb-3"></span>
                      </h4>
                    </div>
                    <div className="me-2 mt-12  ">
                      <h3 className="card-title placeholder-glow hstack gap-2">
                        <span className="placeholder placeholder-sm  col-1 bg-dark"></span>
                        <span className="placeholder placeholder-sm  col-1 bg-dark"></span>
                      </h3>
                    </div>
                  </Row>
                </div>
              </Col>
            </CardBody>
          </Card>
        </>
      ) : (
        <>
          {state ? (
            <Card id="hidedetails">
              <CardHeader>
                <h5 className="mb-0 text-primary">General</h5>
              </CardHeader>
              <CardBody className="hidedata">
                <Col xxl={12}>
                  <div>No Record Found for this Id</div>
                </Col>
              </CardBody>
            </Card>
          ) : (
            <>
              <Card id="contactList">
                <CardHeader className="pt-2 pb-2">
                  <div className="d-flex justify-content-between align-items-center mb-0">
                    <h5 className="mb-0 text-primary">General</h5>
                    <div className="mb-0">
                      <Button
                        className="btn btn-success"
                        onClick={handleSubmit}
                        disabled={isUpdating}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Col xxl={12}>
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={6} md={6}>
                          <div className="mb-0">
                            <Label
                              htmlFor="billinginfo-firstName"
                              className="form-label"
                            >
                              Site Name<span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              value={site_name}
                              onChange={(e) => {
                                setSiteName(e.target.value);
                                setSiteNameError("");
                              }}
                              className={`form-control ${
                                siteNameError ? "is-invalid" : ""
                              }`}
                              id="billinginfo-firstName"
                              placeholder="Enter site name"
                            />
                            {siteNameError && (
                              <div className="invalid-feedback">
                                {siteNameError}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xxl={6} md={6}>
                          <div className="mb-0">
                            <Label
                              htmlFor="billinginfo-lastName"
                              className="form-label"
                            >
                              Site Address<span className="text-danger">*</span>
                            </Label>
                            <GoogleAutocomplete
                              className={`${addressError ? "is-invalid" : ""}`}
                              apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                              value={address}
                              onChange={(e) => {
                                setAddress(e.target.value);
                                setAddressError("");
                              }}
                              onPlaceSelected={(place) =>
                                residentialplace(place)
                              }
                              options={{
                                types: ["geocode"],
                                componentRestrictions: {
                                  country: "au",
                                },
                              }}
                              style={{
                                width: "100%",
                                border: "1px solid #ccc",
                                padding: "10px",
                                borderRadius: "5px",
                                position: "relative",
                              }}
                            />
                            {addressError && (
                              <div className="invalid-feedback">
                                {addressError}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xxl={6} md={6} className="mt-3">
                          <div className="mb-0">
                            <Label htmlFor="day" className="form-label">
                              At this site, the week starts on
                            </Label>
                            <Select
                              value={productCountry[0].options.find(
                                (option) => option.value === week_starts
                              )}
                              onChange={(selectedOption) => {
                                handleSelectDay(selectedOption.value); // Pass the selected value to the function
                              }}
                              options={productCountry[0].options} // Extract the options array from the nested structure
                              id="day"
                            ></Select>
                          </div>
                        </Col>
                        <Col xxl={6} md={6} className="mt-3">
                          <div className="mb-0">
                            <Label htmlFor="state" className="form-label">
                              TimeZone
                            </Label>
                            <Select
                              id="state"
                              value={timeZoneOptions.find(
                                (option) => option.value === time_zone
                              )}
                              onChange={(selectedOption) =>
                                handleSelectTimeZone(selectedOption)
                              }
                              options={timeZoneOptions}
                            ></Select>
                          </div>
                        </Col>

                        <Col xxl={12} md={12} className="">
                          <div className="mb-0">
                            <Label htmlFor="state" className="form-label">
                              Opening Hours
                            </Label>
                            <Row>
                              <Col md={2}>
                                <div className="form-check mb-3 m-2">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    defaultChecked
                                    checked={isMondayOpen}
                                    onChange={() =>
                                      setIsMondayOpen(!isMondayOpen)
                                    }
                                  />
                                  <Label
                                    className="form-check-label"
                                    for="formCheck1"
                                  >
                                    Monday
                                  </Label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="mb-0">
                                  <select
                                    className="form-select"
                                    value={mondayFromTime || "default"}
                                    onChange={(e) => {
                                      setMondayFromTime(e.target.value);
                                      if (e.target.value === "closed") {
                                        setIsMondayOpen(false);
                                      }
                                    }}
                                    disabled={!isMondayOpen}
                                  >
                                    <option value="default" disabled>
                                      Select a time
                                    </option>
                                    {timeOptions.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              {isMondayOpen &&
                                mondayFromTime !== "closed" &&
                                mondayFromTime !== "open_24_hours" && (
                                  <Col md={3}>
                                    <div className="mb-0">
                                      <select
                                        className="form-select"
                                        value={mondayToTime || "default"}
                                        onChange={(e) => {
                                          setMondayToTime(e.target.value);
                                          if (e.target.value === "closed") {
                                            setIsMondayOpen(false);
                                          }
                                        }}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                )}
                            </Row>
                            <Row>
                              <Col md={2}>
                                <div className="form-check mb-3 m-2">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    defaultChecked
                                    checked={isTueOpen}
                                    onChange={() => setIsTueOpen(!isTueOpen)}
                                  />
                                  <Label
                                    className="form-check-label"
                                    for="formCheck1"
                                  >
                                    Tuesday
                                  </Label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="mb-0">
                                  <select
                                    className="form-select"
                                    value={tuesdayFromTime || "default"}
                                    onChange={(e) => {
                                      setTuesdayFromTime(e.target.value);
                                      if (e.target.value === "closed") {
                                        setIsTueOpen(false);
                                      }
                                    }}
                                    disabled={!isTueOpen}
                                  >
                                    <option value="default" disabled>
                                      Select a time
                                    </option>
                                    {timeOptions.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              {isTueOpen &&
                                tuesdayFromTime !== "closed" &&
                                tuesdayFromTime !== "open_24_hours" && (
                                  <Col md={3}>
                                    <div className="mb-0">
                                      <select
                                        className="form-select"
                                        value={tuesdayToTime || "default"}
                                        onChange={(e) => {
                                          setTuesdayToTime(e.target.value);
                                          if (e.target.value === "closed") {
                                            setIsTueOpen(false);
                                          }
                                        }}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                )}
                            </Row>
                            <Row>
                              <Col md={2}>
                                <div className="form-check mb-3 m-2">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    defaultChecked
                                    checked={isWedOpen}
                                    onChange={() => setIsWedOpen(!isWedOpen)}
                                  />
                                  <Label
                                    className="form-check-label"
                                    for="formCheck1"
                                  >
                                    Wednesday
                                  </Label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="mb-0">
                                  <select
                                    className="form-select"
                                    value={wednesdayFromTime || "default"}
                                    onChange={(e) => {
                                      setWednesdayFromTime(e.target.value);
                                      if (e.target.value === "closed") {
                                        setIsWedOpen(false);
                                      }
                                    }}
                                    disabled={!isWedOpen}
                                  >
                                    <option value="default" disabled>
                                      Select a time
                                    </option>
                                    {timeOptions.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              {isWedOpen &&
                                wednesdayFromTime !== "closed" &&
                                wednesdayFromTime !== "open_24_hours" && (
                                  <Col md={3}>
                                    <div className="mb-0">
                                      <select
                                        className="form-select"
                                        value={wednesdayToTime || "default"}
                                        onChange={(e) => {
                                          setWednesdayToTime(e.target.value);
                                          if (e.target.value === "closed") {
                                            setIsWedOpen(false);
                                          }
                                        }}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                )}
                            </Row>
                            <Row>
                              <Col md={2}>
                                <div className="form-check mb-3 m-2">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    defaultChecked
                                    checked={isThuOpen}
                                    onChange={() => setIsThuOpen(!isThuOpen)}
                                  />
                                  <Label
                                    className="form-check-label"
                                    for="formCheck1"
                                  >
                                    Thursday
                                  </Label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="mb-0">
                                  <select
                                    className="form-select"
                                    value={thursdayFromTime || "default"}
                                    onChange={(e) => {
                                      setThursdayFromTime(e.target.value);
                                      if (e.target.value === "closed") {
                                        setIsThuOpen(false);
                                      }
                                    }}
                                    disabled={!isThuOpen}
                                  >
                                    <option value="default" disabled>
                                      Select a time
                                    </option>
                                    {timeOptions.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              {isThuOpen &&
                                thursdayFromTime !== "closed" &&
                                thursdayFromTime !== "open_24_hours" && (
                                  <Col md={3}>
                                    <div className="mb-0">
                                      <select
                                        className="form-select"
                                        value={thursdayToTime || "default"}
                                        onChange={(e) => {
                                          setThursdayToTime(e.target.value);
                                          if (e.target.value === "closed") {
                                            setIsThuOpen(false);
                                          }
                                        }}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                )}
                            </Row>
                            <Row>
                              <Col md={2}>
                                <div className="form-check mb-3 m-2">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    defaultChecked
                                    checked={isFriOpen}
                                    onChange={() => setIsFriOpen(!isFriOpen)}
                                  />
                                  <Label
                                    className="form-check-label"
                                    for="formCheck1"
                                  >
                                    Friday
                                  </Label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="mb-0">
                                  <select
                                    className="form-select"
                                    value={fridayFromTime || "default"}
                                    onChange={(e) => {
                                      setFridayFromTime(e.target.value);
                                      if (e.target.value === "closed") {
                                        setIsFriOpen(false);
                                      }
                                    }}
                                    disabled={!isFriOpen}
                                  >
                                    <option value="default" disabled>
                                      Select a time
                                    </option>
                                    {timeOptions.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              {isFriOpen &&
                                fridayFromTime !== "closed" &&
                                fridayFromTime !== "open_24_hours" && (
                                  <Col md={3}>
                                    <div className="mb-0">
                                      <select
                                        className="form-select"
                                        value={fridayToTime || "default"}
                                        onChange={(e) => {
                                          setFridayToTime(e.target.value);
                                          if (e.target.value === "closed") {
                                            setIsFriOpen(false);
                                          }
                                        }}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                )}
                            </Row>
                            <Row>
                              <Col md={2}>
                                <div className="form-check mb-3 m-2">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    defaultChecked
                                    checked={isSatOpen}
                                    onChange={() => setIsSatOpen(!isSatOpen)}
                                  />
                                  <Label
                                    className="form-check-label"
                                    for="formCheck1"
                                  >
                                    Saturday
                                  </Label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="mb-0">
                                  <select
                                    className="form-select"
                                    value={saturdayFromTime || "default"}
                                    onChange={(e) => {
                                      setSaturdayFromTime(e.target.value);
                                      if (e.target.value === "closed") {
                                        setIsSatOpen(false);
                                      }
                                    }}
                                    disabled={!isSatOpen}
                                  >
                                    <option value="default" disabled>
                                      Select a time
                                    </option>
                                    {timeOptions.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              {isSatOpen &&
                                saturdayFromTime !== "closed" &&
                                saturdayFromTime !== "open_24_hours" && (
                                  <Col md={3}>
                                    <div className="mb-0">
                                      <select
                                        className="form-select"
                                        value={saturdayToTime || "default"}
                                        onChange={(e) => {
                                          setSaturdayToTime(e.target.value);
                                          if (e.target.value === "closed") {
                                            setIsSatOpen(false);
                                          }
                                        }}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                )}
                            </Row>
                            <Row>
                              <Col md={2}>
                                <div className="form-check mb-0 m-2">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="formCheck1"
                                    defaultChecked
                                    checked={isSunOpen}
                                    onChange={() => setIsSunOpen(!isSunOpen)}
                                  />
                                  <Label
                                    className="form-check-label"
                                    for="formCheck1"
                                  >
                                    Sunday
                                  </Label>
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className="mb-0">
                                  <select
                                    className="form-select"
                                    value={sundayFromTime || "default"}
                                    onChange={(e) => {
                                      setSundayFromTime(e.target.value);
                                      if (e.target.value === "closed") {
                                        setIsSunOpen(false);
                                      }
                                    }}
                                    disabled={!isSunOpen}
                                  >
                                    <option value="default" disabled>
                                      Select a time
                                    </option>
                                    {timeOptions.map((option) => (
                                      <option
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              {isSunOpen &&
                                sundayFromTime !== "closed" &&
                                sundayFromTime !== "open_24_hours" && (
                                  <Col md={3}>
                                    <div className="mb-0">
                                      <select
                                        className="form-select"
                                        value={sundayToTime || "default"}
                                        onChange={(e) => {
                                          setSundayToTime(e.target.value);
                                          if (e.target.value === "closed") {
                                            setIsSunOpen(false);
                                          }
                                        }}
                                      >
                                        <option value="default" disabled>
                                          Select a time
                                        </option>
                                        {timeOptions.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                )}
                            </Row>
                          </div>
                        </Col>
                        <Col xxl={12} md={12} className="mt-2">
                          <div className="mb-2">
                            <Label
                              htmlFor="exampleFormControlTextarea5"
                              className="form-label"
                            >
                              Notes
                            </Label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea5"
                              rows="2"
                              value={notes}
                              onChange={(e) => setNotes(e.target.value)}
                            ></textarea>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {/* <hr></hr> */}
                  <ToastContainer closeButton={false} limit={1} theme="light" />
                  {/* reloading the page */}
                  {isUpdating && (
                    <div className="spinner-overlay">
                      <ClipLoader
                        css={override}
                        size={35}
                        color={"#123abc"}
                        loading={true}
                      />
                    </div>
                  )}

                  {/* {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}*/}
                </CardBody>
              </Card>
            </>
          )}
        </>
      )}
    </div>
  );
};
