import { Card, CardBody, Col, Row } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import img13 from "../../../assets/images/small/img-13.png";
import { Button } from 'reactstrap';
import { isEmpty } from "lodash";
import Select from "react-select";

import "../../../assets/scss/pages/_settings.scss";

import {
  CardHeader,
  Label,
  Input,

} from "reactstrap";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
//reloading the page
import { jsx } from '@emotion/react'
import { ClipLoader } from 'react-spinners';
//toast
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// axios
import axios from "axios";
import Loader from "../../../Components/Common/Loader";


//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export const Leave = () => {
  document.title="Leave | Get Rostered";
  const [isLoading, setisLoading] = useState(false);
  return (
    <div>
      <Card>
        <CardHeader>
          <h5 className="mb-0 text-primary">Leave</h5>
        </CardHeader>
        <CardBody>
        </CardBody>
      </Card>
    </div>
  )
}
