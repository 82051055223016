import React, { useEffect, useState } from 'react';
import { Col, Input, Label, Row, CardHeader, CardBody, Accordion, AccordionItem, Collapse, Card, Container, Table } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import axios from 'axios';
// import * as moment from "moment";
import moment from "moment";
// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { resetProperties } from 'lord-icon-element/utils/lottie';
import saveAs from "file-saver";

const OperationsReport = () => {
    const [col1, setcol1] = useState(false);
    const [reportsArray, setReportArray] = useState([]);
    const t_col1 = () => {
        setcol1(!col1);

    };
    const [data, setData] = useState();
    const [users, setUsers] = useState([]);
    const [reports, setReports] = useState([]);
    const [local, setlocal] = useState([]);
    const [interstate, setInterstate] = useState([]);
    const [totalInvoiceFormatted, setTotalInvoiceFormatted] = useState([]);
    const [totalBalanceFormatted, setTotalBalanceFormatted] = useState([]);
    const [totalPaymentsFormatted, setTotalPaymentsFormatted] = useState([]);

    //Created start date and end date
    const [createdDateStart, setCreatedDateStart] = useState("");
    const [createdDateEnd, setCreatedDateEnd] = useState("");
    const [selectedUserId, setSelectedUserId] = useState("");
    const [org_date, setOrg_Date] = useState([]);

    const loadData = () => {
        axios.get("api/crm-operations-report")
            .then(res => {
                console.log(res, "res12344");
                setOrg_Date(res.organisation_settings);
                setUsers(res.users);
                setData(res);
                setReports(res.reports);
                setInterstate(res.interstates);
                setCreatedDateEnd(moment().format(res.organisation_settings?.date_format_js));
                setCreatedDateStart(moment().subtract(1, 'months').format(res.organisation_settings?.date_format_js));
                setTotalInvoiceFormatted(res.totalInvoiceFormatted);
                setTotalBalanceFormatted(res.totalBalanceFormatted);
                setTotalPaymentsFormatted(res.totalPaymentsFormatted);

                const selectedDateFormat = res.organisation_settings?.date_format;

                // Second Axios request
                const filterData = {
                    'user_id': selectedUserId,
                    'created_date_start': moment().subtract(1, 'months').format(res.organisation_settings?.date_format_js),
                    'created_date_end': moment().format(res.organisation_settings?.date_format_js),
                    'selected_date_format': selectedDateFormat,
                };

                console.log(filterData);

                axios.post("api/crm-operations-get-data", filterData)
                    .then(res => {
                        console.log(res, "res456");
                        setlocal(res.local);
                        setLocalHours(res.totalLocalhours)
                        setLocalCompanyProfitFormated(res.localCompanyProfitFormated);
                        setLocalInvoiceFormated(res.localInvoiceFormatted);
                        setLocalBalanceFormated(res.localBalanceFormated);
                        setLocalPaymentFormated(res.localPaymentsFormated);
                        setLocalEmployeePaymentsFormated(res.localEmployeePaymentsFormated);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        // First Axios request
        loadData()
    }, []);

    const [localCompanyProfitFormated, setLocalCompanyProfitFormated] = useState([])
    const [localInvoiceFormated, setLocalInvoiceFormated] = useState([])
    const [localBalanceFormated, setLocalBalanceFormated] = useState([])
    const [localPaymentFormated, setLocalPaymentFormated] = useState([])
    const [localEmployeePaymentsFormated, setLocalEmployeePaymentsFormated] = useState([])

    const [localHours, setLocalHours] = useState([])
    const applyFilter = (event) => {
        event.preventDefault();
        const selectedDateFormat = org_date?.date_format;
        const filterData = {
            'user_id': selectedUserId,
            'created_date_start': createdDateStart,
            'created_date_end': createdDateEnd,
            'selected_date_format': selectedDateFormat
        }
        axios.post("api/crm-operations-get-data", filterData)
            .then(res => {
                console.log(res, "res456");
                setInterstate(res.interstates);
                setLocalHours(res.totalLocalhours)
                setLocalCompanyProfitFormated(res.localCompanyProfitFormated)
                setLocalInvoiceFormated(res.localInvoiceFormatted)
                setLocalBalanceFormated(res.localBalanceFormated)
                setLocalPaymentFormated(res.localPaymentsFormated)
                setReports(res.reports);
                setlocal(res.local);
                setLocalEmployeePaymentsFormated(res.localEmployeePaymentsFormated)
                //loadData();
            })
            .catch(err => {
                console.log(err);
            })
    }
    const handleReset = () => {
        loadData();
        setSelectedUserId("");
        setCreatedDateEnd(moment().format(org_date.date_format_js));
        setCreatedDateStart(moment().subtract(1, 'months').format(org_date.date_format_js));
        t_col1();

    }
    const userExcel = () => {
        console.log(createdDateStart, createdDateEnd)
        const selectedDateFormat = org_date?.date_format;
        axios.get("api/userexcel?created_date_start=" + createdDateStart + "&created_date_end=" + createdDateEnd + "&user_id=" + selectedUserId + "&selectedDateFormat=" + selectedDateFormat)
            .then((res) => {
                console.log(res);
                const excelData = res.excelData;
                const blob = new Blob([Uint8Array.from(atob(excelData), c => c.charCodeAt(0))], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });

                saveAs(blob, "Field Worker Pay Sheet.xlsx");
            })
            .then(err => {
                console.log(err);
            })
    }
    const userInterstateExcel = () => {
        const selectedDateFormat = org_date?.date_format;
        axios.get("api/userexcellocalinterstate?created_date_start=" + createdDateStart + "&created_date_end=" + createdDateEnd + "&user_id=" + selectedUserId + "&selectedDateFormat=" + selectedDateFormat)
            .then((res) => {
                console.log(res);
                const excelData = res.excelData;
                const blob = new Blob([Uint8Array.from(atob(excelData), c => c.charCodeAt(0))], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });

                saveAs(blob, "Field Worker Pay Sheet INTERSTATE TRIPS.xlsx");
            })
            .then(err => {
                console.log(err);
            })
    }
    const userLocalExcel = () => {
        const selectedDateFormat = org_date?.date_format;
        axios.get("api/userexcellocal?created_date_start=" + createdDateStart + "&created_date_end=" + createdDateEnd + "&user_id=" + selectedUserId + "&selectedDateFormat=" + selectedDateFormat)
            .then((res) => {
                console.log(res);
                const excelData = res.excelData;
                const blob = new Blob([Uint8Array.from(atob(excelData), c => c.charCodeAt(0))], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });

                saveAs(blob, "Field Worker Pay Sheet LOCAL JOBS.xlsx");
            })
            .then(err => {
                console.log(err);
            })
    }

    document.title = "Field Worker Pay Sheet | Get Rostered";

    useEffect(() => {
        setReportArray(Object.values(reports));
    }, [reports]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Field Worker Pay Sheet" pageTitle="Get Rostered" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className={classnames("accordion-button fw-semibold", { collapsed: !col1 })} type="button" onClick={t_col1} style={{ cursor: "pointer" }} >
                                                    Search Filters
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne" >
                                                <div className="accordion-body">

                                                    <Row>
                                                        <Col lg={3}>
                                                            <div className="mb-3">
                                                                <Label for="createdDateinput" className="form-label">Created Date</Label>
                                                                <Flatpickr
                                                                    className="form-control" placeholder='Start Date'
                                                                    options={{
                                                                        dateFormat: org_date?.date_format,
                                                                    }}
                                                                    value={createdDateStart}
                                                                    onChange={(selectedDates) => {
                                                                        console.log(selectedDates)
                                                                        if (selectedDates.length === 1) {
                                                                            setCreatedDateStart(moment(selectedDates[0]).format(org_date?.date_format_js));
                                                                            // setCreatedDateStart(moment(selectedDates[0]).format("DD/MM/YYYY"));
                                                                        } else {
                                                                            setCreatedDateStart(null);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={3}>
                                                            <div className="mb-3">
                                                                <Label for="createdDateinput" className="form-label" >End Date</Label>
                                                                <Flatpickr
                                                                    className="form-control"
                                                                    value={createdDateEnd}
                                                                    options={{
                                                                        dateFormat: org_date?.date_format,
                                                                    }}
                                                                    onChange={(selectedDates) => {
                                                                        if (selectedDates.length === 1) {
                                                                            setCreatedDateEnd(moment(selectedDates[0]).format(org_date?.date_format_js));
                                                                            // setCreatedDateEnd(moment(selectedDates[0]).format("DD/MM/YYYY"));
                                                                        } else {
                                                                            setCreatedDateEnd(null);
                                                                        }
                                                                    }} />
                                                            </div>
                                                        </Col>
                                                        <Col lg={3}>
                                                            <Label for="Sortingorder" className="form-label">User</Label>
                                                            <select
                                                                value={selectedUserId}
                                                                onChange={(event) => setSelectedUserId(event.target.value)}
                                                                className="form-select "
                                                                aria-label="Default select example"
                                                            >
                                                                <option value={""}>None selected</option>
                                                                {users.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </Col>
                                                        <Col lg={5}>
                                                            <div className="text-end">
                                                                <button type="submit" className="btn form-btn-marg btn-primary"
                                                                    onClick={applyFilter}
                                                                >Apply</button>
                                                                <button type="submit" className="btn form-btn-marg btn-primary"
                                                                    onClick={handleReset}
                                                                >Reset</button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Collapse>
                                        </AccordionItem>
                                    </Accordion>
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <img src="https://dev.onexfort.com/img/excel.svg" alt="" className='mb-2' style={{ width: "4%", float: "right", cursor: "pointer" }} onClick={() => userExcel()} />
                                    </div>
                                    <Table className="table table-bordered mb-5">
                                        <thead>
                                            <tr className='text-center'>
                                                <th>User</th>
                                                <th>Number of Jobs</th>
                                                <th>Hours Worked</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reportsArray?.map((item, index) => (
                                                <tr key={index}>
                                                    <td className=''> {item.name}</td>
                                                    <td className='right-align-center-align'>{item.NumberOfJobs}</td>
                                                    <td className='right-align-center-align'>{item.Hours}</td>
                                                </tr>
                                            ))}

                                        </tbody>

                                    </Table>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h5 className='mb-3' >Local Jobs</h5>
                                        <img src="https://dev.onexfort.com/img/excel.svg" alt="" className='mb-2' style={{ width: "4%", float: "right", cursor: "pointer" }} onClick={() => userLocalExcel()} />
                                    </div>
                                    <Table className="table table-bordered mb-5">
                                        <thead>
                                            <tr className='text-center'>
                                                <th>Job Date</th>
                                                <th>Job</th>
                                                <th>Customer</th>
                                                <th>Actual Hours</th>
                                                <th>Total Invoice</th>
                                                <th>Payments Received</th>
                                                <th>Balance</th>
                                                <th>Employee</th>
                                                <th>Employe Payment</th>
                                                <th>Company Profit</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {local?.map((item, index) => (

                                                <tr key={index}>
                                                    <td className='text-center'>
                                                        {moment(new Date(item.leg_date)).format(org_date?.date_format_js)}</td>
                                                    <td className='right-align-center-align'>{item.job_number}</td>
                                                    <td>{item.name}</td>
                                                    <td className='right-align-center-align'>{item.actual_hours}</td>
                                                    <td className='right-align-center-align'>$ {parseFloat(item.total_invoice).toFixed(2)}</td>
                                                    {
                                                        item.payment_received ? (
                                                            <td className='right-align-center-align'>$ {parseFloat(item.payment_received).toFixed(2)}</td>
                                                        ) : (
                                                            <td className='right-align-center-align'>$ 0.00</td>
                                                        )
                                                    }
                                                    <td className='right-align-center-align'> {item.balance_formatted}</td>
                                                    <td className='text-center'>{item.employee}</td>
                                                    <td className='right-align-center-align'> {item.employee_payment_formatted}</td>
                                                    <td className='right-align-center-align'>{item.company_profit_formatted}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan="3">
                                                    Total
                                                </td>
                                                <td className='right-align-center-align'>
                                                    {localHours}

                                                </td>
                                                <td className='right-align-center-align'>
                                                    {localInvoiceFormated}
                                                </td>
                                                <td className='right-align-center-align'>
                                                    {localPaymentFormated}
                                                </td>
                                                <td className='right-align-center-align'>
                                                    {localBalanceFormated}
                                                </td>
                                                <td></td>
                                                <td className='right-align-center-align'>

                                                    {localEmployeePaymentsFormated}
                                                </td>
                                                <td className='right-align-center-align'>

                                                    {localCompanyProfitFormated}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h5 className='mb-3' >Interstate Trips</h5>
                                        <img src="https://dev.onexfort.com/img/excel.svg" alt="" className='mb-2' style={{ width: "4%", float: "right", cursor: "pointer" }} onClick={() => userInterstateExcel()} />
                                    </div>
                                    <Table className="table table-bordered mb-5">
                                        <thead>
                                            <tr>
                                                <th>Job Date</th>
                                                <th>Job #</th>
                                                <th>Customer</th>
                                                <th>Total Invoice</th>
                                                <th>Payments Received</th>
                                                <th>Balance</th>
                                                <th>Employee</th>
                                                <th>Employee Payment</th>
                                                <th>Company Profit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {interstate?.map((item, index) => (
                                                <tr key={index} >
                                                    <td>{moment(new Date(item.start_date)).format(org_date?.date_format_js)}</td>
                                                    <td> {item.job_number}</td>
                                                    <td> {item.name}</td>
                                                    <td className='right-align-center-align'> ${parseFloat(item.total_invoice).toFixed(2)}</td>
                                                    <td className='right-align-center-align'>${parseFloat(item.payment_received).toFixed(2)}</td>
                                                    <td className='right-align-center-align'> {item.balance_formatted}</td>
                                                    <td> {item.employee}</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan="3">
                                                    Total
                                                </td>
                                                <td className='right-align-center-align' >
                                                    {totalInvoiceFormatted}
                                                </td>
                                                <td className='right-align-center-align'>
                                                    {totalPaymentsFormatted}
                                                </td>
                                                <td className='right-align-center-align'>
                                                    {totalBalanceFormatted}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

export default OperationsReport;
