import { Card, CardBody, Col, NavLink, Row } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button } from "reactstrap";
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import { CardHeader, Label, Table } from "reactstrap";
import Select from "react-select";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../../Components/Common/DeleteModal";
import "react-toastify/dist/ReactToastify.css";
import { get_cookie } from "../../../helpers/get_cookie";

export const WorkOrderTemplates = () => {
  document.title = "Workorder Templates | Get Rostered";
  // Vertical Nav Tabs
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [authUserId, setAuthUserId] = useState();
  const [rowsData, setRowsData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const field_type_options = [
    { type: "Text" },
    { type: "Number" },
    { type: "Date" },
    { type: "Time" },
    { type: "DateTime" },
    { type: "Boolean" },
    { type: "Select" },
    { type: "MultiSelect" },
    { type: "Textarea" },
    { type: "File" },
    { type: "Email" },
    { type: "URL" },
    { type: "Currency" },
    { type: "Percentage" },
  ];
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        setAuthUserId(res.id);
        getGroupsData(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const getGroupsData = (id) => {
    setIsLoading(true);
    var datavalue = {
      user_id: id,
      business_id: business_id,
    };
    axios
      .post("/get-work-order-groups-with-templates", datavalue)
      .then((res) => {
        console.log(res);
        setGroups(res.data);
        setIsLoading(false);
        setRowsData(new Array(res.data.length).fill([]));
      });
  };

  const handleEdit = (key, index) => {
    const updatedGroups = [...groups];
    updatedGroups[key].templates[index].isEditing = true;
    setGroups(updatedGroups);
  };
  const handleCancel = (key, index) => {
    const updatedGroups = [...groups];
    updatedGroups[key].templates[index].isEditing = false;
    setGroups(updatedGroups);
  };
  const handleChange = (key, index, event) => {
    const { name, value } = event.target;
    if (name === "field_name") {
      const updatedGroups = [...groups];
      updatedGroups[key].templates[index].field_name = value;
      setGroups(updatedGroups);
    }
    if (name === "field_type") {
      const updatedGroups = [...groups];
      updatedGroups[key].templates[index].field_type = value;
      setGroups(updatedGroups);
    }
    if (name === "default_value") {
      const updatedGroups = [...groups];
      updatedGroups[key].templates[index].default_value = value ? value : null;
      setGroups(updatedGroups);
    }
    if (name === "display_on_list") {
      const updatedGroups = [...groups];
      updatedGroups[key].templates[index].display_on_list = value;
      setGroups(updatedGroups);
    }
  };

  const handleUpdate = async (key, index) => {
    const item = groups[key].templates[index];
    console.log("updated item:", item);
    var datavalue = {
      user_id: authUserId,
      business_id: business_id,
      template_id: item.id,
      field_name: item.field_name,
      field_type: item.field_type,
      default_value: item.default_value,
      display_on_list: item.display_on_list,
    };
    if (item.field_name == "") {
      toast.warning("Field name can not be empty");
    }
    axios
      .post("/work-order-template-update", datavalue)
      .then((res) => {
        toast.success(res.success, { theme: "light" });
        getGroupsData(authUserId);
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };
  const handleDelete = async (key, index) => {
    const item = groups[key].templates[index];
    var id = item.id;
    setDeleteId(id);
    setDeleteModal(true);
  };
  const handleDeletePermission = async () => {
    var deleteval = {
      user_id: authUserId,
      business_id: business_id,
      template_id: deleteId,
    };
    axios
      .post("/work-order-template-delete", deleteval)
      .then((res) => {
        toast.success(res.message, { theme: "light" });
        getGroupsData(authUserId);
        setDeleteModal(false);
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };
  const addTableRows = (groupIndex) => {
    const updatedRowsData = [...rowsData];
    updatedRowsData[groupIndex] = [
      ...updatedRowsData[groupIndex],
      {
        field_name: "",
        field_type: "Text",
        default_value: null,
        display_on_list: 1,
      },
    ];
    setRowsData(updatedRowsData);
  };
  const deleteTableRows = (groupIndex, rowIndex) => {
    const updatedRowsData = [...rowsData];
    updatedRowsData[groupIndex] = updatedRowsData[groupIndex].filter(
      (_, index) => index !== rowIndex
    );
    setRowsData(updatedRowsData);
  };
  const handleRowInputChange = (key, index, event) => {
    const { name, value } = event.target;
    if (name === "field_name") {
      const updatedRowsData = [...rowsData];
      const rowIndex = updatedRowsData[key];
      rowIndex[index].field_name = value;
      setRowsData(updatedRowsData);
    }
    if (name === "field_type") {
      const updatedRowsData = [...rowsData];
      const rowIndex = updatedRowsData[key];
      rowIndex[index].field_type = value;
      setRowsData(updatedRowsData);
    }
    if (name === "default_value") {
      const updatedRowsData = [...rowsData];
      const rowIndex = updatedRowsData[key];
      rowIndex[index].default_value = value;
      setRowsData(updatedRowsData);
    }
    if (name === "display_on_list") {
      const updatedRowsData = [...rowsData];
      const rowIndex = updatedRowsData[key];
      rowIndex[index].display_on_list = value;
      setRowsData(updatedRowsData);
    }
  };
  const saveTableRow = (key, rowIndex) => {
    let groupRow = rowsData[key];
    const rowData = groupRow[rowIndex];
    let groupId = groups[key].group_id;

    var datavalue = {
      user_id: authUserId,
      business_id: business_id,
      group_id: groupId,
      field_name: rowData.field_name,
      field_type: rowData.field_type,
      default_value: rowData.default_value,
      display_on_list: rowData.display_on_list,
    };
    console.log(datavalue, "payload for adding a template prop");
    if (rowData.field_name == "") {
      toast.error("Field name field is required.", { theme: "light" });
      return;
    }

    axios
      .post("/work-order-template-create", datavalue)
      .then((res) => {
        toast.success(res.message, { theme: "light" });
        const updatedRowsData = [...rowsData];
        updatedRowsData[key] = updatedRowsData[key].filter(
          (_, index) => index !== rowIndex
        );
        setRowsData(updatedRowsData);
        getGroupsData(authUserId);
      })
      .catch((error) => {
        toast.warning(error.error, { theme: "light" });
        console.error("Error fetching lead types:", error);
      });
  };
  return (
    <div>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePermission}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardHeader>
          <h5 className="mb-0 text-primary">Workorder Templates</h5>
        </CardHeader>
        <CardBody>
          {groups?.map((groupItem, key) => (
            <div key={key}>
              <h5 style={{ paddingTop: '5px', paddingBottom: '10px' }}>{groupItem.group_title}</h5>

              <Table className="table table-bordered  mt-2 align-middle mb-3">
                <thead className="bg-light">
                  <tr>
                    <th className="listTypeHead" style={{ width: "200px" }}>
                      Field Name
                    </th>
                    <th className="listTypeHead" style={{ width: "170px" }}>
                      Field Type
                    </th>
                    <th className="listTypeHead" style={{ width: "150px" }}>
                      Default value
                    </th>
                    <th className="listTypeHead" style={{ width: "150px" }}>
                      Display on List Page?
                    </th>

                    <th style={{ width: "145px" }}>Action</th>
                  </tr>
                </thead>
                <tbody className="mb-3">
                  {groupItem.templates?.map((item, index) => (
                    <tr key={index}>
                      <td className="fw-medium">
                        {item.isEditing ? (
                          <input
                            type="text"
                            value={item.field_name}
                            onChange={(e) => handleChange(key, index, e)}
                            className="form-control"
                            name="field_name"
                          />
                        ) : (
                          item.field_name
                        )}
                      </td>
                      <td className="fw-medium">
                        {item.isEditing ? (
                          <select
                            value={item.field_type}
                            onChange={(event) =>
                              handleChange(key, index, event)
                            }
                            name="field_type"
                            className="form-control"
                          >
                            <option disabled value="">
                              Select{" "}
                            </option>
                            {field_type_options?.map((option, id) => (
                              <option key={id} value={option.type}>
                                {option.type}
                              </option>
                            ))}
                          </select>
                        ) : (
                          item.field_type
                        )}
                      </td>
                      <td className="fw-medium">
                        {item.isEditing ? (
                          <input
                            type="text"
                            value={item.default_value}
                            onChange={(e) => handleChange(key, index, e)}
                            className="form-control"
                            name="default_value"
                          />
                        ) : item.default_value ? (
                          item.default_value
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="fw-medium">
                        {item.isEditing ? (
                          <select
                            value={item.display_on_list}
                            onChange={(event) =>
                              handleChange(key, index, event)
                            }
                            name="display_on_list"
                            className="form-control"
                          >
                            <option disabled value="">
                              Select{" "}
                            </option>

                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        ) : item.display_on_list == 0 ? (
                          "No"
                        ) : (
                          "Yes"
                        )}
                      </td>
                      <td>
                        <div className="hstack gap-1 flex-wrap">
                          {item.isEditing ? (
                            <>
                              <button
                                className="btn btn-success pe-2 ps-2"
                                onClick={() => handleUpdate(key, index)}
                              >
                                Update
                              </button>
                              <button
                                className="btn btn-primary pe-2 ps-2"
                                onClick={() => handleCancel(key, index)}
                              >
                                Cancel
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="btn btn-sm btn-soft-info edit-list "
                                onClick={() => handleEdit(key, index)}
                              >
                                <i className="bx bxs-pencil fs-12 pt-1"></i>
                              </button>
                              <button
                                className="btn btn-sm btn-soft-danger  "
                                onClick={() => handleDelete(key, index)}
                              >
                                <i className="ri-delete-bin-5-fill fs-12 pt-1"></i>
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  {rowsData[key] &&
                    rowsData[key]?.map((data, rowId) => (
                      <tr key={rowId}>
                        <td>
                          <input
                            type="text"
                            value={data.field_name}
                            onChange={(event) =>
                              handleRowInputChange(key, rowId, event)
                            }
                            name="field_name"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <select
                            value={data.field_type}
                            onChange={(event) =>
                              handleRowInputChange(key, rowId, event)
                            }
                            name="field_type"
                            className="form-control"
                          >
                            <option disabled value="">
                              Select{" "}
                            </option>
                            {field_type_options?.map((option, id) => (
                              <option key={id} value={option.type}>
                                {option.type}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            value={data.default_value}
                            onChange={(event) =>
                              handleRowInputChange(key, rowId, event)
                            }
                            name="default_value"
                            className="form-control"
                          />
                        </td>
                        <td>
                          <select
                            value={data.display_on_list}
                            onChange={(event) =>
                              handleRowInputChange(key, rowId, event)
                            }
                            name="display_on_list"
                            className="form-control"
                          >
                            <option disabled value="">
                              Select{" "}
                            </option>

                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </td>
                        <td>
                          <div className="hstack gap-2">
                            <button
                              className="btn btn-success"
                              onClick={() => saveTableRow(key, rowId)}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-primary"
                              onClick={() => deleteTableRows(key, rowId)}
                            >
                              Cancel
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
                <button
                  className="btn btn-primary mt-3"
                  onClick={() => addTableRows(key)}
                >
                  +
                </button>
              </Table>
            </div>
          ))}
        </CardBody>
      </Card>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </div>
  );
};
