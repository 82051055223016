import React from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Button
} from "reactstrap";
import logoPurple from './assets/images/logopurple.png';

const TermsOfUse = () => {
  return (
    <Container >
      <Card style={{ marginTop: '2.5rem', marginBottom: '2.5rem', padding: '2.5rem' }}>
        <CardBody>
        <div style={{ position: 'relative' }}>
        <h3 style={{ marginTop: 0, marginBottom: 0, fontWeight: 600, lineHeight: 1.2 }}>GET ROSTERED PTY LTD</h3>
        <img
              src={logoPurple}
              alt="Logo Purple"
              height={50}
              style={{
                position: 'absolute',
                top: 0,
                right: 40,
              }}
            />
          <p>Australian Company Number 676 787 554</p>

          <h4>WEBSITE AND APP TERMS OF USE</h4>
          <p>
            Welcome to Get Rostered. This platform, accessible via{' '}
            <a
              href="https://getrostered.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://getrostered.app
            </a>
            , including its subdomains, mobile applications, and any other
            related services, is owned and operated by Get Rostered Pty Ltd
            (ACN 676 787 554). By using our business portal and workforce
            app, you agree to comply with and be bound by the following terms
            and conditions of use, which together with our privacy policy
            govern Get Rostered's relationship with you in connection with
            this platform. If you disagree with any part of these terms and
            conditions, please do not use our platform.
          </p>
          <p>
            The term 'Get Rostered' or 'us' or 'our' or 'we' refers to Get
            Rostered Pty Ltd, the owner of the website and application, whose
            registered office is in Victoria, Australia. The term 'you' or
            'your' refers to the platform user.
          </p>
          <p>Your use of this platform is subject to the following terms and conditions:</p>

          <h5>1. Content and Changes</h5>
          <p>
            The content of this platform is for your general information and
            use only. It is subject to change without prior notice.
          </p>

          <h5>2. Cookies</h5>
          <p>
            This platform uses cookies to monitor browsing preferences. If
            you allow cookies to be used, the following personal information
            may be stored by us for use by third parties: name, address.
          </p>

          <h5>3. Warranties and Disclaimers</h5>
          <p>
            Neither we nor any third parties provide any warranty or guarantee
            as to the performance, accuracy, timeliness, completeness, or
            suitability of the information and materials found or offered on
            this platform for any particular purpose. You acknowledge that
            such information and materials may contain mistakes, inaccuracies,
            or errors, and we expressly exclude any liability for such to the
            fullest extent permissible by law.
          </p>

          <h5>4. Risk and Liability</h5>
          <p>
            Your use of any information or materials on this platform is
            entirely at your own risk, for which we shall not be liable. It
            is your own responsibility to ensure that any products, services,
            or information available through this platform meet your specific
            requirements.
          </p>

          <h5>5. Intellectual Property</h5>
          <p>
            This platform contains material which is owned by or licensed to
            us. This material includes, but is not limited to, the content,
            design, layout, appearance, look, and graphics of the platform.
            Any reproduction of the platform’s material is prohibited other
            than in accordance with the copyright notice which forms part of
            these terms and conditions.
          </p>

          <h5>6. Trademarks</h5>
          <p>
            All trademarks reproduced in this platform which are not the
            property of, or licensed to us, are acknowledged on the platform.
          </p>

          <h5>7. Unauthorized Use</h5>
          <p>
            Unauthorized use of this platform may be a criminal offence and/or
            give rise to a claim for damages.
          </p>

          <h5>8. External Links</h5>
          <p>
            This platform may also include links to other websites which are
            not controlled by us. These links are provided for your convenience
            to provide you with further information. You acknowledge that they
            are used at your own risk. They do not signify that we recommend
            or endorse the websites. We have no control over the nature,
            content, and availability of those websites.
          </p>

          <h5>9. Governing Law</h5>
          <p>
            Your use of this platform and any dispute arising out of your use
            of it is subject to the laws of Victoria, Australia.
          </p>

          <h5>10. Lawful Use</h5>
          <p>
            You may only use the platform for lawful purposes and in a manner
            consistent with the nature and purpose of the platform.
          </p>

          <h5>11. Product and Service Terms</h5>
          <p>
            These terms and conditions do not relate to your use of any product
            or service described on our platform unless otherwise agreed. You
            must refer to the individual warranty relevant to any particular
            product or service.
          </p>

          <h5>12. Amendments</h5>
          <p>
            These terms and conditions may be amended from time to time. Your
            continued use of our platform following any such amendments will
            be deemed to be confirmation that you accept those amendments.
          </p>

          <h5>13. Indemnity</h5>
          <p>
            You indemnify us from and against all claims, suits, demands,
            actions, liabilities, costs, and expenses (including legal costs
            and expenses on a full indemnity basis) resulting from your use of
            the platform.
          </p>

          <h5>14. Limitation of Liability</h5>
          <p>
            In no event will we be liable for any loss, damage, cost, or
            expense, including legal costs and expenses (whether direct or
            indirect), incurred by you in connection with the use of this
            platform.
          </p>

          <h5>15. Availability</h5>
          <p>
            Every effort is made to keep the platform up and running smoothly.
            However, we take no responsibility for, and will not be liable
            for, the platform being temporarily unavailable due to technical
            issues beyond our control.
          </p>

          <p>
            By using Get Rostered's business portal and workforce app, you
            agree to these terms of use. If you have any questions or need
            further information, please contact us at{' '}
            <a href="mailto:support@getrostered.app">support@getrostered.app</a>
            .
          </p>
        </div>
        </CardBody>
      </Card>
    </Container>
  );
};

export default TermsOfUse;
