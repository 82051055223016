import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Col, Container, Row, Input, Button, Spinner } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Components/Common/Loader";
import logoRoster from "../../assets/images/logopurple.png";
import AuthSlider from "../AuthenticationInner/authCarousel";
import { Link } from "react-router-dom";

const Invite = () => {
  const { params } = useParams();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [email_status, setEmailStatus] = useState("");
  const [mail_invite_permission, setMailInvitePermission] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isLoadings, setIsLoadings] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [paramsObject, setParamsObject] = useState({});

  useEffect(() => {
    loadTable();
  }, []);

  const loadTable = async () => {
    setIsLoading(true);
    try {
      const decodedParams = atob(params);
      const paramsArray = decodedParams.split("&");
      const paramsObject = {};
      paramsArray.forEach((param) => {
        const [key, value] = param.split("=");
        paramsObject[key] = value;
      });

      setParamsObject(paramsObject);

      const { business_id, user_id } = paramsObject;
      console.log(paramsObject);

      const formData = {
        user_id: user_id,
        business_id: business_id,
      };

      const response = await axios.post("/get-invitation-data", formData);

      setData(response);
      setFirstName(response?.worker_first_name);
      setLastName(response?.worker_last_name);
      setEmail(response?.worker_email);
      setMailInvitePermission(response?.mail_invite_permission);
      setEmailStatus(response?.email_status);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    console.log("Pooja", email_status);
    // Clear any previous errors
    setPasswordError("");
    setConfirmPasswordError("");

    try {
      if(!email_status){
        if (password == "" || password == null) {
          setPasswordError("Enter password to continue")
          setIsLoading(false);
          return;
        }
        else if (confirmPassword == "" || confirmPassword == null) {
          setConfirmPasswordError("Enter confirm password to continue");
          setIsLoading(false);
          return;
        }
        if (password !== confirmPassword) {
          setConfirmPasswordError("Passwords do not match");
          toast.error("Password and confirm password do not match");
          setIsLoading(false);
          return;
        }
      }
     
      const response = await axios.post("/accept-invitation", {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        user_id: paramsObject.user_id,
        business_id: paramsObject.business_id,
        business_name: data.business_name,
      });
      console.log("test");
      if ([205, 200, 209, 309].includes(response.status)) {
        console.log("test");
        navigate("/login", { state: { email } });
      } else if (response.status == 400) {
        toast.error(response.message);
      } else {
        toast.error("An unexpected error occurred during registration", {
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordError(""); // Reset confirm password error when password changes
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setConfirmPasswordError(""); // Reset confirm password error when confirm password changes
  };

  return (
    <React.Fragment>
      <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden m-0">
                  <Row className="justify-content-center g-0">
                    <AuthSlider />
                    <div className="col-lg-6">
                      <div className="p-lg-5 p-4">
                        <div className="mb-4 text-center">
                          <img src={logoRoster} alt="" height="30" />
                        </div>
                        <div className="mt-1">
                          <form className="needs-validation" noValidate>
                            <div className="mb-3 text-center">
                              <h5 className="text-primary p-3 pb-0">
                                {data.worker_name}
                              </h5>
                            </div>

                            <div className="mb-4 mt-2 text-center">
                              <h5 className=" p-3 pb-0">
                                {data.business_name} has invited you to access
                                their business portal
                              </h5>
                            </div>

                            <div className="mb-4 mt-2 text-left">
                              <h5 className="text-primary p-0 pb-0">
                                User email
                              </h5>
                              <b className=" p-0 pb-0">{data.worker_email}</b>
                            </div>

                            {/* Conditionally render password fields based on emailStatus */}
                            {email_status === false ? (
                              <>
                                <div className="mb-3 text-left">
                                  <label
                                    className="form-label"
                                    htmlFor="password-input"
                                  >
                                    Password
                                  </label>
                                  <div className="position-relative auth-pass-inputgroup">
                                    <Input
                                      type={passwordShow ? "text" : "password"}
                                      className={`form-control pe-5 password-input ${passwordError ? "is-invalid" : ""}`}
                                      placeholder="Enter password"
                                      id="password-input"
                                      name="password"
                                      value={password}
                                      onChange={handlePasswordChange}
                                      onPaste={(e) => e.preventDefault()}
                                      required
                                    />
                                    <Button
                                      color="link"
                                      onClick={() =>
                                        setPasswordShow(!passwordShow)
                                      }
                                      className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                      type="button"
                                      id="password-addon"
                                    >
                                      <i className="ri-eye-fill align-middle"></i>
                                    </Button>
                                    {passwordError && (
                                      <div className="invalid-feedback">
                                        {passwordError}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="mb-3">
                                  <label
                                    className="form-label"
                                    htmlFor="confirm-password-input"
                                  >
                                    Confirm password
                                  </label>
                                  <div className="position-relative auth-pass-inputgroup">
                                    <Input
                                      type={confirmPasswordShow ? "text" : "password"}
                                      className={`form-control pe-5 password-input ${confirmPasswordError ? "is-invalid" : ""}`}
                                      placeholder="Enter Confirm password"
                                      id="confirm-password-input"
                                      name="confirmPassword"
                                      value={confirmPassword}
                                      onChange={handleConfirmPasswordChange}
                                      onPaste={(e) => e.preventDefault()}
                                      required
                                    />
                                    <Button
                                      color="link"
                                      onClick={() =>
                                        setConfirmPasswordShow(
                                          !confirmPasswordShow
                                        )
                                      }
                                      className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                      type="button"
                                      id="confirm-password-addon"
                                    >
                                      <i className="ri-eye-fill align-middle"></i>
                                    </Button>
                                    {confirmPasswordError && (
                                      <div className="invalid-feedback">
                                        {confirmPasswordError}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : null}

                            {/* Show Accept Invite Button */}
                            <Button
                              onClick={handleSubmit}
                              className="btn btn-signup w-100 text-white"
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <Spinner size="sm" className="me-2">
                                  Loading...
                                </Spinner>
                              ) : "Accept Invite"}
                            </Button>
                          </form>

                          {mail_invite_permission === "accept" ? (
                            <div className="col-lg-12">
                              <div className="text-center">
                                <p className="mt-3">
                                  By signing up, you agree to our{" "}
                                  <Link
                                    to="/terms-of-use"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="terms-link"
                                  >
                                    Terms of Use
                                  </Link>{" "}
                                  and{" "}
                                  <Link
                                    to="/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="terms-link"
                                  >
                                    Privacy Policy
                                  </Link>
                                </p>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Row>

                  {/* Loader when API call is in progress */}
                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
            <ToastContainer />
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Invite;
