import React, {
  useState,
  useEffect,
  useMemo,
} from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import axios from "axios";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import { get_cookie } from "../../helpers/get_cookie";

const Directory = () => {
  document.title = "Directory | Get Rostered";
  const [isLoading, setisLoading] = useState(false);
  const [authUserId, setAuthUserId] = useState();
  const business_id = JSON.parse(get_cookie("authUser")).business_id;

  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res);
        setAuthUserId(res.id);
        loadTable(res.id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [data, setData] = useState([]);

  const loadTable = (userId) => {
    const formData = {
      user_id: userId,
      business_id: business_id,
    };
    setisLoading(true);
    axios
      .post("/get-directory-data", formData)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const columns = useMemo(() => [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ row }) => {
        const colors = [
          "#ff5733",
          "#c70039",
          "#900c3f",
          "#581845",
          "#2e86c1",
          "#16a085",
          "#8e44ad",
          "#f39c12",
          "#3498db",
          "#d35400",
        ]; // Define your array of colors
        const initials =
          row.original.first_name?.charAt(0).toUpperCase() +
          row.original.last_name?.charAt(0).toUpperCase();
        const colorIndex = initials?.charCodeAt(0) % colors.length;
        const bgColor = colors[colorIndex];

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {row.original.profile_picture_url ? (
              <img
                src={row.original.profile_picture_url}
                alt={row.original.full_name}
                style={{
                  marginRight: "0.5rem",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <div
                style={{
                  marginRight: "0.5rem",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: bgColor, // Use the calculated background color
                }}
              >
                <span style={{ fontSize: "18px", color: "#fff" }}>
                  {initials || '-'}
                </span>
              </div>
            )}
            <span>{row.original.full_name || '-'}</span>
          </div>
        );
      },
      filterable: false,
    },
    {
      Header: "Location",
      accessor: "address",
      Cell: ({ value }) => value ? value : "-",
      filterable: false,
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }) => value ? value : "-",
      filterable: false,
    },
    {
      Header: "Mobile",
      accessor: "mobile",
      Cell: ({ value }) => value ? value : "-",
      filterable: false,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => value ? value : "-",
      filterable: false,
    },
  ], []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Directory" />
          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody className="p-4">
                  <div>
                    {data.length ? (
                      <TableContainer
                        columns={columns}
                        data={data || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={100}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        isContactsFilter={true}
                        SearchPlaceholder="Search by name, location, email..."
                      />
                    ) : (
                      <p style={{ textAlign: "center", marginTop: "10px" }}>
                        No records found
                      </p>
                    )}
                  </div>
                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Directory;
