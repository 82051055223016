import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import GoogleAutocomplete from "react-google-autocomplete";
import axios from "axios";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Progress,
  Spinner,
  FormFeedback,
} from "reactstrap";
import classnames from "classnames";
import Dropzone from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import progileBg from "../../assets/images/profile-bg.jpg";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import cleaning from "../../assets/images/freepik/mop_cleaning.png";
import uploadImg from "../../assets/images/freepik/uploading_img.png";
import businessProfile from "../../assets/images/b_profile.jpg";
import userDummy from "../../assets/images/users/user-dummy-img.jpg";
import checklist from "../../assets/images/freepik/Checklist.jpg";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import { add_cookie, get_cookie } from "../../helpers/get_cookie";
import logo from "../../assets/images/logo_purple_trans.png";
import halfLogo from "../../assets/images/half-logo.png";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import setCanvasPreview from "../../setCanvasPreview";

const MIN_DIMENSION = 100;

const BusinessProfile = () => {
  // image cropper
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [croppedImgSrc, setCroppedImgSrc] = useState(null);
  const [error, setError] = useState("");
  const [uploadFileName, setUploadFileName] = useState("");
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const handleAcceptedFiles = (files) => {
    const file = files[0];
    if (!file) return;

    if (file) {
      setUploadFileName(file.name);
    }

    // Validate file type
    const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (!validFileTypes.includes(file.type)) {
      setError("Invalid file type. Please select a JPEG or PNG image.");
      setImgSrc(null);
      setCroppedImgSrc(null);
      return;
    }

    // Clear any previous error if file type is valid
    setError("");

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        const { naturalWidth, naturalHeight } = e.currentTarget;

        // If the image is smaller than the minimum crop dimensions, skip cropping
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setImgSrc(imageUrl);
          setCroppedImgSrc(imageUrl); // Save the image as it is
          return;
        }

        setImgSrc(imageUrl);
      });
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.currentTarget;
    const aspectRatio = naturalWidth / naturalHeight;

    // Proceed with cropping only for larger images
    const cropWidthInPercent = 50; // Adjust as needed

    const initialCrop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      aspectRatio,
      naturalWidth,
      naturalHeight
    );
    const centeredCrop = centerCrop(initialCrop, naturalWidth, naturalHeight);
    setCrop(centeredCrop);
  };

  // Update canvas preview
  const updateCanvasPreview = (crop) => {
    if (
      imgRef.current &&
      previewCanvasRef.current &&
      crop &&
      crop.width &&
      crop.height
    ) {
      setCanvasPreview(
        imgRef.current, // HTMLImageElement
        previewCanvasRef.current, // HTMLCanvasElement
        convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
      );

      const canvas = previewCanvasRef.current;
      const dataUrl = canvas.toDataURL("image/jpeg");
      setCroppedImgSrc(dataUrl);
    }
  };

  // Effect to update canvas preview
  useEffect(() => {
    if (imgSrc && crop) {
      updateCanvasPreview(crop);
    }
  }, [imgSrc, crop]);

  // const handleCropSave = async () => {
  //   if (croppedImgSrc) {
  //     const croppedImageFile = await fetch(croppedImgSrc).then((res) =>
  //       res.blob()
  //     );
  //     croppedImageFile.name = selectedFileName.name;
  //     console.log(croppedImgSrc, "setSelectedFile1231");
  //     setSelectedFile(croppedImageFile);
  //   }
  // };

  const navigate = useNavigate();
  const [data, setData] = useState();
  const [authUserId, setAuthUserId] = useState();
  const [activeTab, setactiveTab] = useState(1);
  const [userName, setUserName] = useState();
  const [progressbarvalue, setprogressbarvalue] = useState(0);
  const [registrationProgress, setRegistrationProgress] = useState(0);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [indOptions, setIndOptions] = useState([]);
  const [selectedInd, setSelectedInd] = useState();
  const [showIndustries, setShowIndustries] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [roleInCompany, setRoleInCompany] = useState("");
  const [roleInCompanyError, setRoleInCompanyError] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [businessSize, setBusinessSize] = useState();
  const [checkboxes, setCheckboxes] = useState([
    { label: "1 - 10", checked: false, value: "1-10" },
    { label: "11 - 30", checked: false, value: "11-30" },
    { label: "31 - 50", checked: false, value: "31-50" },
    { label: "51 - 100", checked: false, value: "51-100" },
    { label: "101 - 200", checked: false, value: "101-200" },
    { label: "201 - 500", checked: false, value: "201-500" },
    { label: "501 - 1000", checked: false, value: "501-1000" },
    { label: "1000+", checked: false, value: "1000+" },
  ]);
  // const [selectedFiles, setselectedFiles] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCity, setSelectedcity] = useState();
  const [selectedSuburb, setselectedSuburb] = useState();
  const [selectedPostcode, setselectedPostcode] = useState();
  const [country, setCountry] = useState([]);
  const [isProfileCompleted, setIsProfileCompleted] = useState(false);
  const [profileStepCount, setProfileStepCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const progressMap = {
    0: 0,
    1: 30,
    2: 70,
    3: 100,
  };
  const tabBar = {
    0: 0,
    1: 30,
    2: 70,
    3: 100,
  };
  let progressBarWidth = "0";
  if (registrationProgress === 30) {
    progressBarWidth = "33";
  } else if (registrationProgress === 70) {
    progressBarWidth = "66";
  } else if (registrationProgress === 100) {
    progressBarWidth = "100";
  }
  const residentialplace = (place) => {
    const addressComponents = place?.address_components;
    console.log(place);
    const { lat, lng } = place.geometry.location;
    const streetNumberComponent = addressComponents?.find((component) =>
      component.types.includes("street_number")
    );
    const streetRouteComponent = addressComponents?.find((component) =>
      component.types.includes("route")
    );
    const unitComponent = place?.addressComponents?.find((component) =>
      component.types.includes("subpremise")
    );
    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );
    const countryComponent = addressComponents?.find((component) =>
      component.types.includes("country")
    );

    let selectedStreetNumber = "";
    if (streetNumberComponent) {
      selectedStreetNumber = streetNumberComponent.long_name;
    }
    let selectedUnitNumber = "";
    if (unitComponent) {
      selectedUnitNumber = unitComponent.long_name;
    }
    let selectedStreetRoute = "";
    if (streetRouteComponent) {
      selectedStreetRoute = streetRouteComponent.long_name;
    }
    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
    }
    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name;
    }
    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }
    let selectedcountry = "";
    if (countryComponent) {
      selectedcountry = countryComponent.long_name;
    }

    setSelectedcity(
      `${selectedUnitNumber} ${selectedStreetNumber} ${selectedStreetRoute} `
    );
    setselectedSuburb(selectedSuburb + ", " + selectedState);
    setselectedPostcode(selectedPostcode);
    setCountry(selectedcountry);
    setAddress(place?.formatted_address);
    setLatitude(lat());
    setLongitude(lng());
  };
  console.log(selectedCity, "selectedCity");
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res);
        setUserName(res.name);
        setAuthUserId(res.id);
        getRegistrationData(res.id);
      })
      .then((err) => {
        console.log(err);
      });
    axios
      .get("/industry-options")
      .then((res) => {
        setIndOptions(res);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const getRegistrationData = (userId) => {
    setIsLoading(true);
    let formData = {
      user_id: userId,
      business_id: business_id,
    };
    axios
      .post("/get-registration-data", formData)
      .then((res) => {
        console.log(res, "busnessdata");
        console.log(res.business_name);
        setCompanyName(res.business_name);
        setDescription(res.about_the_business);
        setRoleInCompany(res.role_name);
        setBusinessSize(res.business_size);
        setSelectedInd(res.industry_name);
        setSelectedCard(res.industry_name);
        setCountry(res.country_name);
        setProfileStepCount(res.profile_setup_step);
        setIsProfileCompleted(res.profile_setup_completed === 1);
        setactiveTab(res.profile_setup_step + 1);
        const step = res.profile_setup_step;
        // Setting progress bar value based on the step
        setprogressbarvalue(tabBar[step] || 0);
        setRegistrationProgress(progressMap[step] || 0);
        const checkboxIndex = checkboxes.findIndex(
          (checkbox) => checkbox.value === res.business_size
        );
        if (checkboxIndex !== -1) {
          handleButtonClick(checkboxIndex);
        }
        setAddress(
          (res.address ? res.address + ", " : "") +
            (res.suburb ? res.suburb + " " : "") +
            (res.postcode ? res.postcode + ", " : "") +
            (res.country_name ? res.country_name : "")
        );
        setLatitude(res.latitude);
        setLongitude(res.longitude);
        setSelectedcity(res.address);
        setselectedSuburb(res.suburb);
        setselectedPostcode(res.postcode);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleButtonClick = (index) => {
    setCheckboxes((prevCheckboxes) => {
      const newCheckboxes = [...prevCheckboxes];
      newCheckboxes.forEach((checkbox, i) => {
        checkbox.checked = i === index;
      });
      return newCheckboxes;
    });

    setBusinessSize(checkboxes[index].value);
  };

  const handleNextButtonClick = () => {
    // Validate the company name
    if (!companyName?.trim()) {
      setCompanyNameError("Please enter your company name.");
    } else {
      setCompanyNameError("");
    }
    if (!description?.trim()) {
      setDescriptionError("Please enter company description.");
    } else {
      setDescriptionError("");
    }

    // Validate the role in the company
    if (!roleInCompany?.trim()) {
      setRoleInCompanyError("Please enter your role in company.");
    } else {
      setRoleInCompanyError("");
    }

    //Validate the company address
    if (!address) {
      setAddressError("Please enter company address.");
    } else {
      setAddressError("");
    }

    //proceed to next step if all conditions are met
    if (
      companyName?.trim() &&
      description?.trim() &&
      roleInCompany?.trim() &&
      address
    ) {
      toggleTab(activeTab + 1, 30);
    }
  };

  const [isCharity, setIsCharity] = useState(0); // Initialize as 0 (unchecked)
  const [abnNumber, setAbnNumber] = useState(""); // Initialize as empty string
  const [abnError, setAbnError] = useState(""); // Initialize as empty string

  const handleCheckboxChange = (e) => {
    setIsCharity(e.target.checked ? 1 : 0);
  };

  const handleAbnChange = (e) => {
    const value = e.target.value;
    const isNumeric = /^[0-9]*$/.test(value); // Regular expression to check if the value is numeric

    let error = "";
    if (!isNumeric) {
      error = "ABN number must be numeric";
    } else if (value.length > 20) {
      error = "ABN number cannot exceed 20 digits";
    }

    setAbnNumber(value);
    setAbnError(error);
  };

  function toggleTab(tab, value) {
    if (tab == 3 && (selectedInd == null || !selectedInd)) {
      toast.warning("Please select your business industry", {
        autoClose: 2000,
      });
      return false;
    }
    //validate employee-number

    if (tab == 2) {
      if (abnError) {
        return;
      }
      var formData = {
        user_id: authUserId,
        business_id: business_id,
        company_name: companyName,
        about_the_business: description,
        role_in_company: roleInCompany,
        address: selectedCity,
        suburb: selectedSuburb,
        longitude: longitude,
        latitude: latitude,
        post_code: selectedPostcode,
        country_name: country,
        is_charity_or_non_profit: isCharity,
        business_number: abnNumber,
      };
      console.log(formData, "formData");
      axios
        .post("/save-company-name-and-role", formData)
        .then((res) => {
          console.log(res);
        })
        .then((err) => {
          console.log(err);
        });
    } else if (tab == 3) {
      let formData = {
        user_id: authUserId,
        business_size: businessSize,
        name: selectedInd,
        business_id: business_id,
      };
      axios
        .post("/save-employees-and-industry", formData)
        .then((res) => {
          console.log(res);
        })
        .then((err) => {
          console.log(err);
        });
    } else if (tab == 4) {
      let file;
      if (croppedImgSrc) {
        // Function to convert base64 to binary file
        const base64ToFile = (base64String, filename) => {
          // Remove the data URL prefix
          const byteString = atob(base64String.split(",")[1]);
          // Get the MIME type from the base64 string
          const mimeType = base64String.match(/:(.*?);/)[1];
          // Create an ArrayBuffer to hold the binary data
          const ab = new ArrayBuffer(byteString.length);
          // Create a typed array from the ArrayBuffer
          const ia = new Uint8Array(ab);
          // Convert the binary string to binary data
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          // Create a Blob from the binary data
          const blob = new Blob([ia], { type: mimeType });
          // Create a File from the Blob
          return new File([blob], filename, { type: mimeType });
        };

        file = base64ToFile(croppedImgSrc, uploadFileName);
      }
      const formData = new FormData();
      formData.append("user_id", authUserId);
      formData.append("business_id", business_id);

      if (file) {
        formData.append("business_logo", file);
      }

      axios
        .post("/upload-logo", formData)
        .then((res) => {
          console.log(res);
          if (res.profile_setup_completed === 1) {
            setIsLoading(true);
            var user = JSON.parse(get_cookie("authUser"));
            user.profile_status = 1;
            setError("");
            add_cookie(user);
            const param = "register"; // Your parameter
            const encodedParam = btoa(param); // Encode to Base64

            setTimeout(() => {
              navigate(
                `/business_settings/sites/AddSite?param=${encodedParam}`
              ); // Use backticks for template literals
            }, 3000);
          }
        })
        .then((err) => {
          console.log(err);
        });
    }
    if (companyName.trim() && roleInCompany.trim()) {
      if (activeTab !== tab) {
        var modifiedSteps = [...passedSteps, tab];

        if (tab >= 1 && tab <= 4) {
          setactiveTab(tab);
          setPassedSteps(modifiedSteps);
        }
      }
      setprogressbarvalue(value);
      setRegistrationProgress(value);
    }
  }

  // function handleAcceptedFiles(files) {
  //   files.map((file) =>
  //     Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //       formattedSize: formatBytes(file.size),
  //     })
  //   );
  //   setselectedFiles(files);
  // }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const handleCardClick = (cardId) => {
    setSelectedCard((prevSelectedCard) =>
      prevSelectedCard === cardId ? null : cardId
    );
    setSelectedInd((prevSelectedCard) =>
      prevSelectedCard === cardId ? null : cardId
    );
    console.log(selectedCard, "selectedcard");
  };

  const renderCard = (industry, isOtherIndustry = false) => (
    <Col xl={3} lg={4} md={6} key={industry.id}>
      <div
        className={`card-animate cursor-pointer text-center ${
          isOtherIndustry ? "rounded-pill  rounded-blue-bg" : "rounded-blue-bg"
        } ${selectedCard === industry.name ? "selectedind" : ""}`}
        onClick={() => handleCardClick(industry.name)}
      >
        <div>
          {!isOtherIndustry && (
            <img
              src={industry.industry_image}
              height={50}
              alt={industry.name}
            ></img>
          )}
          <div className={`${!isOtherIndustry ? "pt-2" : ""}`}>
            {industry.name}
          </div>
        </div>
      </div>
    </Col>
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Business Registration"
            pageTitle="Business Profile"
          />
          <Card className="" style={{ minHeight: "calc(100vh - 190px)" }}>
            <CardBody>
              <Row>
                <Col xxl={3} className="mt-3">
                  <div className="ms-3 me-3">
                    <h5 className="card-title  mt-3 mb-0">
                      Complete Your Profile
                    </h5>
                    {console.log(registrationProgress, "registration percent")}
                    <div
                      className={`progress ps-0 animated-progress mt-4 ${
                        registrationProgress === 30
                          ? "custom-progress-30"
                          : registrationProgress === 70
                          ? "custom-progress-70"
                          : registrationProgress === 100
                          ? "custom-progress-100"
                          : "custom-progress-0"
                      } progress-label`}
                    >
                      <div
                        className={`progress-bar`}
                        role="progressbar"
                        style={{
                          width: `${registrationProgress}%`,
                          backgroundColor: "#48008A",
                        }}
                        aria-valuenow={registrationProgress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {/* <div className="label">{`${registrationProgress}%`}</div> */}
                      </div>
                    </div>
                    <div className="mt-4">
                      <img src={logo} height={150}></img>
                    </div>
                  </div>
                </Col>

                <Col xxl={9} className="mt-3">
                  <div className="ms-3 me-3">
                    <Form action="#" className="form-steps">
                      <div className="hstack gap-5 flex-start">
                        <div className=" pt-3 pb-4 mb-1">
                          <h5>Signup Your Account</h5>
                        </div>

                        <div className="progress-nav mb-4 flex-1">
                          <Progress
                            value={progressBarWidth}
                            style={{ height: "1px" }}
                          />

                          <Nav
                            className="nav-pills progress-bar-tab custom-nav"
                            role="tablist"
                          >
                            <NavItem>
                              <NavLink
                                to="javascript;"
                                id="pills-gen-info-tab"
                                className={classnames({
                                  active: activeTab === 1,
                                  done: activeTab <= 5 && activeTab >= 2,
                                })}
                                onClick={(e) => {
                                  e.preventDefault();
                                  // toggleTab(1, 0);
                                }}
                                tag="button"
                              >
                                <i className="bold ri-check-line text-white fs-16"></i>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                to="javascript;"
                                id="pills-gen-info-tab"
                                className={classnames({
                                  active: activeTab === 2,
                                  done: activeTab <= 5 && activeTab >= 3,
                                })}
                                onClick={(e) => {
                                  e.preventDefault();
                                  // toggleTab(2, 30);
                                }}
                                tag="button"
                              >
                                <i className="bold ri-check-line text-white fs-16"></i>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                to="javascript;"
                                id="pills-gen-info-tab"
                                className={classnames({
                                  active: activeTab === 3,
                                  done: activeTab <= 5 && activeTab >= 4,
                                })}
                                onClick={(e) => {
                                  e.preventDefault();
                                  // toggleTab(3, 70);
                                }}
                                tag="button"
                              >
                                <i className="bold ri-check-line text-white fs-16"></i>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                to="javascript;"
                                id="pills-gen-info-tab"
                                className={classnames({
                                  active: activeTab === 4,
                                  done: activeTab <= 5 && activeTab == 4,
                                })}
                                onClick={(e) => {
                                  e.preventDefault();
                                  //toggleTab(4, 100);
                                }}
                                tag="button"
                              >
                                <i className="bold ri-check-line text-white fs-16"></i>
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>
                      </div>

                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={1}>
                          <div
                            style={{
                              minHeight: "60vh",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Row className="mt-3 mb-1">
                              <Col lg={6}>
                                <Label
                                  className="form-label h5"
                                  htmlFor="gen-info-email-input"
                                >
                                  What is your business name?{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="text"
                                  className={`form-control ${
                                    companyNameError ? "is-invalid" : ""
                                  }`}
                                  value={companyName}
                                  onChange={(e) =>
                                    setCompanyName(e.target.value)
                                  }
                                />
                                {companyNameError && (
                                  <div className="invalid-feedback">
                                    {companyNameError}
                                  </div>
                                )}
                              </Col>

                              <Col lg={6}>
                                <Label
                                  className="form-label h5 "
                                  htmlFor="gen-info-username-input"
                                >
                                  What's your role in the company?{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="text"
                                  className={`form-control ${
                                    roleInCompanyError ? "is-invalid" : ""
                                  }`}
                                  value={roleInCompany}
                                  onChange={(e) =>
                                    setRoleInCompany(e.target.value)
                                  }
                                />

                                {roleInCompanyError && (
                                  <div className="invalid-feedback">
                                    {roleInCompanyError}
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <div className="mb-1 mt-4">
                                <Label
                                  className="form-label h5 "
                                  htmlFor="gen-info-username-input"
                                >
                                  What is your business address?{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <GoogleAutocomplete
                                  className={`${
                                    addressError ? "is-invalid" : ""
                                  }`}
                                  apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                  value={address}
                                  onChange={(e) => {
                                    setAddress(e.target.value);
                                    setAddressError("");
                                  }}
                                  onPlaceSelected={(place) =>
                                    residentialplace(place)
                                  }
                                  options={{
                                    types: ["address"],
                                    componentRestrictions: {
                                      country: "au",
                                    },
                                  }}
                                  style={{
                                    width: "100%",
                                    border: "1px solid #ccc",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    position: "relative",
                                    zIndex: 9999,
                                  }}
                                />
                                {addressError && (
                                  <div className="invalid-feedback">
                                    {addressError}
                                  </div>
                                )}
                              </div>
                            </Row>
                            <Row>
                              <div className="mb-1 mt-4">
                                <Label
                                  className="form-label h5"
                                  htmlFor="gen-info-email-input"
                                >
                                  Could you please provide a brief description
                                  of your company?{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <textarea
                                  type="text"
                                  rows={3}
                                  className={`form-control ${
                                    descriptionError ? "is-invalid" : ""
                                  }`}
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  placeholder="Type description here"
                                />

                                {descriptionError && (
                                  <div className="invalid-feedback">
                                    {descriptionError}
                                  </div>
                                )}
                              </div>
                            </Row>
                            <Row className="mt-4 mb-1">
                              <Col
                                lg={6}
                                className={`d-flex align-items-center gap-3 ${
                                  abnError ? "mb-4" : "mb-0"
                                }`}
                              >
                                <Input
                                  className="form-check-input mb-1"
                                  type="checkbox"
                                  id="auth-remember-check"
                                  onChange={handleCheckboxChange}
                                  style={{
                                    width: "1.25rem",
                                    height: "1.25rem",
                                  }} // Checkbox size
                                />
                                <h6
                                  className="mb-0"
                                  htmlFor="auth-remember-check"
                                >
                                  Is this business a charity or a
                                  not-for-profit?
                                </h6>
                              </Col>

                              <Col
                                lg={6}
                                className="d-flex align-items-center gap-3"
                              >
                                <h6
                                  className={abnError ? "mb-4" : "mb-0"}
                                  id="business-number-input"
                                >
                                  Business Number
                                </h6>

                                <div className="d-flex flex-column ">
                                  <Input
                                    type="text"
                                    className={`form-control ${
                                      abnError ? "is-invalid" : ""
                                    }`}
                                    id="business-number-input"
                                    style={{ width: "100%" }} // Responsive width
                                    value={abnNumber}
                                    onChange={handleAbnChange}
                                    placeholder="ABN"
                                  />
                                  {abnError && (
                                    <div className="invalid-feedback mt-1">
                                      {abnError}
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>

                            {/* <Row className="align-items-center mt-4 mb-1">
  <Col lg={12} md={6} className="d-flex align-items-center">
    <div className="form-check d-flex align-items-center me-5">
      <Input
        className="form-check-input"
        type="checkbox"
        id="auth-remember-check"
        onChange={handleCheckboxChange}
      />
      <Label className="form-label h5 ms-2 mb-0" htmlFor="auth-remember-check">
        Is this business a charity or a not-for-profit?
      </Label>
    </div>
    <div className="d-flex align-items-center ms-5">
      <Label className="form-label h5 mb-0 me-2" htmlFor="business-number-input">
        Business Number
      </Label>
      <Input
        type="text"
        className={`form-control ${abnError ? 'is-invalid' : ''}`}
        id="business-number-input"
        style={{ width: '250px' }} // Adjust width as needed
        value={abnNumber}
        onChange={handleAbnChange}
        placeholder="ABN"
      />
      {abnError && <div className="invalid-feedback mt-1">{abnError}</div>}
    </div>
  </Col>
  
</Row> */}
                            {/* <Row className="align-items-center mt-4 mb-1">
  <Col lg={12} md={6} className="d-flex align-items-center">
    <div className="form-check d-flex align-items-center me-5">
      <Input
        className="form-check-input"
        type="checkbox"
        id="auth-remember-check"
        onChange={handleCheckboxChange}
      />
      <Label className="form-label h5 ms-2 mb-0" htmlFor="auth-remember-check">
        Is this business a charity or a not-for-profit?
      </Label>
    </div>
    <div className="d-flex align-items-center ms-5">
      <Label className="form-label h5 mb-0 me-2" htmlFor="business-number-input">
        Business Number
      </Label>
      <div className="d-flex flex-column position-relative">
        <Input
          type="text"
          className={`form-control ${abnError ? 'is-invalid' : ''}`}
          id="business-number-input"
          style={{ width: '250px' }} // Adjust width as needed
          value={abnNumber}
          onChange={handleAbnChange}
          placeholder="ABN"
        />
        {abnError && (
          <div className="invalid-feedback position-absolute" style={{ top: '100%', left: 0 }}>
            {abnError}
          </div>
        )}
      </div>
    </div>
  </Col>
</Row> */}
                          </div>
                          <div className="d-flex justify-content-end text-center gap-3 mt-auto mb-2">
                            <button
                              type="button"
                              className="btn btn-primary  right text-white  nexttab "
                              style={{ zIndex: "999" }}
                              onClick={() => {
                                handleNextButtonClick();
                              }}
                            >
                              Next Step
                            </button>
                            {isLoading ? (
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  background: "rgba(255, 255, 255, 0.8)",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  zIndex: 9999,
                                }}
                              >
                                <Loader />
                              </div>
                            ) : null}
                          </div>
                        </TabPane>

                        <TabPane tabId={2}>
                          <div className="mt-3">
                            <h5>
                              How many employees do you have?{" "}
                              <span className="text-danger">*</span>
                            </h5>

                            <div className="mt-3">
                              <Row>
                                {checkboxes.map((checkbox, index) => (
                                  <Col key={index} xl={3} lg={4} md={6}>
                                    <div className="text-center bold text-gray card-business card-animate">
                                      <button
                                        type="button"
                                        className={`btn-emp-num ${
                                          checkbox.checked ? "active" : ""
                                        }`}
                                        onClick={() => handleButtonClick(index)}
                                      >
                                        {checkbox.label}
                                        {checkbox.checked && (
                                          <Input
                                            className="form-check-input ml-1"
                                            type="checkbox"
                                            id={`formCheck${index}`}
                                            defaultChecked
                                          />
                                        )}
                                      </button>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                            <div className="mt-4">
                              <h5>
                                What is your business industry?{" "}
                                <span className="text-danger">*</span>
                              </h5>
                            </div>
                            <div className="mt-3">
                              <Row>
                                {indOptions
                                  .slice(0, 8)
                                  .map((industry) => renderCard(industry))}
                              </Row>

                              <Row>
                                <Col lg={12}>
                                  <div
                                    className="text-center hstack gap-1 justify-content-center align-items-center card-business p-2 "
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      setShowIndustries(!showIndustries)
                                    }
                                  >
                                    <i className="ri-add-circle-line" /> Other
                                    Industries
                                  </div>
                                </Col>
                              </Row>

                              {showIndustries && (
                                <Row className="mt-3">
                                  {indOptions
                                    .slice(8)
                                    .map((industry) =>
                                      renderCard(industry, true)
                                    )}
                                </Row>
                              )}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between gap-3 mt-5">
                            <button
                              type="button"
                              className="btn  btn-primary text-decoration-none"
                              onClick={() => {
                                toggleTab(activeTab - 1, 0);
                              }}
                            >
                              Previous
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary  right text-white  nexttab"
                              style={{ zIndex: "999" }}
                              onClick={() => {
                                toggleTab(activeTab + 1, 70);
                              }}
                            >
                              Next step
                            </button>
                          </div>
                        </TabPane>

                        <TabPane tabId={3}>
                          <div
                            style={{
                              minHeight: "60vh",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div className="mt-3 mb-3">
                              <h5>Make it look your own!</h5>
                            </div>
                            <Dropzone
                              onDrop={handleAcceptedFiles}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  className="dropzone logo-dropzone d-flex dz-clickable mb-3"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="dz-message m-auto needsclick">
                                    <div className="mb-3">
                                      <img
                                        height={60}
                                        src={uploadImg}
                                        alt="Upload"
                                      />
                                    </div>
                                    <h6>
                                      Drag your logo here or
                                      <br /> click to browse.
                                    </h6>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <Row>
                              <Col
                                lg={12}
                                className="d-flex justify-content-center align-items-center"
                              >
                                {imgSrc && (
                                  <div className="flex flex-col items-center">
                                    <ReactCrop
                                      crop={crop}
                                      onChange={(newCrop) => setCrop(newCrop)}
                                      onComplete={(newCrop) =>
                                        updateCanvasPreview(newCrop)
                                      }
                                      keepSelection
                                      minWidth={MIN_DIMENSION}
                                    >
                                      <img
                                        ref={imgRef}
                                        src={imgSrc}
                                        alt="Upload"
                                        style={{ maxHeight: "50vh" }}
                                        onLoad={onImageLoad}
                                      />
                                    </ReactCrop>
                                    {/* <ReactCrop
                                      crop={crop}
                                      onChange={(newCrop) => setCrop(newCrop)}
                                      onComplete={(newCrop) =>
                                        updateCanvasPreview(newCrop)
                                      }
                                      keepSelection
                                      minWidth={MIN_DIMENSION}
                                    >
                                      <img
                                        ref={imgRef}
                                        src={imgSrc}
                                        alt="Upload"
                                        style={{ maxHeight: "60%" }}
                                        onLoad={onImageLoad}
                                      />
                                    </ReactCrop> */}
                                  </div>
                                )}

                                {crop && (
                                  <canvas
                                    ref={previewCanvasRef}
                                    className="mt-4"
                                    style={{ display: "none" }}
                                  />
                                )}
                              </Col>

                              {/* <Col lg={4}>
                                {imgSrc && (
                                  <button
                                    type="button"
                                    className="btn btn-primary mt-3"
                                    onClick={handleCropSave}
                                  >
                                    Upload
                                  </button>
                                )}
                                {selectedFile && (
                                  // <Col
                                  //   className="list-unstyled mb-0 m-auto"
                                  //   id="file-previews"
                                  //   lg={3}
                                  // >
                                  <Card className="mt-3 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={selectedFile.name}
                                            // src={`${URL.createObjectURL(
                                            //   selectedFile
                                            // )}?${new Date().getTime()}`}
                                            src={`${URL.createObjectURL(
                                              selectedFile
                                            )}`}
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {selectedFile.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>
                                              {(
                                                selectedFile.size / 1024
                                              ).toFixed(2)}{" "}
                                              KB
                                            </strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                  // </Col>
                                )}
                              </Col> */}
                              {error && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "90%",
                                    textAlign: "center",
                                  }}
                                >
                                  {error}
                                </p>
                              )}
                            </Row>
                            {/* {imgSrc && (
                              <div className="flex flex-col items-center">
                                <ReactCrop
                                  crop={crop}
                                  onChange={(newCrop) => setCrop(newCrop)}
                                  onComplete={(newCrop) =>
                                    updateCanvasPreview(newCrop)
                                  }
                                  keepSelection
                                  aspect={ASPECT_RATIO}
                                  minWidth={MIN_DIMENSION}
                                >
                                  <img
                                    ref={imgRef}
                                    src={imgSrc}
                                    alt="Upload"
                                    style={{ maxHeight: "70vh" }}
                                    onLoad={onImageLoad}
                                  />
                                </ReactCrop>
                              </div>
                            )}
                            {error && (
                              <p className="text-red-400 text-xs">{error}</p>
                            )}
                            {crop && (
                              <canvas
                                ref={previewCanvasRef}
                                className="mt-4"
                                style={{ display: "none" }}
                              />
                            )}
                            {imgSrc && (
                                <button
                                type="button"
                                className="btn btn-primary mt-3"
                                onClick={handleCropSave}
                              >
                                Upload
                              </button>
                            )}
                            {selectedFile && (
                              <Col
                                className="list-unstyled mb-0 m-auto"
                                id="file-previews"
                                lg={3}
                              >
                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={selectedFile.name}
                                          src={URL.createObjectURL(
                                            selectedFile
                                          )}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {selectedFile.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>
                                            {(selectedFile.size / 1024).toFixed(
                                              2
                                            )}{" "}
                                            KB
                                          </strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              </Col>
                            )} */}
                            <div className="d-flex justify-content-between mt-2">
                              <button
                                type="button"
                                className="btn btn-primary text-decoration-none"
                                onClick={() => {
                                  toggleTab(activeTab - 1, 30);
                                }}
                              >
                                Previous
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary right text-white nexttab"
                                style={{ zIndex: "999" }}
                                onClick={() => {
                                  toggleTab(activeTab + 1, 100);
                                }}
                              >
                                Submit Profile
                              </button>
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <h5>
                            Profile Completed! Redirecting to Home page...
                          </h5>
                          <div className="text-center mt-5">
                            {isLoading ? (
                              <Spinner
                                size="md"
                                className="me-2 mt-4 text-primary text-center"
                                color="primary"
                              >
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}
                          </div>
                        </TabPane>
                      </TabContent>
                    </Form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <ToastContainer />
        </Container>
        <div style={{ position: "absolute", bottom: "0px", right: "0px" }}>
          <img src={halfLogo} height={200}></img>
        </div>
      </div>
    </React.Fragment>
  );
};
export default BusinessProfile;
