import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  NavLink,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  CardHeader,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  Alert,
  DropdownMenu,
  Badge,
  Table,
  DropdownItem,
} from "reactstrap";
//Import Icons
import FeatherIcon from "feather-icons-react";
import { toast, ToastContainer } from "react-toastify";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import logoPurple from "../../assets/images/logopurple.png";
import quickfee from "../../assets/images/quickfee.jpg";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useNavigate } from "react-router-dom";
import AddNewPayment from "./AddNewPayment";
import axios from "axios";
import info from "../../assets/images/info.png";
import visaCard from "../../assets/images/card_brand/visa.png";
import amex from "../../assets/images/card_brand/american_express.png";
import union from "../../assets/images/card_brand/union_pay.jpg";
import master from "../../assets/images/card_brand/master_card.png";
import diners from "../../assets/images/card_brand/diners_club_international.png";
import discover from "../../assets/images/card_brand/discover.jpg";
import CardDeleteModal from "./DeleteModal";
import CancelSubscriptionModal from "./CancelModal";
import { get_cookie } from "../../helpers/get_cookie";

const stripePromise = loadStripe(
  "pk_test_51PQgCZKIMwyQ02NVkd61ln75OTdn4diforIzJIfrSMbwy5IeAGBTANUSYkMRpf7WJXR7QbsrFAahxTusy77uTsEN001yPtwoyx"
);

const Billing = () => {
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [authUserId, setAuthUserId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(0);
  const [isEstimateLoading, setIsEstimateLoading] = useState(true);
  const [showCheckout, setShowCheckout] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);
  const [cardDetails, setCardDetails] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [estimateData, setEstimate] = useState({});
  const [showDefaultErr, setShowDefaultErr] = useState(false);
  const [subDetails, setSubDetails] = useState();
  const navigate = useNavigate();
  const card_logos = {
    visa: visaCard,
    amex: amex,
    mastercard: master,
    unionpay: union,
    diners: diners,
    discover: discover,
  };
  const price2 = {
    id: 3,
    type: "One Simple Plan",
    purpose: "Enterprise Businesses",
    planIcon: "ri-stack-line",
    rate: 10,
    projects: "unlimited",
    Customers: "unlimited",
    FTP: "unlimited",
    supportClass: "success",
    supportClassSymbol: "checkbox",
    storageClass: "success",
    storageClassSymbol: "checkbox",
    domainClass: "success",
    domainClassSymbol: "checkbox",
    ribbon: false,
    planButtonClassname: "soft-primary",
    icon: "ri-medal-line",
    btntxt: "Subscribe",
  };

  const getCardLogo = (brand) => {
    return card_logos[brand] || null;
  };

  const handleUpgradeClick = () => {
    setShowCheckout(true);
  };
  const toggleModal = () => {
    setShowCheckout(!showCheckout);
  };
  const toggleAddCard = () => {
    setShowAddCard(!showAddCard);
  };
  const toggleDefaultErr = () => {
    setShowDefaultErr(!showDefaultErr);
  };
  const handleNewPayment = () => {
    setShowAddCard(true);
  };
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        getEstimateData(res.id);
        getSubscriptionDetails(res.id);
        getInvoiceData(res.id);
        axios
          .post("/get-card-details", { user_id: res.id, business_id: business_id })
          .then((res) => {
            if (res.status === "success") {
              console.log(res, "card details");
              setCardDetails(res.cards);
              setIsLoading(false);
            } else {
              //  toast.warning(res.message);
            }
          })
          .then((err) => console.log(err));
        setIsLoading(false);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);
  const getInvoiceData = (id) => {
    let formData = {
      user_id: id,
    };
    axios
      .post("/get-invoices", formData)
      .then((res) => {
        setInvoiceData(res.invoices);
      })
      .then((err) => {
        console.log(err);
      });
  };
  const getCardDetails = () => {
    setIsLoading(true);
    axios
      .post("/get-card-details", { user_id: authUserId, business_id: business_id })
      .then((res) => {
        if (res.status === "error") {
          toast.warning(res.message);
        } else {
          console.log(res, "card details");
          setCardDetails(res.cards);
          setIsLoading(false);
          setIsSubscribed(res.is_subscribed);
        }
      })
      .then((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const getEstimateData = (user_id) => {
    let formData = {
      user_id: user_id,
      business_id: business_id,
    };
    axios
      .post("/getbillingdetails", formData)
      .then((res) => {
        console.log(res);
        setEstimate(res);
        setIsEstimateLoading(false);
      })
      .then((err) => {
        console.log(err);
        setIsEstimateLoading(false);
      });
  };
  const getSubscriptionDetails = (id) => {
    let formData = {
      user_id: id,
      business_id: business_id,
    };
    axios
      .post("subscription-details", formData)
      .then((res) => {
        console.log(res);
        setSubDetails(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getMonthName = (monthNumber) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthNumber - 1];
  };
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        setAuthUserId(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const handleCardDelete = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };
  const handleCancelPermission = (id) => {
    setSubscriptionModal(true);
  };
  const handleDeletePermission = async () => {
    var deleteval = {
      user_id: authUserId,
      payment_method: deleteId,
      business_id: business_id,
    };
    axios
      .post("/delete-card", deleteval)
      .then((res) => {
        if (res.code === 400) {
          setShowDefaultErr(true);
        } else {
          toast.success(res.message, { theme: "light" });
          getCardDetails();
        }

        setDeleteModal(false);
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };
  const handleDefaultCard = (id) => {
    let formData = {
      payment_method: id,
      user_id: authUserId,
      business_id: business_id,
    };
    axios
      .post("/set-default-card", formData)
      .then((res) => {
        console.log(res);
        getCardDetails();
      })
      .then((err) => {
        console.log(err);
      });
  };
  const handleRetryCard = (id) => {
    let formData = {
      payment_method: id,
      user_id: authUserId,
      business_id: business_id,
    };
    axios
      .post("/retry-payment", formData)
      .then((res) => {
        if (res.status === "error") {
          toast.error(res.message);
          return;
        }
        console.log(res);
        getCardDetails();
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleCancelSubscription = () => {
    let formData = {
      user_id: authUserId,
      business_id: business_id,
    };
    axios
      .post("/cancel-subscription", formData)
      .then((res) => {
        console.log(res);
        // getCardDetails();
        setSubscriptionModal(false);
        getSubscriptionDetails(authUserId);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const formatDate = (timestamp) => {
    if (timestamp) {
      const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

      const options = { year: "numeric", month: "long", day: "numeric" };
      console.log(date.toLocaleDateString(undefined, options), "timestamp");
      return date.toLocaleDateString(undefined, options);
    } else {
      return null;
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatToCurrency = (number) => {
    return (number / 100).toFixed(2);
  };

  const handlePreviousPage = () => {
    navigate("/searchworkforce");
  };
  return (
    <React.Fragment>
      <section className="pt-3 pb-5" id="billing">
        <CancelSubscriptionModal
          show={subscriptionModal}
          onDeleteClick={handleCancelSubscription}
          onCloseClick={() => setSubscriptionModal(false)}
        />
        <CardDeleteModal
          show={deleteModal}
          onDeleteClick={handleDeletePermission}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Container>
          <Row className="g-4">
            <Col lg={12}>
              <div className="d-flex mt-2 mb-2 justify-content-between align-items-center">
                <button
                  className="btn btn-soft-ghost d-flex ps-0 gap-2 align-items-center"
                  onClick={() => handlePreviousPage()}
                >
                  <i className="ri-arrow-left-line text-primary fs-18"></i>{" "}
                  <h5
                    className="mb-0  text-primary"
                    style={{ textDecoration: "underline" }}
                  >
                    {" "}
                    Back to portal
                  </h5>
                </button>
                <img
                  src={logoPurple}
                  alt=""
                  className="text-center"
                  height={50}
                />
              </div>
              <h3 className="mb-3 fw-bold lh-base"> Billing Management</h3>
              <Row>
                <Col lg={4}>
                  <Card className="pricing-box ribbon-box ">
                    <CardBody className="p-4 mt-0 m-2">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h6 className="mb-1 fw-semibold text-primary">
                            {price2.type}
                          </h6>
                          <p className="text-muted mb-0">{price2.purpose}</p>
                        </div>
                        <div className="avatar-sm">
                          <div className="avatar-title bg-light rounded-circle text-primary">
                            <i className={"fs-20 " + price2.icon}></i>
                          </div>
                        </div>
                      </div>
                      <div className="pt-3">
                        <h5>
                          <sup style={{ cursor: "default" }}>
                            <small className="text-primary">$ </small>
                          </sup>
                          <span className="text-primary">{price2.rate}</span>
                          <span className="fs-13 t">/ Team Member / Month</span>
                        </h5>
                      </div>
                      <hr className="mt-4 text-muted" />
                      <div>
                        <ul className="list-unstyled text-muted vstack gap-3">
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Search Workforce
                                <sup
                                  style={{ cursor: "default" }}
                                  id="search-workforce"
                                >
                                  ?
                                </sup>
                                <UncontrolledTooltip
                                  placement="right"
                                  target="search-workforce"
                                >
                                  Easily find the right candidates with our
                                  powerful search tools
                                </UncontrolledTooltip>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Unlimited Rosters
                                <sup
                                  style={{ cursor: "default" }}
                                  id="unlimited-rosters"
                                >
                                  ?
                                </sup>
                                <UncontrolledTooltip
                                  placement="right"
                                  target="unlimited-rosters"
                                >
                                  Create and manage as many rosters as required
                                  for your teams
                                </UncontrolledTooltip>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Unlimited Sites
                                <sup
                                  style={{ cursor: "default" }}
                                  id="unlimited-sites"
                                >
                                  ?
                                </sup>
                                <UncontrolledTooltip
                                  placement="right"
                                  target="unlimited-sites"
                                >
                                  Add and manage multiple job sites without any
                                  restrictions
                                </UncontrolledTooltip>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div className="flex-shrink-0 text-success me-1">
                                <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                              </div>
                              <div className="flex-grow-1">
                                Unlimited Job Posting
                                <sup
                                  style={{ cursor: "default" }}
                                  id="unlimited-job-posting"
                                >
                                  ?
                                </sup>
                                <UncontrolledTooltip
                                  placement="right"
                                  target="unlimited-job-posting"
                                >
                                  Post as many jobs as needed to find the right
                                  talent
                                </UncontrolledTooltip>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div
                                className={`flex-shrink-0 text-${price2.supportClass} me-1`}
                              >
                                <i
                                  className={`ri-${price2.supportClassSymbol}-circle-fill fs-15 align-middle`}
                                ></i>
                              </div>
                              <div className="flex-grow-1">
                                Timesheets
                                <sup
                                  style={{ cursor: "default" }}
                                  id="timesheets"
                                >
                                  ?
                                </sup>
                                <UncontrolledTooltip
                                  placement="right"
                                  target="timesheets"
                                >
                                  Track and manage time efficiently with
                                  integrated timesheets
                                </UncontrolledTooltip>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div
                                className={`flex-shrink-0 text-${price2.storageClass} me-1`}
                              >
                                <i
                                  className={`ri-${price2.storageClassSymbol}-circle-fill fs-15 align-middle`}
                                ></i>
                              </div>
                              <div className="flex-grow-1">
                                3rd Party Integration
                                <sup
                                  style={{ cursor: "default" }}
                                  id="integration"
                                >
                                  ?
                                </sup>
                                <UncontrolledTooltip
                                  placement="right"
                                  target="integration"
                                >
                                  Enhance functionality with seamless
                                  integrations to other services
                                </UncontrolledTooltip>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex">
                              <div
                                className={`flex-shrink-0 text-${price2.domainClass} me-1`}
                              >
                                <i
                                  className={`ri-${price2.domainClassSymbol}-circle-fill fs-15 align-middle`}
                                ></i>
                              </div>
                              <div className="flex-grow-1">
                                Unlimited Talent Pool
                                <sup
                                  style={{ cursor: "default" }}
                                  id="unlimited-talent-pool"
                                >
                                  ?
                                </sup>
                                <UncontrolledTooltip
                                  placement="right"
                                  target="unlimited-talent-pool"
                                >
                                  Build a comprehensive talent pool with no
                                  limits on size
                                </UncontrolledTooltip>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div className="mt-4">
                          {isSubscribed == 0 ? (
                            <Button
                              className={`btn btn-${price2.planButtonClassname} w-100 waves-effect waves-light`}
                              onClick={() => handleUpgradeClick()}
                            >
                              {price2.btntxt}
                            </Button>
                          ) : (
                            <Button
                              className={`btn  btn-danger  w-100 waves-effect waves-light`}
                              onClick={() => handleCancelPermission()}
                            >
                              Cancel Subscription
                            </Button>
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={8}>
                  <Card>
                    {isLoading ? (
                      <CardBody>
                        <div>
                          <h3 className="card-title placeholder-glow">
                            <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                          </h3>
                          <h3 className="card-title placeholder-glow">
                            <span className="placeholder placeholder-sm  col-5 bg-dark"></span>
                          </h3>
                        </div>
                      </CardBody>
                    ) : (
                      <CardBody className="p-0">
                        <Alert
                          color={subDetails ? subDetails.info_color : "white"}
                          className="border-0 rounded-top rounded-0 m-0 d-flex align-items-center"
                        >
                          <img src={info} height={20} />
                          <div className="flex-grow-1 ps-1 text-truncate">
                            {subDetails?.info_title}
                          </div>
                        </Alert>

                        <p className="mt-2 ps-3 pe-3">
                          {subDetails?.info_description}
                        </p>
                      </CardBody>
                    )}
                  </Card>
                  <Card>
                    <CardHeader className="d-flex justify-content-between align-items-center">
                      <h5 className="m-0">Payment Methods</h5>
                      {cardDetails?.length >= 1 ? (
                        <Button
                          className="btn btn-primary"
                          style={{
                            border: "0px solid black",
                          }}
                          onClick={() => handleNewPayment()}
                        >
                          New payment method
                        </Button>
                      ) : (
                        <span id="disabled-payment">
                          <Button
                            className="btn btn-primary  text-white "
                            disabled
                            style={{
                              border: "0px solid black",
                              backgroundColor: "#9b9b9b",
                            }}
                            onClick={() => handleNewPayment()}
                          >
                            New payment method
                          </Button>
                          <UncontrolledTooltip
                            placement="bottom"
                            target="disabled-payment"
                          >
                            Please subscribe before adding a new payment method
                          </UncontrolledTooltip>
                        </span>
                      )}
                    </CardHeader>
                    <CardBody>
                      {isLoading ? (
                        <div>
                          <h3 className="card-title placeholder-glow">
                            <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                          </h3>
                          <h3 className="card-title placeholder-glow">
                            <span className="placeholder placeholder-sm  col-5 bg-dark"></span>
                          </h3>
                        </div>
                      ) : (
                        <>
                          {cardDetails.length > 0 ? (
                            <>
                              {cardDetails?.map((detail, index) => (
                                <>
                                  <div
                                    key={index}
                                    className="d-flex justify-content-between  align-items-center"
                                  >
                                    <div
                                      className="row"
                                      style={{ flex: "auto" }}
                                    >
                                      <Col lg={1}>
                                        {detail?.brand && (
                                          <img
                                            src={getCardLogo(detail.brand)}
                                            alt={detail.brand}
                                            height={40}
                                          />
                                        )}
                                      </Col>
                                      <Col lg={11}>
                                        <div className=" ms-3 vstack">
                                          <span>
                                            {detail.brand} xxxx{detail.last4}{" "}
                                            {detail.default && (
                                              <span className="badge badge-soft-primary ms-2">
                                                {" "}
                                                {detail.default
                                                  ? "Default"
                                                  : ""}
                                              </span>
                                            )}
                                          </span>
                                          <span>
                                            Expires:{" "}
                                            {getMonthName(
                                              detail.expiration_month
                                            )}{" "}
                                            {detail.expiration_year}{" "}
                                          </span>
                                        </div>
                                      </Col>
                                    </div>

                                    <div
                                      className="vstack "
                                      style={{ flex: "none" }}
                                    >
                                      {detail.payment_failed ? (
                                        <span className="badge badge-soft-danger">
                                          Payment failed
                                        </span>
                                      ) : null}
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          tag="button"
                                          className="btn float-end btn-icon text-muted btn-sm fs-18 dropdown"
                                          type="button"
                                        >
                                          <i className="ri-more-fill"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <li
                                            onClick={() => {
                                              handleDefaultCard(detail.id);
                                            }}
                                          >
                                            <DropdownItem
                                              disabled={detail.default}
                                            >
                                              Set as Default
                                            </DropdownItem>
                                          </li>
                                          <li
                                            onClick={() => {
                                              handleRetryCard(detail.id);
                                            }}
                                          >
                                            <DropdownItem
                                              disabled={!detail.payment_failed}
                                            >
                                              Retry
                                            </DropdownItem>
                                          </li>
                                          <li
                                            onClick={() =>
                                              handleCardDelete(detail.id)
                                            }
                                          >
                                            <DropdownItem>Delete</DropdownItem>
                                          </li>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  </div>
                                  {index !== cardDetails?.length - 1 && (
                                    <hr className="text-muted"></hr>
                                  )}
                                </>
                              ))}
                            </>
                          ) : (
                            <span className="text-muted">
                              No cards added yet!
                            </span>
                          )}
                        </>
                      )}
                    </CardBody>
                  </Card>
                  <Card>
                    <h6 className="p-3 mb-2 pb-0"> Estimated Bill Summary</h6>
                    {isEstimateLoading ? (
                      <div className="row mb-2 ">
                        <div className="col-lg-4 border-end col">
                          <div className="mt-1 mt-md-0 py-3 px-3">
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-sm  col-7 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-sm  col-10 bg-dark"></span>
                            </h3>
                          </div>
                        </div>
                        <div className="col-lg-3 border-end col">
                          <div className="mt-3 mt-md-0 py-3 px-0">
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-sm  col-7 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-sm  col-10 bg-dark"></span>
                            </h3>
                          </div>
                        </div>
                        <div className="col-lg-2 border-end col">
                          <div className="mt-3 mt-md-0 py-3 px-0">
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-sm  col-7 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-sm  col-10 bg-dark"></span>
                            </h3>
                          </div>
                        </div>
                        <div className="col-lg-3 col">
                          <div className="mt-3 mt-md-0 py-3 px-0">
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-sm  col-7 bg-dark"></span>
                            </h3>
                            <h3 className="card-title placeholder-glow">
                              <span className="placeholder placeholder-sm  col-10 bg-dark"></span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row mb-2 ">
                        <div className="col-lg-4 border-end col">
                          <div className="mt-1 mt-md-0 py-3 px-3">
                            <h6 className="text-muted text-uppercase fs-11">
                              Account Name{" "}
                            </h6>
                            <h6 className="mb-0 fs-14">
                              <span>{estimateData.business_name}</span>
                            </h6>
                          </div>
                        </div>
                        <div className="col-lg-3 border-end col">
                          <div className="mt-3 mt-md-0 py-3 px-0">
                            <h6 className="text-muted text-uppercase fs-11">
                              Billing period{" "}
                            </h6>
                            <h6 className="mb-0  fs-13">
                              <span>{estimateData.billing_period}</span>
                            </h6>
                          </div>
                        </div>
                        <div className="col-lg-2 border-end col">
                          <div className="mt-3 mt-md-0 py-3 px-0">
                            <h6 className="text-muted text-uppercase fs-11">
                              Team Members{" "}
                            </h6>
                            <h6 className="mb-0  fs-14">
                              <span>{estimateData.team_size}</span>
                            </h6>
                          </div>
                        </div>
                        <div className="col-lg-3 col">
                          <div className="mt-3 mt-md-0 py-3 px-0">
                            <h6 className="text-muted text-uppercase fs-11">
                              Estimated Total{" "}
                            </h6>
                            <h6 className="mb-0  fs-14">
                              <span>
                                {estimateData.total_est
                                  ? "$ " + estimateData.total_est
                                  : "$ " + 0}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
              {invoiceData?.length >= 1 && (
                <div className="table-responsive card">
                  <Table className="table-striped table-nowrap align-middle mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Invoice </th>
                        <th scope="col">Invoice Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Total</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceData?.map((invoice, index) => (
                        <tr key={index}>
                          <td>{invoice.customer_name} </td>
                          <td>{formatDate(invoice.created)} </td>
                          <td>{capitalizeFirstLetter(invoice.status)} </td>
                          <td>$ {formatToCurrency(invoice.total)} </td>
                          <td
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              // window.location.href = invoice.invoice_pdf;
                              window.open(invoice.hosted_invoice_url, "_blank");
                            }}
                          >
                            Download{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}

              {/* <div>
                <button
                  className="btn btn-soft-ghost d-flex ps-0 gap-2 align-items-center"
                  onClick={() => handlePreviousPage()}
                >
                  <i className="ri-arrow-left-line text-primary fs-18"></i>{" "}
                  <h5
                    className="mb-0  text-primary"
                    style={{ textDecoration: "underline" }}
                  >
                    {" "}
                    Back to portal
                  </h5>
                </button>
              </div> */}
            </Col>
          </Row>
        </Container>
        <Modal isOpen={showAddCard} toggle={toggleAddCard} centered={true}>
          <ModalHeader className="w-100">
            <div>Add New Card</div>
          </ModalHeader>
          <ModalBody>
            <Elements stripe={stripePromise}>
              <AddNewPayment
                onSuccess={() => {
                  setShowAddCard(false);
                  getCardDetails();
                }}
              />
            </Elements>
          </ModalBody>
        </Modal>
        <Modal isOpen={showCheckout} toggle={toggleModal} centered={true}>
          <ModalHeader className="w-100">
            <div>Pay by Credit or Debit Card</div>
          </ModalHeader>
          <ModalBody>
            <Elements stripe={stripePromise}>
              <CheckoutForm
                onSuccess={() => {
                  setShowCheckout(false);
                  getCardDetails();
                  getSubscriptionDetails(authUserId);
                  toast.success("Subscription successful");
                }}
              />
            </Elements>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={showDefaultErr}
          toggle={toggleDefaultErr}
          centered={true}
        >
          <ModalHeader>
            <div>Something went wrong!</div>
          </ModalHeader>
          <ModalBody>
            <p className="text-center">
              Cannot delete the default payment method. Please set another card
              as default before deleting this one.
            </p>
          </ModalBody>
        </Modal>
        <ToastContainer />
      </section>
    </React.Fragment>
  );
};
export default Billing;
