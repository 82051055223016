import React, { useState, useEffect } from 'react';
import ErrorPage from './ErrorPage';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {

    const handleError = (error) => {
      console.error(error);
      console.log(hasError,"hasError")
      if (error.isTrusted===true){
        setHasError(true);
      }
      
    };
    window.addEventListener('error', handleError);

    // return () => {
    //   window.removeEventListener('error', handleError);
    // };
  }, []);
  
  useEffect(() => {
    console.log(hasError, "hasError");
  }, [hasError]);

  if (hasError) {
    return <ErrorPage />;
  }

  return children;
};

export default ErrorBoundary;
