
export const SettingsTabPages = {
    General: {
        id: 0,
        name:"general",
        displayText: 'General',
        iconClass: 'cog'
    },
    Zone: {
        id: 1,
        name:"zone",
        displayText: 'Zones',
        iconClass: 'user'
    },
    AssignedPeople: {
        id: 2,
        name:"assignedPeople",
        displayText: 'Assigned People',
        iconClass: 'user'
    },
    Holidays: {
        id: 3,
        name:"holidays",
        displayText: 'Public Holidays',
        iconClass: 'calendar-star'
    },
}

