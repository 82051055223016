import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { get_cookie } from "../helpers/get_cookie";
import crown from "../assets/images/crown.png";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [isCustomer, setIsCustomer] = useState(false);
  const [isJobs, setIsJobs] = useState(false);
  const [isReporting, setIsReporting] = useState(false);
  const [isSupplyChain, setIsSupplyChain] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [settingsUrl, setSettingsUrl] = useState(false);
  const [applicationCount, setApplicationCount] = useState(0);
  const [oppCount, setOppCount] = useState(0);
  const [logCount, setLogCount] = useState(0);

  const [allMenuItems, setAllMenuItems] = useState([]);
  // Permissions
  const [showRoster, setShowRoster] = useState(false);
  const [showWorkforce, setShowWorkforce] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  // const user_id = JSON.parse(get_cookie("authUser")).user_id;
  // const all_permissions = JSON.parse(get_cookie("permissions"));

  useEffect(() => {
    var profile_status = JSON.parse(get_cookie("authUser"))?.profile_status;
    if (profile_status === 0) {
      console.log("true");
      setSettingsUrl("/business-profile");
    } else {
      setSettingsUrl("/business_settings");
    }

    const all_permissions = JSON.parse(get_cookie("permissions"));
    // const decrypted_permissions = get_decrypted_permissions_cookie();
    // console.log(decrypted_permissions, "decrypted_permissions");

    const view_roster = all_permissions?.includes("view_rosters");
    const view_workforce_member = all_permissions?.includes(
      "view_workforce_member"
    );

    // console.log(view_workforce_member,'view_workforce_member');
    setShowRoster(view_roster);
    setShowWorkforce(view_workforce_member);
  }, []);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    const user = JSON.parse(get_cookie("authUser"));
    console.log(user, " cookies console");
    if (user.status == "success") {
      const formData = {
        user_id: user.user_id, // Assuming the token is stored in the 'token' property
        business_id: business_id,
      };
      axios.post("sidebar-count", formData)
      .then((res) => {
        console.log("res");
        setApplicationCount(res.new_application_count.original.newApplicationsCount);
      });
    }
  }, [
    history,
    iscurrentState,
    isCustomer,
    isJobs,
    isReporting,
    isSupplyChain,
    isAuth,
  ]);
  // console.log("isVisible:", showRoster)
  const menuItems = [
    //Overview
    // {
    //   id: "overview",
    //   label: "Overview",
    //   icon: "bx bx-grid-alt",
    //   link: "/overview",
    //   // Numbervalue: logCount,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Overview");
    //   },
    //   isVisible: true,
    // },

    // //Activity
    // {
    //   id: "activity",
    //   label: "Activity",
    //   icon: "bx bx-run",
    //   link: "/activity",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Activity");
    //   },
    //   isVisible: true,
    // },

    //Operations

    {
      label: "Operations",
      isHeader: true,
      // icon: crown,
    },
    //Roster Lounge
    {
      id: "rosterlounge",
      label: "Roster",
      icon: "bx bx-calendar-event",
      link: "/roster_lounge",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("RosterLounge");
      },
      isVisible: showRoster, // Hide if showRoster is false
    },
    //Time Clock
    // {
    //   id: "timeclock",
    //   label: "Time Clock",
    //   icon: "bx bx-stopwatch",
    //   link: "/timeclock",
    //   // Numbervalue: oppCount,
    //   // right_icon: true,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("timeclock");
    //   },
    //   isVisible: true,
    // },

    //Work Order
    {
      id: "workorder",
      label: "Work Order",
      icon: "bx bx-receipt",
      link: "/work-order",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Work Order");
      },
      isVisible: true,
    },
    {
      id: "manageTeam",
      label: "Manage Team",
      icon: "bx bx-group",
      link: "/manage-team",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Manage Team");
      },
      isVisible: showWorkforce,
    },
    {
      id: "teamAvailability",
      label: "Team Availability",
      icon: "bx bx-user-check",
      link: "/team-availability",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Team Availability");
      },
      isVisible: showWorkforce,
    },
    //Timesheets

    {
      label: "TimeSheets",
      isHeader: true,
      // icon:crown
    },
    {
      id: "approveTimesheets",
      label: "Approve Timesheets",
      icon: "bx bx-list-check",
      link: "/approve_timesheets",
      // Numbervalue: oppCount,
      // right_icon: true,
      click: function (e) {
        e.preventDefault();
        setIscurrentState("approveTimesheets");
      },
      isVisible: true,
    },
    {
      id: "exportTimesheets",
      label: "Export Timesheets",
      icon: "bx bx-history",
      link: "/export_timesheets",
      // Numbervalue: oppCount,
      // right_icon: true,
      click: function (e) {
        e.preventDefault();
        setIscurrentState("exportTimesheets");
      },
      isVisible: true,
    },

    {
      label: "Communication",
      isHeader: true,
    },
    //Chat
    {
      id: "email",
      label: "Email",
      icon: "bx bx-mail-send",
      link: "/email-inbox",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Email");
      },
      isVisible: true,
    },

    {
      id: "chat",
      label: "Chat",
      icon: "bx bx-message-rounded-dots",
      link: "/chat",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Chat");
      },
      isVisible: true,
    },

    //Text Connect
    // {
    //   id: "textconnect",
    //   label: "Text Connect",
    //   icon: "bx bx-envelope",
    //   link: "/textconnect",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("TextConnect");
    //   },
    //   isVisible: true,
    // },
    //Directory
    {
      id: "directory",
      label: "Directory",
      icon: "ri-contacts-book-line",
      link: "/directory",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Directory");
      },
      isVisible: true,
    },

    //Talent Hub
    showWorkforce && {
      label: "Talent Hub",
      isHeader: true,
    },
    //manage team

    //Search  Workforce
    {
      id: "search",
      label: "Search Workforce",
      icon: "bx bx-user-plus",
      link: "/searchworkforce",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Searchworkforce");
      },
      isVisible: showWorkforce,
    },

    //job vacancies

    {
      id: "JobVacancy",
      label: "Job Vacancies",
      icon: "bx bx-intersect",
      link: "/job-Vacancies",
      // left_icon: true,
      click: function (e) {
        e.preventDefault();
        setIscurrentState("JobVacancies");
      },
      isVisible: showWorkforce,
    },
    // Talent Pool
    {
      id: "talentpool",
      label: "Talent Pool",
      icon: "bx bx-street-view",
      link: "/talent-pool",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("TalentPool");
      },
      isVisible: showWorkforce,
    },
    //job applications
    {
      id: "JobApplications",
      label: "Job Applications",
      icon: "ri-account-box-line",
      link: "/job-applications",
      Numbervalue: applicationCount,
      // left_icon: true,
      click: function (e) {
        e.preventDefault();
        setIscurrentState("JobApplications");
      },
      isVisible: showWorkforce,
    },

    //Engagements

    {
      label: "Engagements",
      isHeader: true,
    },
    {
      id: "currentcontracts",
      label: "Contracts",
      icon: "bx bx-file",
      link: "/current-contracts",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("CurrentContracts");
      },
      isVisible: true,
    },

    {
      label: "Business",
      isHeader: true,
    },

    //Business Settings
    {
      id: "business_settings",
      label: "Business Settings",
      icon: "ri-settings-3-line",
      link: "/business_settings",
      //isHeader: true,
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Business Settings");
      },
      isVisible: true,
    },

    //Referral Rewards Page
    {
      id: "referral_rewards",
      label: "Referral Rewards",
      icon: "bx bx-trophy",
      link: "/referral_rewards",
      //isHeader: true,
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Referral Rewards");
      },
      isVisible: true,
    },

    //Billing
    {
      id: "billing",
      label: "Billing",
      icon: "ri-bank-card-line",
      link: "/billing",
      //isHeader: true,
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Billing");
      },
      isVisible: true,
    },

    //Sites
    // {
    //   id: "sites",
    //   label: "Sites",
    //   icon: "ri-menu-2-line",
    //   link: "/sites",
    //   //isHeader: true,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Sites");
    //   },
    //   isVisible: true,
    // },

    // {
    //   id: "invite",
    //   label: "Invite",
    //   icon: "ri-menu-2-line",
    //   link: "/accept-invitation",
    //   //isHeader: true,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIscurrentState("Invite");
    //   },
    //   isVisible: true,
    // },
  ];

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
