import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  UncontrolledAlert,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";

import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Button } from "reactstrap";
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import { CardHeader, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
//dateformat
import moment from "moment";
import { get_cookie } from "../../../helpers/get_cookie";
//reloading the page
import { jsx } from "@emotion/react";
import { ClipLoader } from "react-spinners";
//toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";
import Dropzone from "react-dropzone";
import uploadImg from "../../../assets/images/freepik/uploading_img.png";
import img13 from "../../../assets/images/small/img-12.jpg";
import { api } from "../../../config";
import GoogleAutocomplete from "react-google-autocomplete";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import setCanvasPreview from "../../../setCanvasPreview";

//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

// image crop
const MIN_DIMENSION = 100;

export const General = () => {
  document.title = "General | Get Rostered";
  const all_permissions = JSON.parse(get_cookie("permissions"));
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  // image cropper
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [croppedImgSrc, setCroppedImgSrc] = useState(null);
  const [error, setError] = useState("");
  const [uploadFileName, setUploadFileName] = useState("");

  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    
    if (file) {
      setUploadFileName(file.name);
    }

    // Validate file type
    const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (!validFileTypes.includes(file.type)) {
      setError("Invalid file type. Please select a JPEG or PNG image.");
      setImgSrc(null);
      setCroppedImgSrc(null);
      return;
    }

    // Clear any previous error if file type is valid
    setError("");

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        const { naturalWidth, naturalHeight } = e.currentTarget;

        // If the image is smaller than the minimum crop dimensions, skip cropping
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setImgSrc(imageUrl);
          setCroppedImgSrc(imageUrl); // Save the image as it is
          return;
        }

        setImgSrc(imageUrl);
      });
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.currentTarget;
    const aspectRatio = naturalWidth / naturalHeight;

    // Proceed with cropping only for larger images
    const cropWidthInPercent = 50; // Adjust as needed

    const initialCrop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      aspectRatio,
      naturalWidth,
      naturalHeight
    );
    const centeredCrop = centerCrop(initialCrop, naturalWidth, naturalHeight);
    setCrop(centeredCrop);
  };

  // Update canvas preview
  const updateCanvasPreview = (crop) => {
    if (
      imgRef.current &&
      previewCanvasRef.current &&
      crop &&
      crop.width &&
      crop.height
    ) {
      setCanvasPreview(
        imgRef.current, // HTMLImageElement
        previewCanvasRef.current, // HTMLCanvasElement
        convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
      );

      const canvas = previewCanvasRef.current;
      const dataUrl = canvas.toDataURL("image/jpeg");
      setCroppedImgSrc(dataUrl);
    }
  };

  // Effect to update canvas preview
  useEffect(() => {
    if (imgSrc && crop) {
      updateCanvasPreview(crop);
    }
  }, [imgSrc, crop]);

  //paramas used for general
  const [isLoading, setisLoading] = useState(true);
  const [business_name, setBusinessName] = useState("");
  const [business_size, setBusinessSize] = useState("");
  const [sys_industry_id, setSysIndustryId] = useState("");
  const [industry, setIndustry] = useState("");
  const [address, setAddress] = useState("");
  const [logo, setLogo] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  //validation params
  const [businessNameError, setBusinessNameError] = useState("");
  const [businessSizeError, setBusinessSizeError] = useState("");
  const [sysIndustryIdError, setSysIndustryIdError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [logoError, setLogoError] = useState("");
  const [id, setId] = useState("");
  const [gld, setGld] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  //pro set
  //google address
  const [selectedCity, setSelectedcity] = useState();
  const [selectedSuburb, setselectedSuburb] = useState();
  const [selectedPostcode, setselectedPostcode] = useState();
  const [country, setCountry] = useState([]);

  const [authUserId, setAuthUserId] = useState();
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res);
        setAuthUserId(res.id);
        loadGeneral(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const residentialplace = (place) => {
    const addressComponents = place?.address_components;
    console.log(place);
    const { lat, lng } = place.geometry.location;
    console.log("Latitude:", lat());
    console.log("Longitude:", lng());
    const streetNumberComponent = addressComponents?.find((component) =>
      component.types.includes("street_number")
    );
    const streetRouteComponent = addressComponents?.find((component) =>
      component.types.includes("route")
    );
    const unitComponent = place?.addressComponents?.find((component) =>
      component.types.includes("subpremise")
    );
    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );
    const countryComponent = addressComponents?.find((component) =>
      component.types.includes("country")
    );

    let selectedStreetNumber = "";
    if (streetNumberComponent) {
      selectedStreetNumber = streetNumberComponent.long_name;
    }
    let selectedUnitNumber = "";
    if (unitComponent) {
      selectedUnitNumber = unitComponent.long_name;
    }
    let selectedStreetRoute = "";
    if (streetRouteComponent) {
      selectedStreetRoute = streetRouteComponent.long_name;
    }
    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
    }
    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name;
    }
    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }
    let selectedcountry = "";
    if (countryComponent) {
      selectedcountry = countryComponent.long_name;
    }

    setSelectedcity(
      `${selectedUnitNumber} ${selectedStreetNumber} ${selectedStreetRoute} `
    );
    setselectedSuburb(selectedSuburb + ", " + selectedState);
    setselectedPostcode(selectedPostcode);
    setCountry(selectedcountry);
    setAddress(place?.formatted_address);
    setLatitude(lat());
    setLongitude(lng());
  };
  // Your list of options with specific values
  const sizeOptions = [
    { value: "1-10", label: "1-10" },
    { value: "11-30", label: "11-30" },
    { value: "31-50", label: "31-50" },
    { value: "51-100", label: "51-100" },
    { value: "101-200", label: "101-200" },
    { value: "201-500", label: "201-500" },
    { value: "501-1000", label: "501-1000" },
    { value: "1000+", label: "1000+" },
  ];
  //end of google address
  //proname
  const handleNameChange = (e) => {
    setBusinessName(e.target.value);
    setBusinessNameError("");
  };
  //proemail
  const handleSizeChange = (selectedOption) => {
    setBusinessSize(selectedOption.value);
    setBusinessSizeError("");
  };
  //promobile
  const handleIndustryChange = (selectedOption) => {
    setSysIndustryId({
      value: selectedOption.value,
      label: selectedOption.label,
    });
    setSysIndustryIdError("");
  };
  //progender
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    setAddressError("");
  };
  const loadGeneral = (userId) => {
    if (!userId) {
      console.warn('User ID is not available.');
      return;
    }
    
    setisLoading(true);
    const formData = {
      user_id: userId,
      business_id: business_id,
    };
    
    axios
      .post("/get-general-data", formData)
      .then((response) => {
        setIndustry(response.industries);
        setGld(response.general_data);
        let address = response.full_address; // Assuming response.full_address is ",null, Australia"
        address = address
          .split(",") // Split the address string by commas
          .filter(
            (part) => part && part.trim() !== "" && part.trim() !== "null"
          ) // Filter out empty and "null" parts
          .join(", "); // Join the remaining parts with commas
        setAddress(address);
        setCountry(response.country_name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  
  // Call loadGeneral only if authUserId is defined
  useEffect(() => {
    if (authUserId) {
      loadGeneral(authUserId);
    }
  }, [authUserId]);
  

  useEffect(() => {
    if (gld) {
      setisLoading(true);
      setId(gld?.id);
      setBusinessName(gld.business_name);
      if (Array.isArray(industry)) {
        setBusinessSize(gld.business_size);
        const selecteOption = industry.find(
          (item) => item.id === gld.sys_industry_id
        );
        if (selecteOption) {
          setSysIndustryId({
            value: gld.sys_industry_id,
            label: selecteOption.name,
          });
        }
      }
      setImage(gld.s3_url);
      setSelectedcity(gld.address);
      setselectedSuburb(gld.suburb);
      setselectedPostcode(gld.post_code);
      setisLoading(false);
    }
  }, [gld]);
  const handleSubmit = (event) => {
    // console.log(croppedImgSrc,"croppedImgSrc");
    //event.preventDefault();
    /* validation */
    if (business_name === "") {
      setBusinessNameError("Please enter your business name.");
    } else {
      setBusinessNameError("");
    }
    if (business_size === "" || business_size === null) {
      setBusinessSizeError("Please select your business size.");
    } else {
      setBusinessSizeError("");
    }
    if (sys_industry_id === "") {
      setSysIndustryIdError("Please select Industry.");
    } else {
      setSysIndustryIdError("");
    }
    if (address === "") {
      setAddressError("Please enter your address.");
    } else {
      setAddressError("");
    }
    if (
      business_name === "" ||
      business_size === "" ||
      sys_industry_id === "" ||
      address === ""
    ) {
      // If any of the required fields are empty, do not proceed with form submission.
      return;
    }

    let file;

    if (croppedImgSrc) {
      // Function to convert base64 to binary file
      const base64ToFile = (base64String, filename) => {
        // Remove the data URL prefix
        const byteString = atob(base64String.split(",")[1]);
        // Get the MIME type from the base64 string
        const mimeType = base64String.match(/:(.*?);/)[1];
        // Create an ArrayBuffer to hold the binary data
        const ab = new ArrayBuffer(byteString.length);
        // Create a typed array from the ArrayBuffer
        const ia = new Uint8Array(ab);
        // Convert the binary string to binary data
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        // Create a Blob from the binary data
        const blob = new Blob([ia], { type: mimeType });
        // Create a File from the Blob
        return new File([blob], filename, { type: mimeType });
      };

      file = base64ToFile(croppedImgSrc, uploadFileName);
    }
    setisLoading(true);
    var formData = new FormData();
    formData.append("business_name", business_name);
    formData.append("business_size", business_size);
    formData.append("sys_industry_id", sys_industry_id.value);
    formData.append("address", selectedCity);
    formData.append("suburb", selectedSuburb);
    formData.append("post_code", selectedPostcode);
    formData.append("country_name", country);
    console.log(file, "file123");
    if (file) {
      formData.append("business_logo", file);
    }
    formData.append("user_id", authUserId);
    formData.append("business_id", business_id);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    if (id) {
      // If setId(gld.id) is not empty, update the data
      formData.append("id", id);
    }
    axios
      .post("/update-general-data", formData)
      .then((response) => {
        toast.success(response.success, { theme: "light" });
        setImgSrc("");
        setError("");
        loadGeneral(authUserId);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  };
  // file upload content
  const [imagesel, setImage] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [imageDimensionsValid, setImageDimensionsValid] = useState(true);

  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Check file type
      const acceptedFormats = ["image/jpeg", "image/jpg", "image/png"];
      if (!acceptedFormats.includes(selectedFile.type)) {
        setLogoError("Please select a valid image format (jpeg, jpg, png).");
        setUploadedImage(null);
      } else {
        setLogoError("");
      }
      // Check image dimensions
      const image = new Image();
      image.src = URL.createObjectURL(selectedFile);
      image.onload = () => {
        if (image.width > 250 || image.height > 100) {
          setLogoError(
            "Image dimensions should not exceed 250px width and 100px height."
          );
          setImageDimensionsValid(false);
        } else {
          const objectUrl = URL.createObjectURL(selectedFile);
          setUploadedImage(selectedFile);
          setSelectedImage(objectUrl);
          setIsImageSelected(true);
          setImageDimensionsValid(true);
        }
      };
    }
  };

  const handleRemoveClick = () => {
    setSelectedImage(null);
    setUploadedImage(null);

    setUploadFileName("");
    setImgSrc("");
    setCroppedImgSrc("");
    setError("");
    setIsImageSelected(false);
    fileInputRef.current.value = null; // Clear the input field value
  };

  const handleChangeClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  //end of file upload content
  //end of params
  return (
    <div>
      <Card id="contactList">
        <CardHeader>
          <h5 className="mb-0 text-primary">General</h5>
        </CardHeader>
        {isLoading ? (
          <>
            <CardBody>
              <div className="mt-4">
                <div className="d-flex align-items-center mb-2"></div>
                <div>
                  <Row className="gy-3">
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-4 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-7 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-9 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-4 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-7 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-9 bg-dark"></span>
                    </h3>
                  </Row>
                </div>
              </div>
            </CardBody>
          </>
        ) : (
          <>
            <CardBody>
              <Col xxl={12}>
                <div className="live-preview">
                  <Row className="gy-4">
                    <Col xxl={12} md={12}>
                      <div>
                        <Label htmlFor="basiInput" className="form-label">
                          Business Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          value={business_name}
                          onChange={handleNameChange}
                          className={`${businessNameError ? "is-invalid" : ""}`}
                          id="readonlyInput"
                        />
                        {businessNameError && (
                          <div className="invalid-feedback">
                            {businessNameError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xxl={12} md={12}>
                      <div>
                        <Label htmlFor="readonlyInput" className="form-label">
                          Business Size <span className="text-danger">*</span>
                        </Label>
                        <Select
                          className={` ${
                            businessSizeError ? "is-invalid" : ""
                          }`}
                          value={sizeOptions.find(
                            (option) => option.value === business_size
                          )}
                          onChange={handleSizeChange}
                          options={sizeOptions}
                          isSearchable={true}
                        />
                        {businessSizeError && (
                          <div className="invalid-feedback">
                            {businessSizeError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xxl={12} md={12}>
                      <div>
                        <Label htmlFor="readonlyInput" className="form-label">
                          Industry <span className="text-danger">*</span>
                        </Label>
                        <Select
                          className={` ${
                            sysIndustryIdError ? "is-invalid" : ""
                          }`}
                          value={sys_industry_id}
                          onChange={handleIndustryChange}
                          options={
                            industry
                              ? industry.map((item) => ({
                                  value: item.id,
                                  label: item.name,
                                }))
                              : []
                          }
                          isSearchable={true}
                        />
                        {sysIndustryIdError && (
                          <div className="invalid-feedback">
                            {sysIndustryIdError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xxl={12} md={12}>
                      <div>
                        <Label
                          htmlFor="choices-single-default"
                          className="form-label"
                        >
                          Address <span className="text-danger">*</span>
                        </Label>
                        <div>
                          <GoogleAutocomplete
                            className={`${addressError ? "is-invalid" : ""}`}
                            apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            onPlaceSelected={(place) => residentialplace(place)}
                            options={{
                              types: ["geocode"],
                              componentRestrictions: {
                                country: "au",
                              },
                            }}
                            style={{
                              width: "100%",
                              border: "1px solid #ccc",
                              padding: "10px",
                              borderRadius: "5px",
                              position: "relative",
                            }}
                          />
                          {addressError && (
                            <div className="invalid-feedback">
                              {addressError}
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={8} md={8}>
                      <div className="mb-3">
                        <Label
                          htmlFor="choices-single-default"
                          className="form-label"
                        >
                          Logo
                        </Label>
                        <Row className="mb-2">
                          <Col md={6}>
                            <div className="img-container">
                              <>
                                {error && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "90%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {error}
                                  </p>
                                )}
                                {imgSrc && (
                                  <div style={{ textAlign: "center" }}>
                                    <ReactCrop
                                      crop={crop}
                                      onChange={(newCrop) => setCrop(newCrop)}
                                      onComplete={(newCrop) =>
                                        updateCanvasPreview(newCrop)
                                      }
                                      keepSelection
                                      minWidth={MIN_DIMENSION}
                                    >
                                      <img
                                        ref={imgRef}
                                        src={imgSrc}
                                        alt="Upload"
                                        style={{ maxHeight: "60%" }}
                                        onLoad={onImageLoad}
                                      />
                                    </ReactCrop>
                                  </div>
                                )}
                                {crop && (
                                  <canvas
                                    ref={previewCanvasRef}
                                    className="mt-4"
                                    style={{
                                      display: "none",
                                      border: "1px solid black",
                                      objectFit: "contain",
                                      width: 150,
                                      height: 150,
                                    }}
                                  />
                                )}
                                {/* Showing stored image */}
                                {imagesel && imgSrc === "" && (
                                  <img
                                    className="img-thumbnail avatar-lg"
                                    alt=""
                                    src={`${imagesel}?${new Date().getTime()}`}
                                  />
                                )}
                              </>
                            </div>
                          </Col>
                        </Row>
                        {imgSrc ? (
                          <div>
                            <button
                              className="btn btn-primary change-button"
                              onClick={handleChangeClick}
                            >
                              Change
                            </button>
                            <button
                              className="btn btn-danger remove-button"
                              onClick={handleRemoveClick}
                            >
                              Remove
                            </button>
                          </div>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={handleButtonClick}
                          >
                            Select image
                          </button>
                        )}
                        <input
                          className={`${logoError ? "is-invalid" : ""}`}
                          type="file"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={onSelectFile}
                          accept=".jpeg, .jpg, .png"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              {all_permissions.includes("edit_business") && <hr />}
              <ToastContainer closeButton={false} limit={1} theme="light" />
              {/* reloading the page */}
              {isUpdating && (
                <div className="spinner-overlay">
                  <ClipLoader
                    css={override}
                    size={35}
                    color={"#123abc"}
                    loading={true}
                  />
                </div>
              )}
              <div className="text-end">
                {all_permissions.includes("edit_business") && (
                  <Button
                    className="btn btn-success"
                    onClick={handleSubmit}
                    disabled={isUpdating}
                  >
                    Apply Changes
                  </Button>
                )}
                {/*<Button className="btn btn-dark" onClick={handleReset}>Reset</Button>*/}
              </div>
              {/*{isLoading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}*/}
            </CardBody>
          </>
        )}
      </Card>
    </div>
  );
};
